import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
  isLogged: false,
  shippingAddresses: [],
}

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    loginSuccess: (state, action) => {
      state.isLogged = true      
    },
    logoutSuccess: (state, action) => {
      state.isLogged = false
    },
    setShippingAddresses: (state, action) => {
      state.shippingAddresses = action.payload
    }
  },
})

export const { loginSuccess, logoutSuccess, setShippingAddresses } = userSlice.actions
export const userSelector = state => state.user
export default userSlice.reducer