import React from 'react'
import { useSelector } from 'react-redux'
import { Outlet } from 'react-router-dom'
import { Col, Row } from 'react-bootstrap'
import { ToastContainer } from 'react-toastify'

import 'react-toastify/dist/ReactToastify.css'
import '../assets/css/stylesBackoffice.scss'

import AdminSideMenu from '../pages/intranet/sidebar'
import MenuMainIntranet from '../pages/intranet/header'

import { companySelector } from '../slices/company'
import { userSelector } from '../slices/user'

const IntranetLayout = (props) => {
  const { brandLogo } = useSelector(companySelector)
  const { isLogged } = useSelector(userSelector)
  return (
    <>
      <MenuMainIntranet brandLogo={brandLogo} isLogged={isLogged} />
      <main className="margin-md">
        <Row className="m-0">
          <Col lg={2} className="border px-0 overflow-hidden">
            <AdminSideMenu />
          </Col>
          <Col lg={10} className="border min-vh-100">
            <Outlet />
          </Col>
        </Row>
      </main>

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </>
  )
}

export default IntranetLayout
