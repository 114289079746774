import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'

import ModalCustom from '../../../components/modal'
import ButtonsFooterModal from '../../../components/modal/buttonsFooter'
import { InputCustom as Input } from '../../../components/input'
import FileInput from '../../../components/input/fileInput'

import { editGallery } from '../../../api/company'

const EditGalleryModal = ({
  isOpen,
  setIsOpen,
  onSuccess,
  idGallery,
  dataGallery,
}) => {
  const [category, setCategory] = useState('')
  const [image, setImage] = useState(null)
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState({
    category: '',
    image: '',
  })

  useEffect(() => {
    setCategory(dataGallery?.category)
  }, [isOpen])

  const onSubmit = async () => {
    setLoading(true)
    setErrors({
      category: '',
      image: '',
    })
    if (category !== '') {
      const formData = new FormData()
      formData.append('category', category)
      if (image !== null) {
        formData.append('image', image)
      }
      const { status } = await editGallery(idGallery, formData)
      if (status === 200) {
        toast.success('Galería editado correctamente!')
        setIsOpen(false)
        onSuccess()
      } else {
        toast.error(`Error(${status}): Algo ha ido mal, intentalo nuevamente`)
      }
    } else {
      if (category === '') {
        setErrors((prev) => ({ ...prev, category: 'Campo obligatorio' }))
      }
    }
    setLoading(false)
  }

  return (
    <ModalCustom
      footer={
        <ButtonsFooterModal
          loading={loading}
          handleSuccess={onSubmit}
          handleClose={() => setIsOpen(false)}
        />
      }
      show={isOpen}
      size="md"
      title="Crear galería"
    >
      <Input
        error={errors.category}
        placeholder="Escribe un nombre de categoría"
        value={category}
        onChange={(e) => setCategory(e.target.value)}
      />
      <FileInput
        file={image}
        setFile={setImage}
        error={errors.image}
        preview={dataGallery?.image}
      />
    </ModalCustom>
  )
}

EditGalleryModal.propTypes = {
  dataGallery: PropTypes.shape({
    category: PropTypes.string,
    image: PropTypes.string,
  }),
  idGallery: PropTypes.number,
  isOpen: PropTypes.bool,
  onSuccess: PropTypes.func,
  setIsOpen: PropTypes.func,
}

export default EditGalleryModal
