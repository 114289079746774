import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import {
  MdChevronLeft,
  MdChevronRight,
  MdClose,
  MdRadioButtonUnchecked,
} from 'react-icons/md'
import './gallery.scss'

const Tile = (props) => {
  const [mouseOver, setMouseOver] = useState(false)
  const [modalShow, setModalShow] = useState(false)

  const handleModalClose = () => {
    setIndex(props.index)
    setModalShow(false)
  }
  const handleModalShow = () => setModalShow(true)
  const [index, setIndex] = useState(props.index)
  const [length, setLength] = useState(props.images.length - 1)

  function _mouseEnter(e) {
    e.preventDefault()
    if (!mouseOver) {
      setMouseOver(true)
    }
  }
  function _mouseLeave(e) {
    if (mouseOver) {
      setMouseOver(false)
    }
  }

  return (
    <>
      <div className="tile">
        <img
          onMouseEnter={_mouseEnter}
          onMouseLeave={_mouseLeave}
          onClick={handleModalShow}
          src={props.data.image}
          alt={props.data.category}
          className="tileStyle"
        />
      </div>

      <Modal
        show={modalShow}
        onHide={handleModalClose}
        size="xl"
        dialogClassName="modal-100w modal-gallery"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="closeButton">
          <MdClose
            className="styleButton"
            size={35}
            onClick={handleModalClose}
          />{' '}
        </div>
        <div className="contentImageModal">
          <div>
            {' '}
            {index !== 0 ? (
              <MdChevronLeft
                className="styleButton"
                size={35}
                onClick={() => setIndex(index - 1)}
              />
            ) : (
              <MdRadioButtonUnchecked size={35} />
            )}{' '}
          </div>
          <img src={props.images[index].image} className="styleImage" />
          <div>
            {' '}
            {index !== length ? (
              <MdChevronRight
                className="styleButton"
                size={35}
                onClick={() => setIndex(index + 1)}
              />
            ) : (
              <MdRadioButtonUnchecked size={35} />
            )}{' '}
          </div>
        </div>
      </Modal>
    </>
  )
}

export default Tile
