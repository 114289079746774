import PropTypes from 'prop-types'
import React from 'react'

import { Form } from 'react-bootstrap'

export const InputCustom = ({
  error,
  onChange,
  label,
  placeholder,
  icon,
  type,
  value,
  ...props
}) => {
  return (
    <Form.Group className="mb-3">
      {label ? <Form.Label>{label}</Form.Label> : null}
      <Form.Control
        className={icon ? 'input-text-icon' : 'input-text'}
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        {...props}
      />
      {error && (
        <Form.Control.Feedback className="d-block" type="invalid">
          {error}
        </Form.Control.Feedback>
      )}
    </Form.Group>
  )
}

InputCustom.propTypes = {
  error: PropTypes.string,
  icon: PropTypes.any,
  label: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.any,
}

export const SelectInputCustom = ({
  choices,
  label,
  placeholder,
  onCheckedItem,
  value,
}) => {
  return (
    <Form.Group controlId="exampleForm.SelectCustom">
      {label && <Form.Label>{label}</Form.Label>}
      <Form.Control
        placeholder={'pruebas'}
        as="select"
        custom
        onChange={(e) => onCheckedItem(e.target.value)}
        value={value}
      >
        {placeholder && <option value="">{placeholder}</option>}
        {choices.map((choice, i) => (
          <option value={choice.id} key={choice.id}>
            {choice.value}
          </option>
        ))}
      </Form.Control>
    </Form.Group>
  )
}

SelectInputCustom.propTypes = {
  choices: PropTypes.array.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  onCheckedItem: PropTypes.func,
  value: PropTypes.any,
}
