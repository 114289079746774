import React, { useState } from 'react'
import { Row, Col, Container } from 'react-bootstrap'
import img from '../../assets/img/404.JPG'

const NotFound = () => {
  const [hover, setHover] = useState(false)

  const handleMouseEnter = () => {
    setHover(true)
  }
  const handleMouseLeave = () => {
    setHover(false)
  }

  return (
    <Container>
      <Row>
        <Col>
          <img
            alt="Not Found"
            src={img}
            style={{
              maxWidth: '100%',
              height: 'auto',
              display: 'block',
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <h2
            style={{ textAlign: 'center', marginTop: '2rem' }}
            className="title"
          >
            Lo sentimos, algo ha fallado
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-md-center">
        <a
          href="/"
          className="nav-link"
          style={{
            color: 'rgba(0, 0, 0, 0.5)',
            ...(hover && { color: 'rgba(0, 0, 0, 1)' }),
          }}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          Volver al inicio
        </a>
      </Row>
    </Container>
  )
}

export default NotFound
