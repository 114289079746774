import React, { useState } from 'react'
import Button from 'react-bootstrap/Button'
import { toast } from 'react-toastify'
import PropTypes from 'prop-types'

//api
import { deleteConcourse } from '../../../api/concourse'
//components
import ModalCustom from '../../modal/index'
import ButtonsFooter from '../../modal/buttonsFooter'

const ModalDeleteConcourse = ({ id, isOpen, setIsOpen, getConcourses }) => {
  const [loading, setLoading] = useState(false)

  const handleClose = () => {
    setIsOpen(false)
  }

  const concourseDelete = async () => {
    setLoading(true)
    const { status } = await deleteConcourse(id)
    if (status === 204) {
      getConcourses()
      setIsOpen(false)
      toast('success', 'Concurso eliminado con éxito')
    } else {
      toast(`Error ${status}: algo ha ido mal, por favor vuelve a intentarlo`)
    }
    setLoading(false)
  }

  return (
    <ModalCustom
      show={isOpen}
      title={<h5 className="text-center">¿Está seguro de eliminar?</h5>}
      footer={
        <ButtonsFooter
          handleClose={handleClose}
          handleSuccess={concourseDelete}
          loading={loading}
        />
      }
    />
  )
}

ModalDeleteConcourse.propTypes = {
  id: PropTypes.number,
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  getConcourses: PropTypes.func,
}

export default ModalDeleteConcourse
