import PropTypes from 'prop-types'
import React from 'react'
import Button from '../buttons/ButtonCustom'

const ButtonsFooterModal = ({
  handleClose,
  handleSuccess,
  textSuccess,
  loading,
}) => {
  return (
    <div className="d-flex justify-content-end">
      <Button
        onClick={handleClose}
        size="lg"
        type={'cancel'}
        disabled={loading}
      >
        Cerrar
      </Button>
      {handleSuccess && (
        <Button type={'primary'} onClick={handleSuccess} disabled={loading}>
          {textSuccess}
        </Button>
      )}
    </div>
  )
}

ButtonsFooterModal.defaultProps = {
  textSuccess: 'Guardar',
}

ButtonsFooterModal.propTypes = {
  handleClose: PropTypes.func,
  handleSuccess: PropTypes.func,
  textSuccess: PropTypes.string,
  loading: PropTypes.bool,
}

export default ButtonsFooterModal
