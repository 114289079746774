import React, { useState } from 'react'
import ReactDOM from 'react-dom'
import { Container, Row, Col } from 'react-bootstrap'
import { MdErrorOutline } from 'react-icons/md'

const PaypalButtons = (props) => {
  const [loading, setLoading] = useState(false)
  let PayPalButton = null

  window.React = React
  window.ReactDOM = ReactDOM
  PayPalButton = window.paypal.Buttons ?
    window.paypal.Buttons ?.driver('react', {
      React,
      ReactDOM,
    })
    : null

  const items = props.items.map((value) => {
    return {
      name: value.titulo,
      unit_amount: {
        value: value.precio_venta_publico.toString(),
        currency_code: 'EUR',
      },
      quantity: value.quantity.toString(),
    }
  })

  const createOrder = (data, actions) => {
    return actions.order.create({
      purchase_units: [
        {
          amount: {
            currency_code: 'EUR',
            value: props.total,
            breakdown: {
              item_total: {
                value: props.subtotal,
                currency_code: 'EUR',
              },
              shipping: {
                value: props.shippingCosts,
                currency_code: 'EUR',
              },
            },
          },
          items: items,
        },
      ],
    })
  }

  const onApprove = async (data, actions) => {
    setLoading(true)
    const order = await actions.order.capture()
    const paymentData = {
      payerID: order.payerID,
      orderID: order.orderID,
    }
    props.pay(paymentData)
  }

  const failApiCall = () => {
    return (
      <div style={{textAlign: 'center'}}> 
        <div>
          <MdErrorOutline style={{color: 'red', fontSize: '28px'}} />
        </div>
        <div>
          Error de conexión, seleccione otro método de pago por favor.
        </div>
      </div>
    )
  }

  return (
    <>
      {loading && (
        <Container>
          <Row>
            <Col md={12} className="title">
              <h3>Procesando pago, por favor espere ....</h3>
            </Col>
          </Row>
        </Container>
      )}
      {PayPalButton !== null ? (
        <PayPalButton
          style={{ background: 'red' }}
          createOrder={(data, actions) => createOrder(data, actions)}
          onApprove={(data, actions) => onApprove(data, actions)}
        />
      ) :
       (failApiCall())
      }
    </>
  )
}

export default PaypalButtons
