import { methodGet, methodPut, methodPost, methodGetExport } from './methods'
import { API_INFO } from '../constans'

export const mergeEditorial = async (params) => {
  const url = API_INFO + 'products/editorials/merge/'
  return methodPost(url, params)
}

export const getReportExcel = async (url) => {
  const urlComplete =
    API_INFO + `products/editorials/${url}/report-product-excel`
  return methodGetExport(urlComplete)
}
