import React, { useEffect } from 'react'
import './assets/css/styles.scss'
import delay from 'delay'
import Cookies from 'js-cookie';

import MainRoute from './routes'

//context
import UserContextProvider from './context/userContext'
import { companySelector, loadMetaCompany, increaseCallMetadata } from './slices/company'
import { useSelector, useDispatch } from 'react-redux'
import { getMetadataCompany } from './api/company'
import setCSSVariables from './utils/setTheme'
import { themeBase } from './constans'
import Favicon from 'react-favicon'

function App() {
  const dispatch = useDispatch()
  const { favicon, theme, callMetadata } = useSelector(companySelector)

  const getMetadata = async () => {
    await delay(100)
    if (callMetadata % 8 === 0 || callMetadata === 1) {
      const { data, status } = await getMetadataCompany()
      dispatch(loadMetaCompany(data))
      setCss()
    }
    dispatch(increaseCallMetadata())
  }

  const setCss = () => {
    let themeCss = document.querySelectorAll('#x-theme-colors')
    if (themeCss.textContent === undefined) {
      setCSSVariables(theme)
    }
  }

  useEffect(() => {
    setCss()
  }, [theme])


  useEffect(() => {
    getMetadata()
  }, [])

  return (
    <div className="App">
      <Favicon url={favicon} />
      <UserContextProvider>
        <MainRoute />
      </UserContextProvider>
    </div>
  )
}

export default App
