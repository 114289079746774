import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'

import ModalCustom from '../../../components/modal'
import ButtonsFooterModal from '../../../components/modal/buttonsFooter'
import { InputCustom as Input } from '../../../components/input'
import FileInput from '../../../components/input/fileInput'

import { createGallery } from '../../../api/company'

const CreateGalleryModal = ({ isOpen, setIsOpen, onSuccess }) => {
  const [category, setCategory] = useState('')
  const [image, setImage] = useState(null)
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState({
    category: '',
    image: '',
  })

  useEffect(() => {
    setCategory('')
    setImage(null)
  }, [isOpen])

  const onSubmit = async () => {
    setLoading(true)
    setErrors({
      category: '',
      image: '',
    })
    if (category !== '' && image !== null) {
      const formData = new FormData()
      formData.append('category', category)
      formData.append('image', image)
      const { status } = await createGallery(formData)
      if (status === 201) {
        toast.success('Galería creada correctamente!')
        setIsOpen(false)
        onSuccess()
      } else {
        toast.error(`Error(${status}): Algo ha ido mal, intentalo nuevamente`)
      }
    } else {
      if (category === '') {
        setErrors((prev) => ({ ...prev, category: 'Campo obligatorio' }))
      }
      if (image === null || image === '') {
        setErrors((prev) => ({ ...prev, image: 'Campo obligatorio' }))
      }
    }
    setLoading(false)
  }

  return (
    <ModalCustom
      footer={
        <ButtonsFooterModal
          loading={loading}
          handleSuccess={onSubmit}
          handleClose={() => setIsOpen(false)}
        />
      }
      show={isOpen}
      size="md"
      title="Crear galería"
    >
      <Input
        error={errors.category}
        placeholder="Escribe un nombre de categoría"
        value={category}
        onChange={(e) => setCategory(e.target.value)}
      />
      <FileInput file={image} setFile={setImage} error={errors.image} />
    </ModalCustom>
  )
}

CreateGalleryModal.propTypes = {
  isOpen: PropTypes.bool,
  onSuccess: PropTypes.func,
  setIsOpen: PropTypes.func,
}

export default CreateGalleryModal
