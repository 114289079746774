import React from 'react'
import { Spinner } from 'react-bootstrap'

const SpinnerCustom = () => {
  return (
    <Spinner
      className="loader-section__spinner"
      animation="border"
      variant="secondary"
    />
  )
}

export default SpinnerCustom
