import React, { useState, useEffect, useContext } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { cartSelector, clear } from '../../slices/cart'
import Form from 'react-bootstrap/Form'
import {
  StepperAction,
  StepperContent,
  StepperContext,
} from 'react-material-stepper'
import { Spinner, Row, Col, Alert, Button } from 'react-bootstrap'
import { MdErrorOutline } from 'react-icons/md'
import { createOrder } from '../../api/ecommerce'
import TPV from './tpv'
import { COMPANY } from '../../constans'
import './cart.scss'
import Items from './items'
import PaypalButton from './paypal_buttons'
import { userSelector } from '../../slices/user'
import { UserContext } from '../../context/userContext'

const PaymentMethods = ({ onFinish }) => {
  const [next, setNext] = useState('')
  const { showSignUpModal } = useContext(UserContext)
  const { 
    items, 
    shippingCosts, 
    total,
    shippingAddress,
    billingAddress,
    storePickup,
    isNewShippingAddress  
  } = useSelector(cartSelector)
  const { isLogged } = useSelector(userSelector)
  const [totalPaypal, setTotalPaypal] = useState(0)
  const [paymentMethod, setPaymentMethod] = useState(0)
  const [loading, setLoading] = useState(false)
  const [confirmOrderCall, setConfirmOrderCall] = useState(true)
  const dispatch = useDispatch()
  const { goAt, getData } = React.useContext(StepperContext)

  useEffect(() => {
    window.document.title = 'Carrito de compras | ' + COMPANY
  }, [])

  const failApiCall = () => {
    return (
      <div style={{textAlign: 'center'}}> 
        <div>
          <MdErrorOutline style={{color: 'red', fontSize: '28px'}} />
        </div>
        <div>
          Error de conexión
        </div>
        <div onClick={()=>confirmOrder(false)} style={{color: '#0000EE', cursor: 'pointer'}}>
          Has click aquí para intentar nuevamente
        </div>
      </div>
    )
  }

  useEffect(() => {
    if (paymentMethod === 4) {
      confirmOrder(false)
    } else if (paymentMethod === 2) {
      confirmOrder(false)
      setNext(false)
    } else if (paymentMethod === 1 || paymentMethod === 5) {
      setConfirmOrderCall(true)
      setNext(
        <StepperAction
          type="submit"
          align="center"
          className="button button--primary"
        >
          Finalizar Compra
        </StepperAction>
      )
    }
  }, [paymentMethod])

  const billing_address = getData('billing_address', {})

  const back = () => goAt('shipping_address')

  const confirmOrder = async (finishProcess) => {
    const payload = {
      forma_pago: paymentMethod,
      billing_address: billing_address? billing_address : {},
      shipping_address: shippingAddress? shippingAddress : {},
      is_new_shipping_address: isNewShippingAddress,
      store_pickup: storePickup,
      items,
      shipping_cost: shippingCosts,
      finish_process: finishProcess,
    }
    try {
      const { status, data } = await createOrder(payload)
      if (status === 201 && finishProcess) {
        dispatch(clear())
        onFinish(data.id, data.forma_pago)
      } else if (status === 201 && !finishProcess) {
        if (paymentMethod === 2) {
          setConfirmOrderCall(true)
        } else if (paymentMethod === 4) {
          setConfirmOrderCall(true)
          setNext(
            <StepperAction align="center">
                <TPV />            
            </StepperAction>
          )
        } else if (paymentMethod === 1 || paymentMethod === 5) {
          setConfirmOrderCall(true)
          setNext(false)
        }
      } else {
        setConfirmOrderCall(false)
        setNext(false)
        console.log('error')
      }
    } catch (error) {
      setConfirmOrderCall(false)
      setNext(false)
      console.log(error)
    }
  }

  const onSubmit = async (event) => {
    event.preventDefault()
    event.stopPropagation()
    confirmOrder(true)
  }

  const payPaypal = async (data) => {
    setLoading(true)
    const payload = {
      forma_pago: paymentMethod,
      id_paypal: data.orderID,
      billing_address: billing_address? billing_address : {},
      shipping_address: shippingAddress? shippingAddress : {},
      is_new_shipping_address: isNewShippingAddress,
      store_pickup: storePickup,
      items,
      shipping_cost: shippingCosts,
      finish_process: true,
    }
    try {
      const { status, data } = await createOrder(payload)
      if (status === 201) {
        dispatch(clear())
        onFinish(data.id, data.forma_pago)
      } else {
        console.log('error')
      }
    } catch (error) {
      console.log(error.response)
    }
  }

  const callTPV = () => {
    setPaymentMethod(4)
  }

  const callPaypal = () => {
    const totPay = parseFloat(total) + parseFloat(shippingCosts)
    setTotalPaypal(totPay.toFixed(2))
    setPaymentMethod(2)
  }

  if (isLogged) {
    return (
      <StepperContent
        onSubmit={onSubmit}
        actions={
          <>
            <StepperAction
              className="button button--outline button--primary"
              type="button"
              onClick={back}
            >
              Anterior
            </StepperAction>
            {next}
          </>
        }
      >
        <div className="resumeContainer">
          <div className="titleResume">Este es el resumen de tu pedido</div>
          <div style={{ width: '100%', height: '30px' }}>
            <hr />
          </div>
          <div className="itemsContainer">
            <Items isResume={true} />
          </div>
        </div>
        <Row className="margin-top-md">
          <Col md={12}>
            {!loading ? (
              <>
                { storePickup && (
                  <Form.Check
                    onChange={(e) => setPaymentMethod(5)}
                    type="radio"
                    label="Pago en tienda"
                    name="payment_methods"
                    id="cash_payment"
                    required
                  />
                )}
                <Form.Check
                  onChange={(e) => setPaymentMethod(1)}
                  type="radio"
                  label="Transferencia Bancaria"
                  name="payment_methods"
                  id="wire_transfer"
                  required
                />
                <Form.Check
                  onChange={(e) => callTPV()}
                  type="radio"
                  label="Tarjeta de Crédito/Débito"
                  name="payment_methods"
                  id="tpv"
                  required
                />
                <Form.Check
                  onChange={(e) => callPaypal()}
                  type="radio"
                  label="Paypal / Tarjeta Bancaria"
                  name="payment_methods"
                  id="paypal"
                  required
                />
                <br />
                {paymentMethod == 2 && confirmOrderCall &&
                  <PaypalButton
                    subtotal={total}
                    shippingCosts={shippingCosts}
                    total={totalPaypal}
                    pay={payPaypal}
                    items={items}
                  /> 
                }
                {!confirmOrderCall && paymentMethod !== 0 &&
                  failApiCall()
                }
              </>
            ) : (
              <Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
              </Spinner>
            )}
          </Col>
        </Row>
      </StepperContent>
    )
  } else {
    return (
      <StepperContent
        onSubmit={onSubmit}
        actions={
          <>
            <StepperAction
              className="button button--outline button--primary"
              type="button"
              onClick={back}
            >
              Anterior
            </StepperAction>
            {next}
          </>
        }
      >
        <Row>
          <Col>
            <Alert className="flex-column" show={true} variant="info">
              <Alert.Heading>Información incompleta</Alert.Heading>
              <p>
                Para continuar debes registrarte e ingresar tus datos de
                facturación.
              </p>
              <hr />
              <div className="d-flex justify-content-end">
                <Button
                  onClick={() => showSignUpModal(true)}
                  className="button button--primary"
                >
                  Registrarme Ahora
                </Button>
              </div>
            </Alert>
          </Col>
        </Row>
      </StepperContent>
    )
  }
}

export default PaymentMethods
