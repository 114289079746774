import React, { useState, useEffect, useMemo } from 'react'
import Button from 'react-bootstrap/Button'
import { FormGroup, Container } from 'react-bootstrap'
import { useForm, Controller } from 'react-hook-form'
import { toast } from 'react-toastify'
import PropTypes from 'prop-types'
//api
import { createConcourse } from '../../../api/concourse'
import { getListUsers } from '../../../api/users'
//components
import ModalCustom from '../../modal/index'
import CustomMultipleInput from '../../custommultipleInput/index'
import { Autocomplete } from '../../autocomplete/index'
import ButtonsFooter from '../../modal/buttonsFooter'
import AddList from '../addList'

const ModalCreate = ({ openModal, setOpenModal, getConcourses }) => {
  const [listUsers, setListUsers] = useState([])
  const [usersLibrary, setUsersLibrary] = useState([])
  const [validateErrors, setValidateErrors] = useState('')
  const [loading, setLoading] = useState(false)
  const {
    register,
    reset,
    formState: { errors },
    setValue,
    control,
    handleSubmit,
  } = useForm()

  const handleClose = () => {
    setOpenModal(false)
    reset(resetData)
    setUsersLibrary([])
  }

  const getUsers = async () => {
    const { data, status } = await getListUsers()
    if (status === 200) {
      setListUsers(data.results)
    } else {
    }
  }

  useEffect(() => {
    if (openModal) {
      getUsers()
    }
  }, [openModal])

  const resetData = {
    nombre: '',
    cliente: '',
    descuento: '',
    usuario_especial: null,
    usuarios_biblioteca: null,
  }

  const optionsUsers = useMemo(
    () =>
      listUsers.map((listUser) => {
        return { label: listUser.username, value: listUser.id }
      }),
    [listUsers]
  )

  const handleValue = (value) => {
    const array = usersLibrary.some((item) => item.value === value.value)
    if (array) {
      setValidateErrors('Este usuario ya está en la lista.')
      return
    }
    setUsersLibrary([...usersLibrary, value])
  }

  const onSubmit = async (values) => {
    setLoading(true)
    const formData = new FormData()
    formData.append('nombre', values.nombre)
    formData.append('cliente', Number(values.cliente))
    formData.append('descuento', Number(values.descuento))
    formData.append('usuario_especial', values.usuario_especial.value)
    usersLibrary.forEach((item) =>
      formData.append('usuarios_biblioteca', item.value)
    )
    try {
      const { status } = await createConcourse(formData)

      if (status === 201) {
        reset(resetData)
        getConcourses()
        setUsersLibrary([])
        setOpenModal(false)
        toast('EL concurso fué creado con éxito.')
      } else {
        toast(`Error ${status}: Algo ha ido mal, por favor vuelve a intentarlo`)
      }
    } catch (error) {
      console.log(error)
    }
    setLoading(false)
  }

  return (
    <Container>
      <ModalCustom
        show={openModal}
        title={<h3 className="text-center">Crear concurso</h3>}
        className="max-width-modal"
        footer={
          <ButtonsFooter
            handleSuccess={handleSubmit(onSubmit)}
            handleClose={handleClose}
            loading={loading}
          />
        }
      >
        <CustomMultipleInput onSubmit={handleSubmit(onSubmit)}>
          <div className="d-flex  justify-content-center flex-wrap ml-5">
            <FormGroup className="d-flex flex-column width-40">
              <label>Nombre</label>
              <input
                type="text"
                className="input-round height-input"
                {...register('nombre', {
                  required: {
                    value: true,
                    message: 'Campo requerido',
                  },
                })}
              />
              {errors.nombre && (
                <small className="text-danger">{errors.nombre.message}</small>
              )}
            </FormGroup>
            <FormGroup className="d-flex flex-column width-40 margin-left-15">
              <label>Cliente</label>
              <input
                type="text"
                className="input-round height-input"
                {...register('cliente', {
                  required: {
                    value: true,
                    message: 'Campo requerido',
                  },
                  pattern: {
                    value: /^[0-9]*$/,
                    message: 'Sólo se permite números',
                  },
                })}
              />
              {errors.cliente && (
                <small className="text-danger">{errors.cliente.message}</small>
              )}
            </FormGroup>
            <FormGroup className=" d-flex flex-column width-40">
              <label>Descuento</label>
              <input
                type="text"
                className="input-round height-input"
                {...register('descuento', {
                  required: {
                    value: true,
                    message: 'Campo requerido',
                  },
                  pattern: {
                    value: /^[0-9]*$/,
                    message: 'Sólo se permite números',
                  },
                })}
              />
              {errors.descuento && (
                <small className="text-danger">
                  {errors.descuento.message}
                </small>
              )}
            </FormGroup>
            <FormGroup className="ml-5 width-40 " controlId="usuario_especial">
              <Controller
                control={control}
                name="usuario_especial"
                rules={{ required: 'Campo obligatorio' }}
                render={({ field: { onChange, value, name } }) => {
                  return (
                    <div>
                      <Autocomplete
                        id="usuario_especial"
                        isDisabled={false}
                        label="Usuario especial"
                        required
                        asyncFetch={optionsUsers}
                        onSelect={(value) => {
                          setValue('usuario_especial', value.value)
                          onChange(value.value)
                        }}
                        placeholder={'Seleccionar'}
                      />
                    </div>
                  )
                }}
              />
              {errors.usuario_especial && (
                <small className="text-danger">
                  {errors.usuario_especial.message}
                </small>
              )}
            </FormGroup>

            <FormGroup
              className=" mt-2 width-40 "
              controlId="usuarios_biblioteca"
            >
              <div>
                <Controller
                  control={control}
                  name="usuarios_biblioteca"
                  render={({ field: { onChange, value, name } }) => {
                    return (
                      <div>
                        <Autocomplete
                          id="usuarios_biblioteca"
                          isDisabled={false}
                          label="Usuarios biblioteca"
                          required
                          asyncFetch={optionsUsers}
                          onSelect={(value) => {
                            handleValue(value)
                            onChange(value)
                          }}
                          placeholder={'Seleccionar'}
                        />
                      </div>
                    )
                  }}
                />
                {errors.usuarios_biblioteca && (
                  <small className="text-danger">
                    {errors.usuarios_biblioteca.message}
                  </small>
                )}
                {validateErrors && (
                  <p className="text-danger">
                    <small>{validateErrors}</small>
                  </p>
                )}
                <AddList
                  usersLibrary={usersLibrary}
                  setUsersLibrary={setUsersLibrary}
                />
              </div>
            </FormGroup>
          </div>
        </CustomMultipleInput>
      </ModalCustom>
    </Container>
  )
}

ModalCreate.propTypes = {
  openModal: PropTypes.bool,
  setOpenModal: PropTypes.func,
  getConcourses: PropTypes.func,
}

export default ModalCreate
