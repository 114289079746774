import React, { useState, useEffect } from 'react'
import { Container, Row, Col, ListGroup } from 'react-bootstrap'

import { featuredMattersGeslibApi } from '../../api/libros'
import { COMPANY } from '../../constans'

const FeaturedMatters = () => {
  const [matters, setMatters] = useState([])

  useEffect(() => {
    const loadData = async () => {
      let cont = 0
      let matters_lists = [[], [], []]
      const { data } = await featuredMattersGeslibApi()
      data.map((item) => {
        matters_lists[cont].push(item)
        if (cont === 2) cont = 0
        else cont = cont + 1
      })
      setMatters(matters_lists)
    }
    loadData()
  }, [])

  useEffect(() => {
    window.document.title = 'Materias destacadas | ' + COMPANY
  }, [matters])

  return (
    <Container style={{ marginBottom: 45 }}>
      <Row>
        <Col md={12} className="title">
          <h2>Materias destacadas</h2>
          <span className="divider-title"></span>
        </Col>
        {matters.map((itemMatters, i) => (
          <Col md={4} key={i}>
            <ListGroup>
              {itemMatters.map((item, k) => (
                <ListGroup.Item key={k} action href={`/materia/${item.url}`}>
                  {item.name}
                </ListGroup.Item>
              ))}
            </ListGroup>
          </Col>
        ))}
      </Row>
    </Container>
  )
}

export default FeaturedMatters
