import React, { memo, useEffect, useState } from 'react'

import { Button, Row, Col } from 'react-bootstrap'
import { Link, useParams } from 'react-router-dom'
import PropTypes from 'prop-types'
// import { useDispatch } from 'react-redux'
// import { useSelector } from 'react-redux'
//api
// import { addItemCart } from '../../api/ecommerce'
//import { getDetailProduct } from '../../api/products'
//helpers
import { checkEmpty } from '../../helpers'
//slices
// import { addItem } from '../../slices/cart'
// import { userSelector } from '../../slices/user'
import Authors from '../../components/authors'
//preview para libros sin portada
import preview from '../../assets/img/preview.jpg'
//img
import CoverImg from '../coverImage/CoverImage'

const Item = (props) => {
  const { data } = props
  const urlItem = checkEmpty(data) ? `/productos/${data.type}/${data.url}/` : ''
  let { type } = useParams()

  // const dispatch = useDispatch()
  // const { isLogged } = useSelector(userSelector)
  // const [autorUrl, setAutorUrl] = useState('')
  // const [merchandising, setMerchandising] = useState()

  // const getAuthor = async () => {
  //   const rs = await getDetailProduct(data?.url, { type })
  //   if (rs.status === 200) {
  //     const { data } = rs
  //     setAutorUrl(data.autor[0]?.url)
  //   }
  // }

  const goUp = () => {
    window.scrollTo(0, 0)
  }

  return (
    <div className="store-item mx-3 p-auto">
      <Row className="store-cover">
        <div className="img-container">
          <div className="overlay"></div>
          {data.get_url !== '' || data.cover !== '' ? (
            <Link to={urlItem} onClick={goUp} data-testid="cover-img">
              <CoverImg
                src={data.get_url ? data.get_url : data.cover}
                style={{ marginLeft: '1rem' }}
              />
            </Link>
          ) : (
            <Link to={urlItem} onClick={goUp}>
              <CoverImg src={preview} />
            </Link>
          )}
        </div>
      </Row>
      <Row className="img-caption">
        <Col xs={12}>
          <Link className="store-item__link" to={urlItem}>
            <h5 className="title limit-long" title={data.titulo}>
              {data.titulo}
            </h5>
          </Link>
        </Col>
        <Col xs={12} className="subtitle limit-long">
          {/* {data.autors && (
            <Link className="store.item__link" to={`/autor/${autorUrl}`}>
              <h6
                className="subtitle limit-long"
                title={data.autors}
                data-testid="author-name"
              >
                {data.autors}
              </h6>
            </Link>
          )} */}

          {data.autors_all && <Authors elements={data.autors_all} />}
        </Col>
      </Row>
      <Link to={urlItem} onClick={goUp}>
        <Button
          variant="outline-dark"
          size="sm"
          title="ver producto"
          block
          data-testid="price"
        >
          {data.precio_venta_publico} €
        </Button>
      </Link>
    </div>
  )
}

Item.propTypes = {
  data: PropTypes.object,
}

export default memo(Item, (prev, next) => {
  return prev.data.id === next.data.id
})
