import { methodGet, methodPostForm, methodPatch, methodDelete } from './methods'
import { API_INFO } from '../constans'

export const getListConcourse = async (payload) => {
  const url = API_INFO + 'user/concourse/'
  return methodGet(url, payload)
}

export const createConcourse = async (params) => {
  const url = API_INFO + `user/concourse/`
  return methodPostForm(url, params)
}
export const deleteConcourse = async (id) => {
  const url = API_INFO + `user/concourse/${id}/`
  return methodDelete(url)
}

export const editItemConcourse = async (id, params) => {
  const url = API_INFO + `user/concourse/${id}/`
  return methodPatch(url, params)
}
