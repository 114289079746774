import React from 'react'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import PropTypes from 'prop-types'

const CustomMultipleInput = ({ onSubmit, children, className }) => {
  return (
    <Form onSubmit={onSubmit} className={className}>
      {children}
    </Form>
  )
}

CustomMultipleInput.propTypes = {
  onSubmit: PropTypes.func,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
}

export default CustomMultipleInput
