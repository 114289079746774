import React, { useState, useCallback } from 'react'
import { Form, Button } from 'react-bootstrap'
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai'
import { useNavigate } from 'react-router-dom'
import { useForm, Controller } from 'react-hook-form'
import { toast, ToastContainer } from 'react-toastify'
//api
import { loginIntranet } from '../../../api/login'
//img
import avatar from '../../../assets/img/login-avatar.png'
//components
import CardCustom from '../../../components/card/index'
import CustomMultipleInput from '../../../components/custommultipleInput/index'
import ButtonCustom from '../../../components/buttons/ButtonCustom'

const resetData = {
  username: '',
  password: '',
}

const Login = () => {
  const navigate = useNavigate()
  const [passwordShown, setPasswordShown] = useState(false)
  const [loading, setLoading] = useState(false)
  const {
    register,
    reset,
    control,
    formState: { errors },
    getValues,
    handleSubmit,
    setValue,
  } = useForm({})

  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true)
  }

  const onSubmit = async (values) => {
    setLoading(true)
    const payload = {
      username: values.username,
      password: values.password,
    }
    try {
      const { status } = await loginIntranet(payload)
      if (status === 200) {
        toast.success('Redirigiendo a intranet!')
        reset(resetData)
        navigate('/intranet')
      } else {
        toast.error(`Error(${status}): Algo ha ido mal, intentalo nuevamente`)
      }
    } catch (e) {
      console.log(e)
    }
    setLoading(false)
  }

  return (
    <div className="container-login ">
      <CardCustom title="Iniciar sesión" className="card-login">
        <div className="d-flex justify-content-center ">
          <img src={avatar} className="avatar-login" />
        </div>

        <CustomMultipleInput
          onSubmit={handleSubmit(onSubmit)}
          className="d-flex h-100 flex-column  p-3 w-100 align-items-center"
        >
          <Form.Group className="d-flex flex-column w-100 justify-content-center">
            <Form.Label>Usuario</Form.Label>
            <Form.Control
              type="text"
              className="input-round"
              {...register('username', {
                required: {
                  value: true,
                  message: 'Campo obligatorio',
                },
              })}
            />
            {errors.username && (
              <small className="text-danger error-message-register">
                {errors.username.message}
              </small>
            )}
          </Form.Group>
          <Form.Group className="d-flex flex-column w-100 position-relative">
            <Form.Label>Contraseña</Form.Label>
            <Form.Control
              type={passwordShown ? 'text' : 'password'}
              className="input-round "
              {...register('password', {
                required: {
                  value: true,
                  message: 'Campo obligatorio',
                },
              })}
              className="w-100 size-input"
            />
            <span
              className="position-absolute position-eye"
              onClick={togglePasswordVisiblity}
            >
              {passwordShown ? <AiFillEye /> : <AiFillEyeInvisible />}{' '}
            </span>
            {errors.password && (
              <small className="text-danger error-message-register">
                {errors.password.message}
              </small>
            )}
          </Form.Group>
          <ButtonCustom onClick={handleSubmit(onSubmit)} disabled={loading}>
            Enviar
          </ButtonCustom>
        </CustomMultipleInput>
      </CardCustom>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </div>
  )
}

export default Login
