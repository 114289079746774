import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';

// own 
import CardEvent from '../../components/event/cardEvent';
import { getEvents } from '../../api/company';
import { COMPANY } from '../../constans';

const Events = (props) => {
  const [events, setEvents] = useState([])
  const [page, setPage] = useState(1)
  const [next, setNext] = useState(false)

  useEffect(() => {
    window.document.title = "Eventos | " + COMPANY
    handleEvents();
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [])

  useEffect(() => {
    handleEvents()
  }, [page])

  async function handleEvents() {
    const rs = await getEvents({page})
    if(rs.status === 200){
      const { results } = rs.data
      setNext(() => rs.data.next !== null ? true : false )
      const moreEvents = [ ...events, ...results]
      setEvents(moreEvents)
    }
  }

  function handleScroll (){
    if (window.innerHeight + document.documentElement.scrollTop !== document.documentElement.offsetHeight ) return;
    setPage(prevPage => prevPage + 1)
  }  

  return (
    <Container>
      <Col md={12} className="title">
          <h2 >Eventos</h2>
          <span className="divider-title"></span>
        </Col>
      <Row className="padding-top-md">
        {events.length > 0 ? (
          events.map(item => (
            <Col md={6} key={item.id}>
              <CardEvent event={item} />
            </Col>
          ))
        ) : (
          <h1 className="notice-message" >No hay eventos programados</h1>
        )}
      </Row>
    </Container>
  )
}

export default Events