export const requiredText = 'Campo requerido'

const formText = {
  email: {
    required: 'Ingresa tu email',
    error: 'Email incorrecto',
  },
  phone: {
    error: 'Número telefónico incorrecto',
  },
  zipCode: {
    error: 'código postal inválido',
  },
}

export default formText
