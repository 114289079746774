import React from 'react'

const SliderClientes = () => {
  return (
    <div>
      <h1>Slider Clientes</h1>
    </div>
  )
}

export default SliderClientes
