import React from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'react-bootstrap'

const ModalCustom = (props) => {
  return (
    <Modal
      show={props.show}
      centered
      size={props.size}
      className={props.className}
    >
      <Modal.Header>
        <Modal.Title className="text-center">{props.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{props.children}</Modal.Body>
      <Modal.Footer>{props.footer}</Modal.Footer>
    </Modal>
  )
}

ModalCustom.propTypes = {
  show: PropTypes.bool,
  size: PropTypes.string,
  title: PropTypes.object,
  children: PropTypes.node.isRequired,
  footer: PropTypes.node,
  className: PropTypes.string,
}

export default ModalCustom
