import React from 'react'
import { Row, Col, Container, Card } from 'react-bootstrap'
import { Link } from 'react-router-dom'

//iconos
import { BsFillPeopleFill, BsFillInboxesFill } from 'react-icons/bs'
import { FaBookOpen, FaTruckMoving } from 'react-icons/fa'
import { MdPhonelink } from 'react-icons/md'
import { SiPostman } from 'react-icons/si'
import { BiSlider, BiExit, BiHome } from 'react-icons/bi'
import { GrGallery } from 'react-icons/gr'
import { RiCupFill } from 'react-icons/ri'
import { AiOutlineOrderedList, AiFillEdit } from 'react-icons/ai'
import { FiPackage } from 'react-icons/fi'
import { GiLaurelsTrophy } from 'react-icons/gi'

//componentes
import PasswordModal from '../ModalPassword'
import './sidebar.scss'

const HomeSection = () => {
  return (
    <Card className="p-0">
      <Card.Header className="p-0">
        <p className="card-header">TABLERO</p>
      </Card.Header>
    </Card>
  )
}
const SECTIONS = [
  ['Inicio', { endpoint: '/', icon: <BiHome className="mr-3" /> }],
  [
    'Concursos',
    { endpoint: 'concursos', icon: <GiLaurelsTrophy className="mr-3" /> },
  ],
  [
    'Clientes',
    { endpoint: 'clientes', icon: <BsFillPeopleFill className="mr-3" /> },
  ],
  [
    'Productos',
    { endpoint: 'productos', icon: <BsFillInboxesFill className="mr-3" /> },
  ],
  ['Autores', { endpoint: 'autores', icon: <FaBookOpen className="mr-3" /> }],
  [
    'Pedidos',
    { endpoint: 'pedidos', icon: <FaTruckMoving className="mr-3" /> },
  ],
  [
    'Suscriptores',
    { endpoint: 'suscriptores', icon: <MdPhonelink className="mr-3" /> },
  ],
  [
    'Gestionar Posts',
    { endpoint: 'gestionarPosts', icon: <SiPostman className="mr-3" /> },
  ],
  [
    'Slider Clientes',
    { endpoint: 'sliderClientes', icon: <BiSlider className="mr-3" /> },
  ],
  ['Galeria', { endpoint: 'galeria', icon: <GrGallery className="mr-3" /> }],
  [
    'Merchandising',
    { endpoint: 'merchandising', icon: <RiCupFill className="mr-3" /> },
  ],
  ['Slider', { endpoint: 'slider', icon: <BiSlider className="mr-3" /> }],
  [
    'Listas',
    { endpoint: 'listas', icon: <AiOutlineOrderedList className="mr-3" /> },
  ],
  [
    'Editoriales',
    { endpoint: 'editoriales', icon: <AiFillEdit className="mr-3" /> },
  ],
  [
    'Preparacion de pedidos',
    { endpoint: 'preparacionPedidos', icon: <FiPackage className="mr-3" /> },
  ],
  ['Cambiar password', { endpoint: 'cambiarPassowrd', modal: true }], // este seria el boton para abrir el modal
  ['Salir', { endpoint: '', icon: <BiExit className="mr-3" /> }],
]

const AdminSideMenu = () => {
  return (
    <Container fluid className="px-0">
      <Row className="adminAsideRow">
        <Col lg={12} className="m-0 ml-2">
          <HomeSection />
          {SECTIONS.map(([SectionTitle, { endpoint, modal, icon }], index) => (
            <Card key={`Section#${index}`} className="p-0">
              <Card.Header className="p-0 mb-2">
                {modal ? (
                  <PasswordModal />
                ) : (
                  <Link
                    key={`seccion${endpoint + index}`}
                    to={endpoint ? `/intranet/${endpoint}` : '/intranet/login'}
                    className="text-left w-100 pt-1 mb-1 link-text"
                  >
                    {icon}
                    {SectionTitle}
                  </Link>
                )}
              </Card.Header>
            </Card>
          ))}
        </Col>
      </Row>
    </Container>
  )
}

export default AdminSideMenu
