import React, { useState, useEffect } from 'react'
import Button from 'react-bootstrap/Button'

//api
import { editPost } from '../../../../api/blog'

//components
import ModalCustom from '../../../../components/modal'
import CustomInput from '../../../../components/customInput'
import Form from 'react-bootstrap/Form'

const ModalEditPost = ({ dataPost, isModal, setIsModal, getPosts }) => {
  const [postTitle, setPostTitle] = useState('')
  const [postBody, setPostBody] = useState('')

  useEffect(() => {
    setPostTitle(dataPost?.title)
    setPostBody(dataPost?.featured_paragraph)
  }, [dataPost])

  //   const updatePost = async () => {
  //     const { status } = await editPost(url, data)
  //     if (status === 200) {
  //         getPosts()
  //         setIsModal(false)
  //     } else {
  //         setIsModal(true)
  //     }
  // setNamePost('')
  //   }

  const handleClose = () => {
    setIsModal(false)
  }
  //   const handleValue = (e) => {
  //     setNamePost(e.target.value)
  //   }

  const Buttonfooter = () => {
    return (
      <div className="text-center">
        <Button variant="secondary" onClick={handleClose} className="mr-3">
          Cancelar
        </Button>
        {/* <Button variant="secondary" onClick={updateNamePost}>
          Aceptar
        </Button> */}
      </div>
    )
  }

  return (
    <ModalCustom
      show={isModal}
      title={<p className="text-center">Modificar Post</p>}
    >
      <div className="margin-bottom-30">
        <CustomInput
          //   onChange={handleValue}
          inputValue={postTitle}
          type={'text'}
          label={'Título del post'}
        />
        <Form.Group>
          <Form.Label>Texto Post</Form.Label>
          <Form.Control as="textarea" rows={3} defaultValue={postBody} />
          {/* <Form.Text>
                {postBody}
              </Form.Text> */}
        </Form.Group>
        {/* <CustomInput
        //   onChange={handleValue}
        inputValue={postBody}
        type={'text'}
        label={'Cuerpo del post'}
        /> */}
      </div>
      <Buttonfooter />
    </ModalCustom>
  )
}

export default ModalEditPost
