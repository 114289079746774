import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

const Authors = (props) => {
  return props.elements.map((value, i) => (
    <React.Fragment key={value.id}>
      <Link
        className="store-item__link"
        to={`/autor/${value.url}/`}
        data-testid="author-link"
      >
        <span>{value.nombre_autor}</span>
      </Link>
      ,
    </React.Fragment>
  ))
}

Authors.propTypes = {
  elements: PropTypes.array,
}

export default Authors
