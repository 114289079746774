import { methodGet } from './methods'
import { API_INFO } from '../constans'

export async function listEncuadernacion(slug) {
  const url = API_INFO + `libros/libros/list-encuadernacion/${slug}`
  return methodGet(url, {})
}

export async function getMaterias(slug) {
  const url = API_INFO + `libros/materias-cervantes/${slug}`
  return methodGet(url, {})
}

export async function listIdiomas(id, query) {
  const url = API_INFO + 'libros/libros/list-idiomas/'
  return methodGet(url, query)
}

export async function listPaises(query) {
  const url = API_INFO + 'libros/libros/list-paises/'
  return methodGet(url, query)
}

export const getMateriasByCompany = () => {
  const url = API_INFO + 'libros/materias/main-materies/'
  return methodGet(url)
}

export const detailMateryGeslibApi = (code) => {
  const url = API_INFO + 'libros/materias-geslib/' + code
  return methodGet(url)
}

export const getDetailMateryGeslib = (slug) => {
  const url = API_INFO + 'libros/materias-geslib/' + slug
  return methodGet(url)
}

export const getDetailMateryIbic = (slug) => {
  const url = API_INFO + 'libros/materias-ibic/' + slug
  return methodGet(url)
}

export const getElementsMateryGeslib = (slug, page) => {
  const url =
    API_INFO + 'libros/materias-geslib/' + slug + '/get-products?page=' + page
  return methodGet(url)
}

export const getElementsMateryIbic = (slug, page) => {
  const url =
    API_INFO + 'libros/materias-ibic/' + slug + '/get-products?page=' + page
  return methodGet(url)
}

export const featuredMattersGeslibApi = () => {
  const url = API_INFO + 'libros/materias-geslib/featured-matters'
  return methodGet(url)
}
