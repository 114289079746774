import React from 'react'
import { Card, Row, Col } from 'react-bootstrap'
import { AiOutlineClockCircle, AiOutlineIdcard } from 'react-icons/ai'
import PropTypes from 'prop-types'
// own
import '../../assets/css/event.scss'
import { truncate } from '../../helpers'
import { MONTH_DIS } from '../../constans'

const CardEvent = (props) => {
  const { start_date, finish_date, start_time, id } = props.event
  const start_month = parseInt(start_date.split('-')[1])
  const start_day = parseInt(start_date.split('-')[2])
  const finish_month = parseInt(finish_date.split('-')[1])
  const finish_day = parseInt(finish_date.split('-')[2])

  return (
    <Card className="card-event">
      <Card.Header
        className="header"
        style={{ backgroundImage: `url('${props.event.cover}')` }}
      >
        <span>{props.event.title}</span>
      </Card.Header>
      <Card.Body>
        <div className="card-text">
          <div className="date">
            {start_month === finish_month ? (
              <>
                <span className="month">{MONTH_DIS[start_month - 1]}.</span>{' '}
                <br />
                <span className="day">
                  {start_day !== finish_day ? (
                    <>
                      {start_day} - {finish_day}
                    </>
                  ) : (
                    <>{start_day}</>
                  )}
                </span>
              </>
            ) : (
              <span className="day">
                {MONTH_DIS[start_month - 1]}. {start_day}-
                {MONTH_DIS[finish_month - 1]}. {finish_day}
              </span>
            )}
          </div>
          <div className="description">
            {truncate(props.event.description, 120)}
          </div>
        </div>
        <Row className="margin-top-sm">
          <Col className="text-center text-secondary v-center">
            <AiOutlineClockCircle />
            {'  '} {start_time}
          </Col>
          <Col className="text-center text-secondary v-center">
            <AiOutlineIdcard />
            {'   '}
            <a className="text-secondary" href={`/eventos/${id}`}>
              Ver más
            </a>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  )
}

CardEvent.propTypes = {
  event: PropTypes.object,
}

export default CardEvent
