import PropTypes from 'prop-types'
import React from 'react'
import Table from 'react-bootstrap/Table'

import IntranetPagination from '../pagination/paginationIntranet'
import NoResults from './noResults'
import SpinnerCustom from '../SpinnerCustom'
import CustomInput from '../customInput'
import { BsSearch } from 'react-icons/bs'

const CustomTable = ({
  cols,
  children,
  noResults,
  page,
  totalPages,
  changePage,
  isLoading,
  handleSearch,
  handleClear,
  setValue,
  value,
}) => {
  const renderBody = () => {
    if (isLoading) {
      return (
        <tr>
          <td colSpan={cols.length} className="text-center p-5">
            <SpinnerCustom />
          </td>
        </tr>
      )
    } else if (noResults) {
      return <NoResults colCount={cols?.length} />
    } else {
      return children
    }
  }

  return (
    <div>
      {handleSearch && (
        <div className="d-flex justify-content-end m-3">
          <CustomInput
            handleSubmit={handleSearch}
            onChange={(e) => setValue(e.target.value)}
            inputValue={value}
            placeholder="Buscar producto por nombre"
            icon={<BsSearch color="white" />}
            type="search"
          />
          <button onClick={handleClear}>Limpiar</button>
        </div>
      )}
      <Table striped bordered hover>
        <thead>
          <tr>
            {cols.map((col) => (
              <th className="text-center" key={col}>
                {col}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>{renderBody()}</tbody>
      </Table>
      {page && (
        <IntranetPagination
          page={page}
          totalPages={totalPages}
          changePage={changePage}
        />
      )}
    </div>
  )
}

CustomTable.propTypes = {
  children: PropTypes.any,
  cols: PropTypes.array.isRequired,
  noResults: PropTypes.bool,
  page: PropTypes.number,
  totalPages: PropTypes.number,
  changePage: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  handleSearch: PropTypes.func,
  handleClear: PropTypes.func,
  setValue: PropTypes.func,
  value: PropTypes.string,
}

export default CustomTable
