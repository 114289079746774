import React, { useState, useEffect } from 'react'
import { Container, Row, Spinner } from 'react-bootstrap'
import CardBlog from '../../components/blog/cardBlog'
import { getPosts, getPostSortVisits } from '../../api/blog'
import './blog.scss'

const DatePublishPost = ({ post }) => {
  const date = new Date(post.publish_date)
  return (
    <Row key={'info-' + post.id}>
      <span>{`Publicado el ${date.toLocaleDateString('es-ES', {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
      })} a las ${post.publish_time} por ${post.author}`}</span>
    </Row>
  )
}

const PostList = () => {
  const [posts, setPosts] = useState([])
  const [loaded, setLoaded] = useState(false)
  const [postSort, setPostSort] = useState([])

  useEffect(() => {
    const getPostList = async () => {
      const rs = await getPosts()
      if (rs.status === 200) {
        setPosts(rs.data.results)
        setLoaded(true)
      }
    }
    const getPostSortByVisit = async () => {
      const rs = await getPostSortVisits()
      if (rs.status === 200) {
        setPostSort(rs.data.results)
        setLoaded(true)
      }
    }
    getPostList()
    getPostSortByVisit()
  }, [])
  if (loaded) {
    return (
      <div className="container p-rel margin-top-md margin-bottom-md">
        <div className="blogContainer">
          <div className="containerPostList">
            <div className="titleColumnPostTitle">Lo más nuevo</div>
            {posts.map((post, i) => {
              return (
                <React.Fragment key={post.id}>
                  <CardBlog
                    event={post}
                    key={i}
                    isContainer={true}
                    hasLine={posts.length - 1 != i}
                  />
                </React.Fragment>
              )
            })}
          </div>
          <div className="columnPostList">
            <div className="titleColumnPostTitle">Más visitados</div>
            {postSort.map((post, i) => {
              return (
                <CardBlog
                  event={post}
                  key={i}
                  isColumn={true}
                  hasLine={posts.length - 1 != i}
                />
              )
            })}
          </div>
        </div>
      </div>
    )
  } else {
    return (
      <Container style={{ height: '50vh' }}>
        <Spinner
          data-testid="spinner-grow"
          animation={'grow'}
          style={{ marginTop: '20vh', marginLeft: '40vw' }}
        />
      </Container>
    )
  }
}

export { DatePublishPost, PostList }
