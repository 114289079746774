import React from 'react'
import Button from 'react-bootstrap/Button'
//api
import { deleteClient } from '../../../api/clients'
//components
import ModalCustom from '../../modal/index'

const ModalDeleteClient = ({ id, isOpen, setIsOpen, getDataClients }) => {
  const handleClose = () => {
    setIsOpen(false)
  }

  const clientDelete = async () => {
    const { status } = await deleteClient(id)
    if (status === 204) {
      getDataClients()
      setIsOpen(false)
    }
  }

  const Buttonfooter = () => {
    return (
      <div className="d-flex justify-content-end">
        <Button variant="secondary" onClick={handleClose} className="mr-3">
          Cancelar
        </Button>
        <Button variant="secondary" onClick={clientDelete}>
          Aceptar
        </Button>
      </div>
    )
  }

  return (
    <>
      <ModalCustom
        show={isOpen}
        title={<h5 className="text-center">¿Está seguro de eliminar?</h5>}
      >
        <Buttonfooter />
      </ModalCustom>
    </>
  )
}

export default ModalDeleteClient
