import React, { useState, useEffect, useMemo } from 'react'
import { AiOutlineClose } from 'react-icons/ai'
import PropTypes from 'prop-types'

const AddList = ({ usersLibrary, setUsersLibrary }) => {
  const removeUser = (id) => {
    const updatedUsers = usersLibrary.filter(
      (userLibrary) => userLibrary.value !== id
    )
    setUsersLibrary(updatedUsers)
  }

  const mappedItems = useMemo(
    () =>
      usersLibrary?.map((item) => (
        <div key={item.value}>
          <div className="d-flex mt-3 ml-2 justify-content-between">
            <p>{item.label}</p>
            <div>
              <AiOutlineClose
                color="gray"
                onClick={() => removeUser(item.value)}
                className="cursor-pointer"
              />
            </div>
          </div>
        </div>
      )),
    [usersLibrary]
  )
  return (
    <>
      {usersLibrary?.length > 0 && (
        <div className="card p-3 height-card mt-2">
          <h6 className="m-2">Seleccionados:</h6>
          {mappedItems}
        </div>
      )}
    </>
  )
}

AddList.propTypes = {
  usersLibrary: PropTypes.array,
  setUsersLibrary: PropTypes.func,
}

export default AddList
