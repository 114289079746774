import React, { useState, useEffect, useMemo } from 'react'
import Button from 'react-bootstrap/Button'
import { FormGroup, Container } from 'react-bootstrap'
import { useForm, Controller } from 'react-hook-form'
import { toast } from 'react-toastify'
import PropTypes from 'prop-types'
//api
import { editItemConcourse } from '../../../api/concourse'
import { getListUsers } from '../../../api/users'
//components
import ModalCustom from '../../modal/index'
import CustomMultipleInput from '../../custommultipleInput/index'
import { Autocomplete } from '../../autocomplete/index'
import ButtonsFooter from '../../modal/buttonsFooter'
import AddList from '../addList'

const ModalEdit = ({
  openModal,
  setOpenModal,
  getConcourses,
  dataConcourse,
}) => {
  const [listUsers, setListUsers] = useState([])
  const [usersLibrary, setUsersLibrary] = useState([])
  const [validate, setValidate] = useState('')
  const [loading, setLoading] = useState(false)

  const {
    register,
    reset,
    formState: { errors },
    setValue,
    control,
    handleSubmit,
  } = useForm({})

  const getUsers = async () => {
    const { data, status } = await getListUsers()
    if (status === 200) {
      setListUsers(data.results)
    } else {
    }
  }

  useEffect(() => {
    if (openModal) {
      getUsers()
    }
  }, [openModal])

  useEffect(() => {
    const convertArray = dataConcourse?.usuarios_biblioteca?.map((item) => ({
      label: item.username,
      value: item.id,
    }))
    setUsersLibrary(convertArray)
  }, [dataConcourse])

  const optionsUsers = useMemo(
    () =>
      listUsers.map((listUser) => {
        return { label: listUser.username, value: listUser.id }
      }),
    [listUsers]
  )

  const resetData = {
    nombre: '',
    cliente: '',
    descuento: 0,
    usuario_especial: {},
    usuarios_biblioteca: [],
  }

  const handleClose = () => {
    setOpenModal(false)
    reset(resetData)
    setUsersLibrary([])
  }

  const onSubmit = async (values) => {
    setLoading(true)
    const formData = new FormData()
    formData.append('nombre', values.nombre)
    formData.append('cliente', values.cliente)
    formData.append('descuento', Number(values.descuento))
    formData.append('usuario_especial', values.usuario_especial.value)
    usersLibrary.forEach((item) =>
      formData.append('usuarios_biblioteca', item?.value)
    )

    try {
      const { status } = await editItemConcourse(dataConcourse.id, formData)

      if (status === 200) {
        getConcourses()
        reset(resetData)
        setUsersLibrary([])
        setOpenModal(false)
        toast('EL concurso fué actualizado con éxito.')
      } else {
        toast(`Error ${status}: algo ha ido mal, por favor vuelve a intentarlo`)
      }
    } catch (error) {
      console.log(error)
    }
    setLoading(false)
  }

  const handleValue = (value) => {
    const array = usersLibrary.some((item) => item.value === value.value)
    if (array) {
      setValidate('Este usuario ya está en la lista.')
      return
    }
    setUsersLibrary([...usersLibrary, value])
  }

  return (
    <Container>
      <ModalCustom
        show={openModal}
        title={<h3 className="text-center">Editar concurso</h3>}
        footer={
          <ButtonsFooter
            handleSuccess={handleSubmit(onSubmit)}
            textSuccess="Enviar"
            handleClose={handleClose}
            loading={loading}
          />
        }
        className="max-width-modal"
      >
        <CustomMultipleInput onSubmit={handleSubmit(onSubmit)}>
          <div className="d-flex  justify-content-center flex-wrap ml-5">
            <FormGroup className="d-flex flex-column width-40">
              <label>Nombre</label>
              <input
                type="text"
                className="input-round height-input"
                {...register('nombre', {
                  required: {
                    value: true,
                    message: 'Campo obligatorio',
                  },
                  pattern: {
                    value: /^[^\s].*/,
                    message: 'No puede empezar con espacio en blanco',
                  },
                })}
                defaultValue={dataConcourse.nombre}
              />
              {errors.nombre && (
                <small className="text-danger">{errors.nombre.message}</small>
              )}
            </FormGroup>
            <FormGroup className="d-flex flex-column width-40 margin-left-15">
              <label>Cliente</label>
              <input
                type="text"
                className="input-round height-input"
                defaultValue={dataConcourse?.cliente}
                {...register('cliente', {
                  required: {
                    value: true,
                    message: 'Campo obligatorio',
                  },
                  pattern: {
                    value: /^[^\s].*/,
                    message: 'No puede empezar con espacio en blanco',
                  },
                })}
              />
              {errors.cliente && (
                <small className="text-danger">{errors.cliente.message}</small>
              )}
            </FormGroup>
            <FormGroup className="margun-left-15 d-flex flex-column width-40">
              <label>Descuento</label>
              <input
                type="text"
                className="input-round height-input"
                defaultValue={dataConcourse.descuento}
                {...register('descuento', {
                  required: {
                    value: true,
                    message: 'Campo obligatorio',
                  },
                  pattern: {
                    value: /^[^\s].*/, // No debe empezar con espacio en blanco
                    message: 'No puede empezar con espacio en blanco',
                  },
                })}
              />
              {errors.descuento && (
                <small className="text-danger">
                  {errors.descuento.message}
                </small>
              )}
            </FormGroup>
            <FormGroup className="ml-3 width-40 " controlId="usuario_especial">
              <Controller
                control={control}
                name="usuario_especial"
                defaultValue={{
                  label: dataConcourse.usuario_especial?.username,
                  value: dataConcourse.usuario_especial?.id,
                }}
                rules={{
                  required: 'Campo obligatorio',
                }}
                render={({ field: { onChange, value, name } }) => {
                  return (
                    <div>
                      <Autocomplete
                        id="usuario_especial"
                        label="Usuario especial"
                        inputValue={value}
                        required
                        asyncFetch={optionsUsers}
                        onSelect={(value) => {
                          setValue('usuario_especial', value.value)
                          onChange(value)
                        }}
                        placeholder={'Seleccionar'}
                      />
                    </div>
                  )
                }}
              />
              {errors.usuario_especial && (
                <small className="text-danger">
                  {errors.usuario_especial.message}
                </small>
              )}
            </FormGroup>

            <FormGroup
              className=" mt-2 width-40 "
              controlId="usuarios_biblioteca"
            >
              <div>
                <Controller
                  control={control}
                  name="usuarios_biblioteca"
                  render={({ field: { onChange, value, name } }) => {
                    return (
                      <div>
                        <Autocomplete
                          id="usuarios_biblioteca"
                          isDisabled={false}
                          label="Usuarios biblioteca"
                          required
                          asyncFetch={optionsUsers}
                          onSelect={(value) => {
                            handleValue(value)
                            onChange(value)
                          }}
                          placeholder={'Seleccionar'}
                        />
                      </div>
                    )
                  }}
                />
                {errors.usuarios_biblioteca && (
                  <small className="text-danger">
                    {errors.usuarios_biblioteca.message}
                  </small>
                )}
                {validate && (
                  <p className="text-danger">
                    <small>{validate}</small>
                  </p>
                )}
                <AddList
                  usersLibrary={usersLibrary}
                  setUsersLibrary={setUsersLibrary}
                />
              </div>
            </FormGroup>
          </div>
        </CustomMultipleInput>
      </ModalCustom>
    </Container>
  )
}

ModalEdit.propTypes = {
  openModal: PropTypes.bool,
  setOpenModal: PropTypes.func,
  getConcourses: PropTypes.func,
  dataConcourse: PropTypes.object,
}

export default ModalEdit
