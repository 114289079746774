import React from 'react'
import PropTypes from 'prop-types'

const NoResults = ({ colCount }) => {
  return (
    <tr className="w-100 text-center">
      <td colSpan={colCount} className="no-results-td">
        No hay resultados
      </td>
    </tr>
  )
}

NoResults.propTypes = {
  colCount: PropTypes.number,
}

export default NoResults
