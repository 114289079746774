import React from 'react';
import { Container } from 'react-bootstrap';

const Error404 = () => {
  return (
    <Container className="container-error">
      <h1>404</h1>
      <h2 className="text-secondary-c">Página no encontrada</h2>
    </Container>
  )
}

export default Error404