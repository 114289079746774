import React, { useState } from 'react'
import { Row, Col, Container, Button } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import Form from 'react-bootstrap/Form'
import { companySelector } from '../../slices/company.js'
import { InputCustom } from '../../components/input/index'
import { sendClaim } from '../../api/orders'
import {
  emailRegex,
  latinCharsRegex,
  phoneRegex,
  orderRegex,
} from '../../constans'
import './legals.scss'

const Legals = () => {
  const [nameClaim, setNameClaim] = useState('')
  const [emailClaim, setEmailClaim] = useState('')
  const [phoneClaim, setPhoneClaim] = useState('')
  const [orderClaim, setOrderClaim] = useState('')
  const [nameInvalid, setNameInvalid] = useState(false)
  const [emailInvalid, setEmailInvalid] = useState(false)
  const [phoneInvalid, setPhoneInvalid] = useState(false)
  const [orderInvalid, setOrderInvalid] = useState(false)
  const [sendSuccess, setSendSuccess] = useState(false)
  const [error, setError] = useState('')
  const {
    name,
    direccion_completa,
    telefono,
    email,
    data,
    claimsText,
  } = useSelector(companySelector)
  let url = window.location.host

  const verifyForms = (event) => {
    let fieldsOk = 0
    if (event.keyCode === 13 || event.type === 'click') {
      const checkName = latinCharsRegex.test(nameClaim)
      if (!checkName) {
        setNameInvalid(true)
      } else {
        setNameInvalid(false)
        fieldsOk += 1
      }
      const checkEmail = emailRegex.test(emailClaim)
      if (!checkEmail) {
        setEmailInvalid(true)
      } else {
        setEmailInvalid(false)
        fieldsOk += 1
      }
      const checkPhone = phoneRegex.test(phoneClaim)
      if (!checkPhone) {
        setPhoneInvalid(true)
      } else {
        setPhoneInvalid(false)
        fieldsOk += 1
      }
      const checkOrder = orderRegex.test(orderClaim)
      if (!checkOrder) {
        setOrderInvalid(true)
      } else {
        setOrderInvalid(false)
        fieldsOk += 1
      }
      if (fieldsOk === 4) {
        onSendClaim(event)
      }
    }
  }

  const onSendClaim = async (event) => {
    const form = event.target
    if (form.checkValidity() === false) {
      event.preventDefault()
      event.stopPropagation()
    }
    const payload = {
      name: nameClaim,
      email: emailClaim,
      phone: phoneClaim,
      order: orderClaim,
    }
    const { status, data } = await sendClaim(payload)
    console.log(status)
    if (status === 201) {
      setSendSuccess(true)
      setError('')
    } else if (status === 400) {
      setError(
        'El número de pedido es incorrecto o ya cuenta con un reclamo registrado.'
      )
      setSendSuccess(false)
    } else {
      setError('Error, por favor intente nuevamente')
    }
  }

  return (
    <Container>
      <Row>
        <Col className="title" md={12}>
          <h2>Aviso Legal</h2>
          <span className="divider-title"></span>
          <div className="legal">
            <p>
              En cumplimiento de la Ley 34/2002, de 11 de julio, de servicios de
              la sociedad de la información y de comercio electrónico, {name} le
              facilita los siguientes datos que permiten su identificación como
              titular del sitio {url}:
            </p>
            <Col className="companyInfo" lg={8}>
              <div>
                <p>
                  <strong>Nombre:</strong>&nbsp; {name}
                </p>
                <p>
                  <strong>CIF:</strong>&nbsp; {data.cif}
                </p>
                <p>
                  <strong>Tel:</strong>&nbsp; {telefono}
                </p>
                <p>
                  <strong>Dir:</strong>&nbsp; {direccion_completa}
                </p>
                <p>
                  <strong>Mail:</strong>&nbsp; {email}
                </p>
              </div>
            </Col>
            <p>
              {name} no puede asumir ninguna responsabilidad derivada del uso
              incorrecto, inapropiado o ilícito de la información aparecida en
              las páginas de Internet de {url}.
            </p>
            <p>
              {name} pone todos los medios razonables a su disposición para
              intentar que la información mostrada en su página de internet sea
              veraz y sin errores tipográficos. Si se produjese algún error de
              este tipo, ajeno en todo momento a la voluntad de {name}, se
              corregiría tan pronto como se detecte. Si el error se produce en
              algunos de los precios mostrados y algún cliente hubiese realizado
              una compra, le comunicaremos dicho error y el cliente o usuario
              tendrá derecho a anular su pedido sin coste por su parte. El envío
              de una confirmación automática de compra no valida las condiciones
              de un precio erróneo.
            </p>
            <p>
              {name} no se puede hacer responsable ni directa ni indirectamente
              de ninguna de las informaciones y descripciones de los productos
              ofrecidos en la web, ya que ésta es extraída directamente de las
              informaciones que los editores, fabricantes y distribuidores
              facilitan a {name} y en algunos casos, en sus propias webs. Esta
              responsabilidad recae en todo momento en los fabricantes o
              proveedores de dichos productos. Aun así, en el caso de que la
              información sobre el producto no corresponda con las
              características reales, el cliente podrá anular el pedido sin
              coste alguno por su parte.
            </p>
            <p>
              Las páginas de Internet de {url} pueden contener enlaces (links) a
              otras páginas de terceras partes que {name} no puede controlar.
              Por lo tanto, {name} no puede asumir responsabilidades por el
              contenido que pueda aparecer en páginas de terceros.
            </p>
            <p>
              Todos los textos son propiedad de sus autores y están protegidos
              por la Ley de Propiedad Intelectual. Cualquier acto de
              transmisión, distribución, cesión, reproducción, almacenamiento o
              comunicación pública total o parcial, debe contar con el
              consentimiento expreso de {name}. Todas las marcas y fotografías
              de productos publicadas en {url} son propiedad de sus respectivos
              propietarios.
            </p>
          </div>
          <h2>LEGISLACIÓN APLICABLE</h2>
          <span className="divider-title"></span>
          <div className="legal">
            <p>
              Las transacciones comerciales realizadas con {url} están sometidas
              a la legislación española.
            </p>
            <p>Leyes de aplicación a la materia:</p>
            <ul>
              <li>
                Ley 7/2017, de 2 de noviembre, por la que se incorpora al
                ordenamiento jurídico español la Directiva 2013/11/UE, del
                Parlamento Europeo y del Consejo, de 21 de mayo de 2013,
                relativa a la resolución alternativa de litigios en materia de
                consumo.
                https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home2.show&lng=ES
              </li>
              <li>
                Ley 3/2014, de 27 de marzo, por la que se modifica el texto
                refundido de la Ley General para la Defensa de los Consumidores
                y Usuarios y otras leyes complementarias, aprobado por el Real
                Decreto Legislativo 1/2007, de 16 de noviembre.
              </li>
              <li>
                Reglamento (UE) nº 524/2013 del Parlamento y del Consejo, de 21
                de mayo de 2013
              </li>
              <li>
                Real Decreto Legislativo 1/2007, de 16 de noviembre, por el que
                se aprueba el texto refundido de la Ley General para la Defensa
                de los Consumidores y Usuarios y otras leyes complementarias
              </li>
              <li>
                Ley 34/2002, de 11 de Julio, de Servicios de la Sociedad de la
                Información y del Comercio Electrónico y Ley 32/2003, de 3 de
                noviembre, General de Telecomunicaciones.
              </li>
              <li>
                Ley 7/96, de 15 de Enero, de Ordenación del Comercio minorista
                (L.O. 2/1996, de 15 de Enero, Complementaria de la Ordenación
                del Comercio Minorista, Ley 47/2002, del 19 de Diciembre, de
                reforma de la Ley 7/1996 y Ley 1/2010, de 1 de Marzo de reforma
                de la Ley 7/1996)
              </li>
              <li>
                Ley 7/1998 sobre Condiciones Generales de la contratación.
                Cuantas disposiciones legales resulten de aplicación.
              </li>
            </ul>
          </div>
          <h2>RECLAMACIONES</h2>
          <span className="divider-title"></span>
          <div
            className="legal"
            dangerouslySetInnerHTML={{ __html: claimsText }}
          ></div>
          <h2 className="mt-4">Formulario de Reclamación y Devolución</h2>
          <span className="divider-title"></span>
        </Col>
      </Row>
      <Row>
        <div className="claims-form" onKeyDown={verifyForms}>
          <Form onSubmit={verifyForms}>
            <InputCustom
              value={nameClaim}
              onChange={(e) => setNameClaim(e.target.value)}
              label="Nombres y Apellidos"
              placeholder={'Ingrese sus nombres y apellidos'}
              isInvalid={nameInvalid}
              error="Error al ingresar el nombre."
            />
            <InputCustom
              value={emailClaim}
              onChange={(e) => setEmailClaim(e.target.value)}
              label="Email"
              placeholder={'Ingrese su email'}
              isInvalid={emailInvalid}
              error="Ingrese un email válido."
            />
            <InputCustom
              value={phoneClaim}
              onChange={(e) => setPhoneClaim(e.target.value)}
              label="Teléfono"
              placeholder={'Ingrese su teléfono'}
              isInvalid={phoneInvalid}
              error="Ingrese un teléfono válido."
            />
            <InputCustom
              value={orderClaim}
              onChange={(e) => setOrderClaim(e.target.value)}
              label="Nro de Pedido"
              placeholder={'Ingrese su nro de pedido'}
              isInvalid={orderInvalid}
              error="Número de orden incorrecto."
            />
            {sendSuccess ? (
              <Col className="alert-success" lg={12}>
                <span>
                  Su reclamo ha sido registrado con éxito, a la brevedad será
                  contactado por nuestro personal.
                </span>
              </Col>
            ) : null}
            {error !== '' ? (
              <Col className="alert-danger" lg={12}>
                <span>{error}</span>
              </Col>
            ) : null}
            <Button className="my-3" variant="primary" onClick={verifyForms}>
              Enviar
            </Button>
          </Form>
        </div>

        <div>
          <h1></h1>
        </div>
      </Row>
    </Container>
  )
}

export default Legals
