import PropTypes from 'prop-types'
import React from 'react'
import { Button } from 'react-bootstrap'

const ButtonCustom = ({ onClick, children, disabled, type, ...props }) => {
  const getClassType = () => {
    if (type === 'primary') {
      return 'btn-primary'
    } else if (type === 'secondary') {
      return 'btn-secondary'
    } else if (type === 'danger') {
      return 'btn-danger'
    } else if (type === 'cancel') {
      return 'btn-cancel'
    }
    return 'btn'
  }

  return (
    <Button
      variant="secondary"
      onClick={onClick}
      disabled={disabled}
      className={'mr-3 ' + getClassType()}
      {...props}
    >
      {children}
    </Button>
  )
}

ButtonCustom.defaultProps = {
  type: 'primary',
}

ButtonCustom.propTypes = {
  children: PropTypes.any,
  onClick: PropTypes.func,
  type: PropTypes.string,
}

export default ButtonCustom
