import { methodGet, methodPost } from './methods'
import { API_INFO } from '../constans'

export async function getDetailOrder(id) {
  const url = API_INFO + 'storehouse/orders/' + id + '/'
  return methodGet(url)
}

export async function sendClaim(payload) {
  const url = API_INFO + 'storehouse/claims/'
  return methodPost(url, payload)
}

export const getOrdersApi = async (payload) => {
  const url = API_INFO + `storehouse/orders/`
  return methodGet(url, payload)
}

export const getOrdersFiltersApi = async () => {
  const url = API_INFO + `storehouse/orders/filters/`
  return methodGet(url, {})
}
