import React, { useCallback, useEffect, useState } from 'react'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import { TiDelete, TiPlus, TiMinus } from 'react-icons/ti'
import Popover from 'react-bootstrap/Popover'
import { useDispatch, useSelector } from 'react-redux'
import { AsyncTypeahead } from 'react-bootstrap-typeahead'
import PropTypes from 'prop-types'
//api
import {
  addItemCart,
  decreaseQuantityItemCart,
  removeItemCart,
} from '../../api/ecommerce'
import { getListProducts } from '../../api/products'
//slices
import {
  addItem,
  decreaseQuantity,
  increaseQuantity,
  removeItem,
} from '../../slices/cart'
import { userSelector } from '../../slices/user'
//styles
import './index.scss'

const DetailProduct = ({ item }) => {
  const dispatch = useDispatch()
  const { isLogged } = useSelector(userSelector)
  const [productByEanOptions, setProductByEanOptions] = useState([])
  const [isLoadingProductEan, setIsLoadingProductEan] = useState(false)
  const [productByNameOptions, setProductByNameOptions] = useState([])
  const [isLoadingProductName, setIsLoadingProductName] = useState(false)
  const [product, setProduct] = useState(item)
  const [isDisabledEan, setIsDisabledEan] = useState(false)
  const [isDisabledName, setIsDisabledName] = useState(false)

  const popoverAdd = <Popover className="p-3">Se añadio un producto</Popover>
  const popoverRemove = <Popover className="p-3">Se quito un producto</Popover>
  const popoverDelete = (
    <Popover className="p-3">Se elimino un producto</Popover>
  )

  const handleProducto = (selected) => {
    if (selected.length === 0) {
      setProduct('')
    } else {
      setProduct(selected[0])
      addProductToCart(selected[0])
    }
  }

  const searchProductById = async (query) => {
    setIsLoadingProductEan(true)
    const { status, data } = await getListProducts(`ean=${query}`)
    if (status === 200) {
      setProductByEanOptions(data.results)
      setIsLoadingProductEan(false)
      setIsDisabledName(true)
    } else {
      console.log('error al buscar por ean o id')
    }
  }

  const searchProductByName = async (query) => {
    setIsLoadingProductName(true)
    const { status, data } = await getListProducts(`titulo=${query}`)
    if (status === 200) {
      setProductByNameOptions(data.results)
      setIsLoadingProductName(false)
      setIsDisabledEan(true)
    } else {
      console.log('error al buscar por titulo')
    }
  }

  const addProductToCart = async (data) => {
    if (isLogged) {
      const payload = { item: data.id, quantity: 1 }
      const { status, dataResponse } = await addItemCart(payload)
      if (status === 201) {
        dispatch(addItem(data))
      } else {
        console.log('error')
      }
    } else {
      dispatch(addItem(data))
    }
  }

  // const increaseQuantityItem = async (id) => {
  //   if (isLogged) {
  //     const payload = { item: id, quantity: 1 }
  //     const { status } = await addItemCart(payload)
  //     if (status === 201) {
  //       dispatch(increaseQuantity(id))
  //     } else {
  //       console.log('error')
  //     }
  //   } else {
  //     dispatch(increaseQuantity(id))
  //   }
  // }
  const increaseQuantityItem = useCallback(
    async (id) => {
      if (isLogged) {
        const payload = { item: id, quantity: 1 }
        const { status } = await addItemCart(payload)
        if (status === 201) {
          dispatch(increaseQuantity(id))
        } else {
          console.log('error al incrementar en 1 al item')
        }
      } else {
        dispatch(increaseQuantity(id))
      }
    },
    [dispatch, isLogged]
  )

  // const decreaseQuantityItem = async (id) => {
  //   if (isLogged) {
  //     const payload = { item: id, quantity: 1 }
  //     const { status } = await decreaseQuantityItemCart(payload)
  //     if (status === 200) {
  //       dispatch(decreaseQuantity(id))
  //     } else {
  //       console.log('error')
  //     }
  //   } else {
  //     dispatch(decreaseQuantity(id))
  //   }
  // }
  const decreaseQuantityItem = useCallback(
    async (id) => {
      if (isLogged) {
        const payload = { item: id, quantity: 1 }
        const { status } = await decreaseQuantityItemCart(payload)
        if (status === 200) {
          dispatch(decreaseQuantity(id))
        } else {
          console.log('error al remover 1 item')
        }
      } else {
        dispatch(decreaseQuantity(id))
      }
    },
    [dispatch, isLogged]
  )

  // const removeProductCart = async (item) => {
  //   if (isLogged) {
  //     const payload = { item: item.id }
  //     const { status } = await removeItemCart(payload)
  //     if (status === 200) {
  //       dispatch(removeItem(item))
  //     } else {
  //       console.log('error')
  //     }
  //   } else {
  //     dispatch(removeItem(item))
  //   }
  // }
  const removeProductCart = useCallback(
    async (item) => {
      if (isLogged) {
        const payload = { item: item.id }
        const { status } = await removeItemCart(payload)
        if (status === 200) {
          dispatch(removeItem(item))
        } else {
          console.log('error al borrar el item')
        }
      } else {
        dispatch(removeItem(item))
      }
    },
    [dispatch, isLogged]
  )

  return (
    <tr key={product}>
      <td className="text-center">
        <AsyncTypeahead
          inputProps={{ className: 'input-field' }}
          id="idEan"
          isLoading={isLoadingProductEan}
          labelKey="ean"
          onSearch={searchProductById}
          // onSelect={handleProducto}
          onChange={handleProducto}
          defaultInputValue={item.ean}
          options={productByEanOptions}
          placeHolder={'Buscar por ID o EAN'}
          label="ean"
          minLength={3}
          disabled={isDisabledEan}
          // selected={product.titulo}
        />
      </td>
      <td className="text-center">
        <AsyncTypeahead
          // selected={product.titulo}
          inputProps={{ className: 'input-field' }}
          id="idName"
          isLoading={isLoadingProductName}
          labelKey="titulo"
          onSearch={searchProductByName}
          // onSelect={handleProducto}
          onChange={handleProducto}
          defaultInputValue={product.titulo}
          options={productByNameOptions}
          placeHolder={'Buscar por nombre'}
          label="titulo"
          minLength={3}
          disabled={isDisabledName}
        />
      </td>
      <td className="text-center">{item.type}</td>
      <td className="text-center">{item.quantity}</td>
      <td className="text-center">
        <OverlayTrigger
          trigger="click"
          placement="top"
          overlay={popoverAdd}
          rootClose={true}
        >
          <TiPlus
            size="1.2rem"
            className="mr-1"
            onClick={() => increaseQuantityItem(item.id)}
          />
        </OverlayTrigger>

        <OverlayTrigger
          trigger="click"
          placement="top"
          overlay={popoverRemove}
          rootClose={true}
        >
          <TiMinus
            size="1.2rem"
            className="mr-1"
            onClick={() => decreaseQuantityItem(item.id)}
          />
        </OverlayTrigger>

        <OverlayTrigger
          trigger="click"
          placement="top"
          overlay={popoverDelete}
          rootClose={true}
        >
          <TiDelete size="1.2rem" onClick={() => removeProductCart(item)} />
        </OverlayTrigger>
      </td>
      <td className="text-center">{item.precio_venta_publico}</td>
      <td className="text-center">
        {(item.precio_venta_publico * item.quantity).toFixed(2)}
      </td>
    </tr>
  )
}

DetailProduct.propTypes = {
  item: PropTypes.array,
}

export default DetailProduct
