import React, { useState, useEffect } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import {
  AiOutlineFacebook,
  AiOutlineInstagram,
  AiFillPhone,
  AiOutlineTwitter,
  AiOutlineMail,
} from 'react-icons/ai'
import { FaRegAddressBook } from 'react-icons/fa'
import { BsCalendar } from 'react-icons/bs'
import { FiCoffee } from 'react-icons/fi'

import { companySelector } from '../../slices/company'
import { useSelector } from 'react-redux'

import MapMaker from '../../components/map/Map'
import './nosotros.scss'
import Head from '../../components/metatags_helmet/metatags'

const CompanyDescription = (props) => {
  let description = ''
  if (props.description) {
    description = props.description
  }
  const lines = description.split(/\r?\n/).filter((item) => item.length)

  return (
    <div>
      {lines.map((line, index) => {
        if (index === 0)
          return (
            <p className="mt-4 text-wrap" key={index}>
              {line}
            </p>
          )
        else return <p key={index}>{line}</p>
      })}
    </div>
  )
}

const Nosotros = () => {
  const title = 'Nosotros'

  const {
    name,
    descripcion_pub,
    direccion_completa,
    telefono,
    email,
    redes_sociales,
    latitude,
    longitude,
    localidad,
    pais,
    business_hours,
    cafeteria_hours,
    data,
  } = useSelector(companySelector)

  return (
    <Container>
      <Head description={descripcion_pub} title={title} noIndex={true} />
      <Row xs={2} lg={12} className="mainrow-about">
        <Col lg={6} xs={12}>
          <div>
            <h2>{name}</h2>
            <hr />
          </div>
          <div>
            <CompanyDescription description={descripcion_pub} />
          </div>
          <div>
            {redes_sociales && (
              <>
                <p className="mt-4">Siguenos en nuestras redes sociales:</p>
                <div>
                  {redes_sociales.facebook && (
                    <a
                      href={redes_sociales.facebook}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <AiOutlineFacebook
                        color="#3b5998"
                        size="2em"
                        className="icons-size icon-lg mr-3"
                      />
                    </a>
                  )}
                  {redes_sociales.instagram && (
                    <a
                      href={redes_sociales.instagram}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <AiOutlineInstagram
                        color="#DD2A7B"
                        size="2em"
                        className="icons-size icon-lg mr-3"
                      />
                    </a>
                  )}
                  {redes_sociales.twitter && (
                    <a
                      href={redes_sociales.twitter}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <AiOutlineTwitter
                        color="#1e8dee"
                        size="2em"
                        className="icons-size  icon-lg"
                      />
                    </a>
                  )}
                </div>
              </>
            )}
          </div>
        </Col>
        <Col>
          <Row>
            <div className="address-info">
              <div className="d-flex align-items-center mb-1">
                <AiFillPhone className="mb-2 mr-2" role="none" />
                <span className="ml-2">
                  <a href={`tel:${telefono.replace(/\s+/g, '')}`}>{telefono}</a>
                </span>
              </div>
              <div className="d-flex align-items-center mb-1">
                <AiOutlineMail className="mb-3 mr-2" role="none" />
                <a href={`mailto:${email}`}>
                  <p className="contactDetails">{email}</p>
                </a>
              </div>
              <div className="d-flex align-items-center mb-3">
                <FaRegAddressBook className="mb-1 mr-2" />
                <a
                  href={`https://maps.google.com/?q=${latitude},${longitude}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span className="ml-2">
                    {direccion_completa && pais
                      ? direccion_completa
                      : 'Dirección empresa'}
                  </span>
                </a>
              </div>
              <div className="d-flex align-items-center">
                {business_hours && (
                  <>
                    <BsCalendar className="mr-2 mb-3" />
                    <span className="ml-2">Librería de {business_hours}</span>
                  </>
                  // </div>
                )}
                {cafeteria_hours && (
                  // <div className="d-flex align-items-center">
                  <>
                    <FiCoffee />
                    <span className="ml-2">Café de {cafeteria_hours}</span>
                  </>
                )}
              </div>
              {data.sub_cat && (
                <div className="mt-4 text-center">
                  <img
                    alt="Subvencionado"
                    style={{
                      width: '100%',
                      maxWidth: '160px',
                      height: '55px',
                    }}
                    src={data.sub_cat.logo}
                  />
                  <p
                    className="mt-3"
                    style={{
                      fontSize: '13px',
                      fontWeight: 'bold',
                      color: '#5195de',
                    }}
                  >
                    {data.sub_cat.text}
                  </p>
                </div>
              )}
            </div>
          </Row>
          <Row>
            <div
              style={{ height: 350, marginTop: '0 auto', marginBottom: '10px' }}
            >
              <MapMaker lat={latitude} lng={longitude} />
            </div>
          </Row>
        </Col>
      </Row>
    </Container>
  )
}

export default Nosotros
