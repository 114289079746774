import React from 'react'

const Intranet = (props) => {
  return (
    <>
      <h1>hola intranet</h1>
    </>
  )
}

export default Intranet
