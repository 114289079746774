import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Form } from 'react-bootstrap'
import { SiMicrosoftexcel } from 'react-icons/si'
import { MdCloudUpload } from 'react-icons/md'
import { RiDeleteBin5Fill } from 'react-icons/ri'

const FileInput = ({ file, setFile, typeFiles, error, preview, ...props }) => {
  const [fileName, setFileName] = useState()
  const [imageReader, setImageReader] = useState(null)

  useEffect(() => {
    if (file === null) {
      setFileName()
    } else if (typeFiles.includes('image')) {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onloadend = () => {
        setImageReader(reader.result)
      }
    }
  }, [file])

  useEffect(() => {
    if (preview) {
      setImageReader(preview)
    }
  }, [preview])

  const getFileSelected = () => {
    if (typeFiles.includes('image')) {
      return <img src={imageReader} width={150} height={150} alt={fileName} />
    } else if (typeFiles.includes('sheet')) {
      return <SiMicrosoftexcel size={80} color="#21A366" />
    }
  }

  return (
    <div
      className="upload-container"
      onClick={() => document.querySelector('.input-field').click()}
    >
      <div>
        <input
          type="file"
          accept={typeFiles}
          className="input-field"
          hidden
          onChange={({ target: { files } }) => {
            if (files.length > 0) {
              files[0] && setFileName(files[0].name)
              if (files) {
                setFile(files[0])
              }
            }
          }}
        />

        {file || preview ? (
          getFileSelected()
        ) : (
          <>
            <MdCloudUpload size={60} />
            <p>Examinar archivos para cargar</p>
          </>
        )}
      </div>

      {fileName && (
        <section className="uploaded-row">
          <div className="upload-content">
            <div>{fileName} </div>
            <RiDeleteBin5Fill
              color="red"
              size={30}
              onClick={(e) => {
                e.preventDefault()
                setFileName(null)
                setFile(null)
              }}
            />
          </div>
        </section>
      )}
      {error && (
        <Form.Control.Feedback className="d-block" type="invalid">
          {error}
        </Form.Control.Feedback>
      )}
    </div>
  )
}

FileInput.defaultProps = {
  typeFiles: 'image/*',
}

FileInput.propTypes = {
  preview: PropTypes.string,
  file: PropTypes.object,
  setFile: PropTypes.func.isRequired,
  typeFiles: PropTypes.string,
  error: PropTypes.string,
}

export default FileInput
