import React, { useState } from 'react'
import { Button, Container } from 'react-bootstrap'
import { toast } from 'react-toastify'
import PropTypes from 'prop-types'
//api
import { importDilve } from '../../api/products'
//components
import ModalCustom from '../modal/index'
import CustomInput from '../customInput/index'
import ButtonsFooter from '../modal/buttonsFooter'

const ModalDilve = ({ showModal, setShowModal }) => {
  const [eanProduct, setEanProduct] = useState('')
  const [validateErrors, setValidateErrors] = useState({
    required: '',
    spaces: '',
  })
  const [loading, setLoading] = useState(false)

  const importBookDilve = async () => {
    setLoading(true)
    const data = {
      ean: eanProduct,
    }
    setValidateErrors({
      required: '',
      spaces: '',
    })
    if (eanProduct !== '') {
      try {
        const { status } = await importDilve(data)
        if (status === 200) {
          setShowModal(false)
          setEanProduct('')
          toast('Importar Dilve guardado con éxito')
        } else {
          toast(
            `Error ${status}: Algo ha ido mal, por favor vuelve a intentarlo`
          )
        }
      } catch (e) {}
    } else {
      if (eanProduct === '') {
        setValidateErrors((prev) => ({ ...prev, required: 'Campo requerido' }))
        return
      }
      if (eanProduct.trimLeft() !== eanProduct) {
        setValidateErrors((prev) => ({
          ...prev,
          spaces: 'No se permiten espacios al principio',
        }))
      }
    }
    setLoading(false)
  }

  const handleClose = () => {
    setShowModal(false)
    setEanProduct('')
  }
  const handleValue = (e) => {
    setEanProduct(e.target.value)
  }

  return (
    <Container>
      <ModalCustom
        show={showModal}
        title={<p className="text-center">Importar Dilve</p>}
        footer={
          <ButtonsFooter
            handleClose={handleClose}
            handleSuccess={importBookDilve}
            loading={loading}
          />
        }
      >
        <div className="margin-bottom-30">
          <CustomInput
            onChange={handleValue}
            inputValue={eanProduct}
            type={'text'}
            label={'Isbn'}
          />
          {validateErrors.required && (
            <p className="text-danger m-3">
              <small>{validateErrors.required}</small>
            </p>
          )}
          {validateErrors.spaces && (
            <p className="text-danger m-3">
              <small>{validateErrors.spaces}</small>
            </p>
          )}
        </div>
      </ModalCustom>
    </Container>
  )
}

ModalDilve.propTypes = {
  showModal: PropTypes.bool,
  setShowModal: PropTypes.func,
}

export default ModalDilve
