import React, { useEffect, useState } from 'react'
import { Button, Col, Container, Form, Row, Toast } from 'react-bootstrap'
import { AsyncTypeahead } from 'react-bootstrap-typeahead'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import './index.scss'

//api
import { getLibraries } from '../../api/products'
import { getListClients } from '../../api/clients'
import { getVisits, saveBudget } from '../../api/ecommerce'

//slices
import { cartSelector } from '../../slices/cart'

//componentes
import CustomTable from '../../components/table'
import DetailProduct from '../../components/detailProduct'

const Budget = ({
  increaseQuantityItem,
  decreaseQuantityItem,
  removeProductCart,
}) => {
  const navigate = useNavigate()
  const { items } = useSelector(cartSelector)
  const [descuento, setDescuento] = useState(0)
  const [observaciones, setObservaciones] = useState('')
  const [nombre, setNombre] = useState('')
  const [budgetQuery, setBudgetQuery] = useState({})
  const [budgetId, setBudgetId] = useState('')
  const [isBudgetSaved, setIsBudgetSaved] = useState(true)
  const [showToast, setShowToast] = useState(false)
  const [itemsTable, setItemsTable] = useState(items)
  const [clientesOptions, setClientesOptions] = useState([])
  const [isLoadingClientes, setIsLoadingClientes] = useState(false)
  const [cliente, setCliente] = useState({})
  const [visitaOptions, setVisitaOptions] = useState([])
  const [isLoadingVisita, setIsLoadingVisita] = useState(false)
  const [visita, setVisita] = useState({})
  const [bibliotecaOptions, setBibliotecaOptions] = useState([])
  const [isLoadingBiblioteca, setIsLoadingBiblioteca] = useState(false)
  const [biblioteca, setBiblioteca] = useState({})
  const [validated, setValidated] = useState(false)

  useEffect(() => {
    const detalles = items.map((item) => ({
      producto: item.id,
      quantity: item.quantity,
    }))
    setBudgetQuery({ ...budgetQuery, details: detalles })
  }, [items])

  useEffect(() => {
    setItemsTable(items)
  }, [items])

  const toggleToast = () => {
    setShowToast(!showToast)
  }

  const addProductLine = () => {
    const newItems = [
      ...itemsTable,
      {
        id: 0,
        titulo: '',
        quantity: 1,
        precio_venta_publico: 0,
      },
    ]
    setItemsTable(newItems)
  }

  const saveBudgetFunc = async () => {
    setBudgetQuery({ ...budgetQuery, precioTotal: totalPrice })
    const { status, data } = await saveBudget(budgetQuery)
    if (status === 201) {
      setIsBudgetSaved(false)
      setBudgetId(data.id)
      toggleToast()
      setTimeout(() => {
        navigate('/presupuestos')
      }, 3000)
    } else {
      console.log('error!')
    }
  }

  const handleNombre = (e) => {
    setNombre(e.target.value)
    setBudgetQuery({ ...budgetQuery, nombre: e.target.value })
  }

  const handleDescuento = (e) => {
    setDescuento(parseFloat(e.target.value))
    setBudgetQuery({ ...budgetQuery, descuento: e.target.value + '%' })
  }

  const handleObs = (e) => {
    setObservaciones(e.target.value)
    setBudgetQuery({ ...budgetQuery, observaciones: e.target.value })
  }

  const getClientsFunc = async (query) => {
    setIsLoadingClientes(true)
    const { status, data } = await getListClients(`nombre_fiscal=${query}`)
    if (status === 200) {
      setClientesOptions(data.results)
      setIsLoadingClientes(false)
    }
  }
  const handleCliente = (selected) => {
    setCliente(selected[0])
    setBudgetQuery({ ...budgetQuery, cliente: selected[0].id })
  }

  const getVisitaFunc = async (query) => {
    setIsLoadingVisita(true)
    const { status, data } = await getVisits(`${query}`)
    if (status === 200) {
      setVisitaOptions(data.results)
      setIsLoadingVisita(false)
    } else {
      setBudgetQuery({ ...budgetQuery, visita: '' })
    }
  }

  const handleVisita = (selected) => {
    setVisita(selected[0])
  }

  const getBibliotecaFunc = async (query) => {
    setIsLoadingBiblioteca(true)
    const { status, data } = await getLibraries(`denominacion=${query}`)
    if (status === 200) {
      setBibliotecaOptions(data.results)
      setIsLoadingBiblioteca(false)
    }
  }
  const handleBiblioteca = (selected) => {
    setBiblioteca(selected[0])
    setBudgetQuery({ ...budgetQuery, biblioteca: selected[0].id })
  }

  let totalItems = 0
  for (let i = 0; i < items.length; i++) {
    totalItems += items[i].quantity
  }

  let totalPrice = 0
  for (let i = 0; i < items.length; i++) {
    totalPrice +=
      items[i].quantity * parseFloat(items[i].precio_venta_publico).toFixed(2)
  }

  const filterBy = () => true

  const handleSubmit = async (event) => {
    const form = event.currentTarget
    event.preventDefault()
    event.stopPropagation()
    if (form.checkValidity() === true) {
      setValidated(false)
    } else {
      setValidated(true)
    }
  }

  const colsTable = [
    'EAN o ID',
    'Nombre',
    'Tipo',
    'Cantidad',
    'Acciones',
    'P. Normal',
    'Total',
  ]

  return (
    <Container>
      <h1>Crear presupuesto</h1>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Row>
          <Col>
            <Form.Group className="mb-3" controlId="formNombre">
              <Form.Label>Nombre</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Nombre presupuesto"
                onChange={handleNombre}
              />
              <Form.Control.Feedback type="invalid">
                Este campo es obligatorio.
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="formClientes">
              <Form.Label>Elegir Cliente</Form.Label>
              <AsyncTypeahead
                required
                style={{ width: '20rem' }}
                filterBy={filterBy}
                id="client-search"
                isLoading={isLoadingClientes}
                labelKey="nombre_fiscal"
                minLength={3}
                onSearch={getClientsFunc}
                onChange={handleCliente}
                options={clientesOptions}
                placeholder="Buscar Cliente"
                renderMenuItemChildren={(option) => (
                  <span>{option.nombre_fiscal}</span>
                )}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="biblioteca">
              <Form.Label>Elegir Biblioteca</Form.Label>
              <AsyncTypeahead
                style={{ width: '20rem' }}
                filterBy={filterBy}
                id="biblioteca-search"
                isLoading={isLoadingBiblioteca}
                labelKey="denominacion"
                minLength={3}
                onSearch={getBibliotecaFunc}
                onChange={handleBiblioteca}
                options={bibliotecaOptions}
                placeholder="Buscar Cliente"
                renderMenuItemChildren={(option) => (
                  <span>{option.denominacion}</span>
                )}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group controlId="visita">
              <Form.Label>Elegir Visita</Form.Label>
              <AsyncTypeahead
                style={{ width: '20rem' }}
                filterBy={filterBy}
                id="visita-search"
                isLoading={isLoadingVisita}
                labelKey="visita"
                minLength={3}
                onSearch={getVisitaFunc}
                onChange={handleVisita}
                options={visitaOptions}
                placeholder="Buscar Visita"
                // renderMenuItemChildren={(option) => (
                //   <span>{option}</span>
                // )}
                // inputProps={{ required: true }}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="mb-3" controlId="formObs">
              <Form.Label>Observaciones</Form.Label>
              <Form.Control
                type="text"
                placeholder="Ingrese Observaciones"
                onChange={handleObs}
              />
            </Form.Group>
            <Form.Control.Feedback type="invalid">
              Este campo no debe exceder los 100 caracteres.
            </Form.Control.Feedback>
          </Col>
          <Col>
            <Form.Group className="mb-3" controlId="formDescuento">
              <Form.Label>Agregar descuento en %</Form.Label>
              <Form.Control
                required
                type="number"
                placeholder="Ingrese monto del descuento"
                value={descuento}
                onChange={handleDescuento}
                min="0"
                max="100"
                step="0.01"
                pattern="[0-9]+([.][0-9]{0,2})?"
              />
              <Form.Control.Feedback type="invalid">
                Este campo debe ser un número decimal entre 0 y 100.
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <CustomTable cols={colsTable}>
            {itemsTable.map((item) => (
              <DetailProduct item={item} key={item.ean} />
            ))}
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td className="text-center">
                <Button
                  className="button-add"
                  variant="secondary"
                  onClick={addProductLine}
                >
                  Agregar producto
                </Button>
              </td>
            </tr>
            <tr>
              <td className="text-center">Totales</td>
              <td></td>
              <td></td>
              <td className="text-center">
                <strong>{totalItems}</strong>
              </td>
              <td></td>
              <td></td>
              <td className="text-center">
                <strong>
                  {(descuento || 0) === 0
                    ? totalPrice.toFixed(2)
                    : (totalPrice * (1 - descuento / 100)).toFixed(2)}
                </strong>
              </td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td className="text-center">
                <Button
                  className="button-add"
                  variant="secondary"
                  size="sm"
                  onClick={(id) => saveBudgetFunc(id)}
                  type="submit"
                >
                  Guardar presupuesto
                </Button>
              </td>
            </tr>
          </CustomTable>
        </Row>
      </Form>
      <Toast show={showToast} onClose={toggleToast}>
        <Toast.Header>
          <strong>Presupuesto creado con exito</strong>
        </Toast.Header>
        <Toast.Body>Sera redireccionado a la lista de presupuestos</Toast.Body>
      </Toast>
    </Container>
  )
}

export default Budget
