import React, { useState, useEffect } from 'react'
import Button from 'react-bootstrap/Button'

//components
import ModalCustom from '../../modal/index'

const ModalReadClient = ({ dataClient, isModal, setIsModal }) => {
  const [client, setClient] = useState({})

  useEffect(() => {
    setClient(dataClient)
  }, [dataClient])

  const handleClose = () => {
    setIsModal(false)
  }

  const Buttonfooter = () => {
    return (
      <div className="d-flex justify-content-end">
        <Button variant="secondary" onClick={handleClose} className="mr-3">
          Cerrar
        </Button>
      </div>
    )
  }

  return (
    <ModalCustom show={isModal} title={<p className="text-center">Cliente</p>}>
      <div className="d-flex flex-wrap p-3">
        <div className="flex-50">
          <p className="style-font">CIF: </p>
          <p>{client?.cif}</p>
        </div>
        <div className="flex-50">
          <p className="style-font">Nombre fiscal: </p>
          <p>{client?.nombre_fiscal}</p>
        </div>
        <div className="flex-50 pt-3">
          <p className="style-font">Código:</p>
          <p> {client?.codigo}</p>
        </div>
        <div className="flex-50 pt-3">
          <p className="style-font">Email:</p>
          <p> {client?.email}</p>
        </div>
        <div className="flex-50 pt-3">
          <p className="style-font">Empresa:</p>
          <p>{client?.empresa}</p>
        </div>
      </div>

      <Buttonfooter />
    </ModalCustom>
  )
}

export default ModalReadClient
