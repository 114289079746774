import React, { useEffect, useState } from 'react'
import Button from 'react-bootstrap/Button'
import { AsyncTypeahead } from 'react-bootstrap-typeahead'
import Form from 'react-bootstrap/Form'
import { Col, ListGroup, Row } from 'react-bootstrap'

//api
import { getLanguages } from '../../../api/company'
import { editProduct, getDetailAuthor } from '../../../api/products'
import { getMaterias, listEncuadernacion } from '../../../api/libros'

//components
import ModalCustom from '../../modal/index'
import CustomInput from '../../customInput/index'

const ModalEditProduct = ({
  dataProduct,
  isModal,
  setIsModal,
  getNameProduct,
}) => {
  const [productData, setProductData] = useState({
    url: dataProduct?.url,
    ean: dataProduct?.ean,
    titulo: dataProduct?.titulo,
    tipo: dataProduct?.type,
    editorial: dataProduct?.editorial?.nombre_fiscal,
    precioVenta: dataProduct?.precio_venta_publico,
    resumenTexto: dataProduct?.texto_resumen,
    publicado: dataProduct?.publicada,
    disponible: dataProduct?.available?.status,
    idiomas: dataProduct?.idioma,
    autoresEditores: dataProduct?.autor,
    materias: dataProduct?.genero,
    encuadernacion: dataProduct?.encuadernacion?.[0]?.descripcion,
    nroPaginas: dataProduct?.num_paginas,
    fechaPublicacion: dataProduct?.fecha_public,
    cover: dataProduct?.cover,
  })

  const [selectedCover, setSelectedCover] = useState(null)

  const [languageOptions, setLanguageOptions] = useState([])
  const [isLoadingLang, setIsLoadingLang] = useState(false)
  const [langArray, setLangArray] = useState([])

  const [authorOptions, setAuthorOptions] = useState([])
  const [isLoadingAuth, setIsLoadingLangAuth] = useState(false)
  const [authorArray, setAuthorArray] = useState([])

  const [MateriaOptions, setMateriaOptions] = useState([])
  const [isLoadingMat, setIsLoadingMat] = useState(false)
  const [materiaArray, setMateriaArray] = useState([])

  const [encuadernacionOptions, setEncuadernacionOptions] = useState([])
  const [isLoadingEnc, setIsLoadingEnc] = useState(false)
  const [encuadernacionArray, setEncuadernacionArray] = useState([])

  useEffect(() => {
    setProductData({
      url: dataProduct?.url,
      ean: dataProduct?.ean,
      titulo: dataProduct?.titulo,
      tipo: dataProduct?.type,
      editorial: dataProduct?.editorial?.nombre_fiscal,
      precioVenta: dataProduct?.precio_venta_publico,
      resumenTexto: dataProduct?.texto_resumen,
      publicado: dataProduct?.publicada,
      disponible: dataProduct?.available?.status,
      idiomas: dataProduct?.idioma,
      autoresEditores: dataProduct?.autor,
      genero: dataProduct?.genero,
      encuadernacion: dataProduct?.encuadernacion?.[0]?.descripcion,
      nroPaginas: dataProduct?.num_paginas,
      fechaPublicacion: dataProduct?.fecha_public,
      // cover: dataProduct?.cover,
    })
  }, [dataProduct?.ean])

  const getEncuadernacion = async (query) => {
    setIsLoadingEnc(true)
    const { status, data } = await listEncuadernacion(
      `?encuadernacion=${query}`
    )
    if (status === 200) {
      setEncuadernacionOptions(data)
      setIsLoadingEnc(false)
    }
  }

  const handleEncuadernacion = (selected) => {
    const encuadernaciones = selected.map((item) => item.descripcion)
    setProductData({
      ...productData,
      encuadernacion: [...productData.encuadernacion, encuadernaciones],
    })
  }

  const hanbleCoverChange = (event) => {
    if (event.target && event.target.files) {
      const file = event.target.files[0]
      const reader = new FileReader()
      reader.onloadend = () => {
        setSelectedCover(reader.result)
        setProductData((prevProductData) => ({
          ...prevProductData,
          cover: file,
        }))
      }
      reader.readAsDataURL(file)
    }
  }

  const getLanguagesFunc = async (query) => {
    setIsLoadingLang(true)
    const { status, data } = await getLanguages(`?nombre=${query}`)
    if (status === 200) {
      setLanguageOptions(data)
      setIsLoadingLang(false)
    }
  }

  const handleLanguage = (selected) => {
    const idiomas = selected.map((item) => item.nombre)
    setProductData({
      ...productData,
      idiomas: [...productData.idiomas, ...idiomas],
    })
  }

  const getAuthors = async (query) => {
    setIsLoadingLangAuth(true)
    const { status, data } = await getDetailAuthor(`?nombre_autor=${query}`)
    if (status === 200) {
      setAuthorOptions(data.results)
      setIsLoadingLangAuth(false)
    }
  }

  const handleAuthors = (selected) => {
    const authors = selected.map((item) => item.nombre_autor)
    setProductData({
      ...productData,
      autoresEditores: [...productData.autoresEditores, ...authors],
    })
  }

  const getMateriasFunc = async (query) => {
    setIsLoadingMat(true)
    const { status, data } = await getMaterias(`?nombre=${query}`)
    if (status === 200) {
      setMateriaOptions(data.results)
      setIsLoadingMat(false)
    }
  }

  const handleMaterias = (selected) => {
    const materia = selected.map((item) => item.nombre)
    setProductData({
      ...productData,
      genero: [...productData.genero, ...materia],
    })
  }

  const updateProduct = async (e) => {
    e.preventDefault()
    const formData = new FormData()
    formData.append('cover', selectedCover)

    for (const key in productData) {
      formData.append(key, productData[key])
    }
    const { status } = await editProduct(productData.url, productData)
    console.log('productData', productData)
    if (status === 200) {
      setIsModal(false)
    } else {
      setIsModal(true)
    }
  }

  const filterBy = () => true

  const handleClose = () => {
    setIsModal(false)
  }

  const tipos = [
    {
      title: 'Libro',
      value: '1',
    },
    {
      title: 'Cine',
      value: '2',
    },
    {
      title: 'Música',
      value: '3',
    },
  ]

  const size = 'xl'

  const Buttonfooter = () => {
    return (
      <div className="text-center">
        <Button variant="secondary" onClick={handleClose} className="mr-3">
          Cancelar
        </Button>
        <Button variant="secondary" onClick={updateProduct}>
          Aceptar
        </Button>
      </div>
    )
  }

  return (
    <ModalCustom
      show={isModal}
      title={<p className="text-center">Modificar Producto</p>}
      size={size}
    >
      <div className="margin-bottom-30">
        <Row>
          <Col sm={6}>
            <CustomInput
              disabled={true}
              inputValue={productData.ean}
              type={'text'}
              label={'EAN'}
            />
          </Col>
          <Col sm={6}>
            <CustomInput
              inputValue={productData.titulo}
              onChange={(e) =>
                setProductData({ ...productData, titulo: e.target.value })
              }
              type={'text'}
              label={'Titulo'}
            />
          </Col>
        </Row>
        <Row>
          <Col className="mt-4">
            <Form.Group>
              <Form.Label>Elija el tipo</Form.Label>
              <Form.Control as="select" value={productData.tipo}>
                <option value="0">Elija una opción</option>
                {tipos.map((item) => (
                  <option key={item.title} value={item.value}>
                    {item.title}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Col>
          <Col>
            <CustomInput
              inputValue={productData?.editorial}
              onChange={(e) =>
                setProductData({ ...productData, editorial: e.target.value })
              }
              type={'text'}
              label={'Editorial'}
            />
          </Col>
          <Col>
            <CustomInput
              inputValue={productData.precioVenta}
              onChange={(e) =>
                setProductData({ ...productData, precioVenta: e.target.value })
              }
              type={'text'}
              label={'Precio de venta'}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group>
              <Form.Label>Resumen texto</Form.Label>
              <Form.Control
                as="textarea"
                value={productData.resumenTexto}
                onChange={(e) =>
                  setProductData({
                    ...productData,
                    resumenTexto: e.target.value,
                  })
                }
                rows={3}
              />
            </Form.Group>
          </Col>
          <Col className="mt-4">
            <Form.Check
              type="checkbox"
              id="publicado"
              label="Publicado"
              defaultChecked={productData.publicado}
              onClick={(e) =>
                setProductData({ ...productData, publicado: e.target.checked })
              }
            />
          </Col>
          <Col className="mt-4">
            <Form.Check
              type="checkbox"
              id="disponible"
              label="Disponible"
              defaultChecked={productData.disponible}
              onClick={(e) =>
                setProductData({ ...productData, disponible: e.target.checked })
              }
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group>
              <Form.Label>Elegir Idioma</Form.Label>
              <AsyncTypeahead
                style={{ width: '30rem' }}
                filterBy={filterBy}
                id="idioma-search"
                isLoading={isLoadingLang}
                labelKey="nombre"
                minLength={3}
                onSearch={getLanguagesFunc}
                onChange={(value) => {
                  setLangArray((previous) => [...previous, ...value])
                  handleLanguage(value)
                }}
                options={languageOptions}
                placeholder="Buscar idioma"
                renderMenuItemChildren={(option) => (
                  <span>{option.nombre}</span>
                )}
              />
            </Form.Group>
          </Col>
          <Col className="mt-3">
            Idiomas
            <ListGroup horizontal>
              {langArray.map((item) => (
                <ListGroup.Item key={item.codigo}>{item.nombre}</ListGroup.Item>
              ))}
            </ListGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group>
              <Form.Label>Elegir Autores</Form.Label>
              <AsyncTypeahead
                style={{ width: '30rem' }}
                filterBy={filterBy}
                id="author-search"
                isLoading={isLoadingAuth}
                labelKey="nombre_autor"
                minLength={3}
                onSearch={getAuthors}
                onChange={(value) => {
                  setAuthorArray((previous) => [...previous, ...value])
                  handleAuthors(value)
                }}
                options={authorOptions}
                placeholder="Buscar Author"
                renderMenuItemChildren={(option) => (
                  <span>{option.nombre_autor}</span>
                )}
              />
            </Form.Group>
          </Col>
          <Col className="mt-2">
            Autores/Editores
            <ListGroup horizontal>
              {authorArray.map((item) => (
                <ListGroup.Item key={item.id}>
                  {item.nombre_autor}
                </ListGroup.Item>
              ))}
            </ListGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group>
              <Form.Label>Elegir Materias</Form.Label>
              <AsyncTypeahead
                style={{ width: '30rem' }}
                filterBy={filterBy}
                id="materias-search"
                isLoading={isLoadingMat}
                labelKey="nombre"
                minLength={3}
                onSearch={getMateriasFunc}
                onChange={(value) => {
                  setMateriaArray((previous) => [...previous, ...value])
                  handleMaterias(value)
                }}
                options={MateriaOptions}
                placeholder="Buscar Materias"
                renderMenuItemChildren={(option) => (
                  <span>{option.nombre}</span>
                )}
              />
            </Form.Group>
          </Col>
          <Col className="mt-2">
            Materias
            <ListGroup horizontal>
              {materiaArray.map((item) => (
                <ListGroup.Item key={item.id}>{item.nombre}</ListGroup.Item>
              ))}
            </ListGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group>
              <Form.Label>Elegir Encuadernacion</Form.Label>
              <AsyncTypeahead
                style={{ width: '30rem' }}
                filterBy={filterBy}
                id="encuadernacion-search"
                isLoading={isLoadingEnc}
                labelKey="descripcion"
                minLength={3}
                onSearch={getEncuadernacion}
                onChange={(value) => {
                  setEncuadernacionArray((previous) => [...previous, ...value])
                  handleEncuadernacion(value)
                }}
                options={encuadernacionOptions}
                placeholder="Buscar tipo de encuadernacion"
                renderMenuItemChildren={(option) => (
                  <span>{option.descripcion}</span>
                )}
              />
            </Form.Group>
          </Col>
          <Col className="mt-2">
            Encuadernacion
            <ListGroup horizontal>
              {encuadernacionArray.map((item) => (
                <ListGroup.Item key={item.id}>
                  {item.descripcion}
                </ListGroup.Item>
              ))}
            </ListGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <CustomInput
              inputValue={productData.nroPaginas}
              onChange={(e) =>
                setProductData({ ...productData, nroPaginas: e.target.value })
              }
              type={'text'}
              label="Cantidad de paginas"
            />
          </Col>
          <Col>
            <CustomInput
              inputValue={productData.fechaPublicacion}
              onChange={(e) =>
                setProductData({
                  ...productData,
                  fechaPublicacion: e.target.value,
                })
              }
              type={'text'}
              label="Fecha publicación"
            />
          </Col>
          <Col>
            {/* <Form.Group controlId="formFile">
              <Form.File
                onChange={hanbleCoverChange}
                label="Cover"
                accept="*"
              />
            </Form.Group> */}
          </Col>
        </Row>
      </div>
      <Buttonfooter />
    </ModalCustom>
  )
}

export default ModalEditProduct
