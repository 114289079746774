import React, { useState, useEffect, useMemo, useReducer } from 'react'
import { useParams } from 'react-router-dom'
import { Spinner, Container, Row, Col } from 'react-bootstrap'
import { AiOutlineFilter } from 'react-icons/ai'
import FilterProductBooks from '../../components/filters/filterProductBooks'
import Alert from 'react-bootstrap/Alert'
import { debounce, isEmpty } from 'lodash'

//own components
import ItemList from '../../components/itemList/item'

// hooks
import { useIntersectionObserver } from 'react-intersection-observer-hook'

//api
import { searchFilterProduct } from '../../api/products'

const reducer = (state, action) => {
  const { data } = action
  switch (action.type) {
    case 'initialData':
      return { ...state, queryResults: data.queryResults, isUpdating: false }
    case 'updateOnScroll':
      return {
        ...state,
        queryResults: {
          results: [...state.queryResults.results, ...data.results],
          next: data.next,
          count: data.count,
        },
        page: data.page,
        isUpdating: true,
      }
    case 'updateSearch':
      return { ...state, ...data }
    case 'updateComplete':
      return { ...state, isUpdating: false }
    default:
      return state
  }
}

const DynamicFilter = () => {
  const initialState = {
    querySearch: {},
    queryResults: {},
    page: 1,
    isUpdating: false,
  }

  const [isLoading, setIsLoading] = useState(false)
  const [contentListBottom, { entry }] = useIntersectionObserver()
  const isVisible = entry && entry.isIntersecting
  const [state, dispatch] = useReducer(reducer, initialState)

  const { type } = useParams()

  const updateOnScroll = async () => {
    if (isVisible && !isLoading && state.queryResults?.next) {
      const page = state.page + 1
      try {
        const { data } = await searchFilterProduct({
          page,
          page_size: 20,
          ...state.querySearch,
        })
        dispatch({ type: 'updateOnScroll', data: { ...data, page } })
      } catch (e) {
        console.log('e', e)
      } finally {
        setIsLoading(false)
      }
    }
  }

  useEffect(() => {
    updateOnScroll()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible])

  //render
  const renderFilterProducts = useMemo(() => {
    const updateQuery = debounce(async (query) => {
      try {
        setIsLoading(true)
        if (
          query.pais_public ||
          query.idioma ||
          query.autor ||
          query.ean ||
          query.editorial ||
          query.materia_cdu ||
          query.titulo
        ) {
          const ean = query.ean
          const editorial = query.editorial
          const materia_cdu = query.materia_cdu
          const autor = query.autor
          const titulo = query.titulo
          const type = query.type
          const pais_public = query.pais_public
          const { data } = await searchFilterProduct({
            ean,
            editorial,
            materia_cdu,
            autor,
            titulo,
            page_size: 20,
            type,
            pais_public,
          })
          dispatch({
            type: 'updateSearch',
            data: { querySearch: query, queryResults: data },
          })
        }
      } catch (e) {
        console.log('e', e)
      }
      setIsLoading(false)
    }, 750)

    return <FilterProductBooks filterProductsHandle={updateQuery} />
  }, [])

  const nf = new Intl.NumberFormat('de-DE')

  return (
    <Container>
      <Row className="mt-4 align-items-center">
        <Col md={11} xs={10}>
          <h4 className="mb-0">Filtro</h4>
        </Col>
        <Col md={1} xs={2}>
          <AiOutlineFilter onClick={() => 'd'} className="icon-md" />
        </Col>
      </Row>
      <Row className="mt-1 align-items-center">
        <Col>
          <hr />
        </Col>
      </Row>
      <Row className="mb-4">
        <Col lg={3} sm={12} xs={12}>
          {renderFilterProducts}
        </Col>
        <Col lg={9}>
          {state.queryResults?.results?.length > 0 && (
            <Alert variant={'primary'}>
              Se encontraron{' '}
              {state.queryResults ? nf.format(state.queryResults.count) : 0}{' '}
              resultados
            </Alert>
          )}
          <Row>
            {isLoading ? (
              <Col md={12} lg={12} sm={12} style={{ marginTop: 55 }}>
                <h5 className="text-center">
                  {' '}
                  <Spinner animation="border" role="status"></Spinner>
                </h5>
              </Col>
            ) : (
              state.queryResults &&
              !isEmpty(state.queryResults.results) &&
              state.queryResults?.results?.map((item, i) => (
                <Col md={4} lg={3} sm={6} key={i} style={{ marginTop: 5 }}>
                  <ItemList key={i} data={item} />
                </Col>
              ))
            )}
            <Col md={12} lg={12} sm={12} style={{ marginTop: 55 }}>
              <h6 className="text-center">
                {state.queryResults.count === 0 && (
                  <p>No se encontraron resultados de la busqueda.</p>
                )}{' '}
              </h6>
            </Col>
            {state.isUpdating && (
              <Col md={12} lg={12} sm={12} style={{ marginTop: 55 }}>
                <h5 className="text-center">
                  {' '}
                  <Spinner animation="border" role="status"></Spinner>
                </h5>
              </Col>
            )}
            {state.queryResults?.next !== null && !isLoading && (
              <Col md={12} lg={12} sm={12} style={{ marginTop: 55 }}>
                <h6 className="text-center">
                  {state.queryResults.results === undefined && (
                    <p>Ingrese parámetros para comenzar la búsqueda.</p>
                  )}{' '}
                </h6>
                {isLoading && (
                  <Spinner animation="border" role="status"></Spinner>
                )}
              </Col>
            )}
          </Row>
          {!isLoading && state.queryResults?.next && (
            <Col md={12} lg={12} sm={12}>
              <div
                className="content-bottom"
                style={{ height: '20px' }}
                ref={contentListBottom}
              ></div>
            </Col>
          )}
        </Col>
      </Row>
    </Container>
  )
}
export default DynamicFilter
