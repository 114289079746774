import React from 'react'
import CardEvent from '../../components/event/itemEvent'
import CardBlog from '../../components/blog/cardBlog'
import './home.scss'

const EventHome = ({ blogs, events }) => {
  return (
    <div className="homeContainer">
      <div
        className="containerPosts"
        style={{ textAlign: 'center', paddingTop: 10, paddingBottom: 30 }}
      >
        <a href="/materias-destacadas" style={{ fontSize: 20 }}>
          Materias destacadas
        </a>
      </div>
      {blogs?.length > 0 && (
        <div className="containerPosts">
          <div className="titleHomeContainer">Lo último</div>
          <hr />
          {blogs.map((item, i) => (
            <CardBlog
              event={item}
              key={i}
              isContainer={false}
              isRow={false}
              hasLine={blogs?.length - 1 !== i}
            />
          ))}
        </div>
      )}
      {events?.length > 0 && (
        <div className="containerPosts">
          <div className="titleHomeContainer">Agenda</div>
          <hr />
          {events.map((item, i) => (
            <CardEvent event={item} key={i} />
          ))}
        </div>
      )}
    </div>
  )
}

export default EventHome
