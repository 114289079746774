import React, { useState, useEffect } from 'react'
import Button from 'react-bootstrap/Button'
import { Col, Form, Row } from 'react-bootstrap'
//api
import { editSlider } from '../../../api/products'
//components
import ModalCustom from '../../modal/index'

const ModalEditSlider = ({ dataSlider, isModal, setIsModal, getSliders }) => {
  const [sliderId, setSliderId] = useState('')
  const [sliderTipo, setSliderTipo] = useState('')
  const [sliderPortada, setSliderPortada] = useState('')
  const [sliderActivo, setSliderActivo] = useState(false)
  const [selectedImage, setSelectedImage] = useState(null)

  useEffect(() => {
    setSliderId(dataSlider?.id)
    setSliderTipo(dataSlider?.tipo)
    setSliderPortada(dataSlider?.portada)
    setSliderActivo(dataSlider?.estado)
  }, [dataSlider?.id])

  const params = {
    producto: dataSlider?.producto.id,
    tipo: sliderTipo,
    estado: sliderActivo,
    empresa: dataSlider?.empresa,
    portada: selectedImage,
  }

  const updateSlider = async (e) => {
    e.preventDefault()
    const formData = new FormData()
    formData.append('portada', selectedImage)

    for (const key in params) {
      formData.append(key, params[key])
    }
    const { status } = await editSlider(sliderId, formData)
    if (status === 200) {
      getSliders()
      setIsModal(false)
    } else {
      setIsModal(true)
    }
  }

  const handleImageChange = (event) => {
    setSelectedImage(event.target.files[0])
  }

  const handleClose = () => {
    setIsModal(false)
  }

  const handleTipo = (e) => {
    setSliderTipo(e.target.value)
  }

  const tipos = [
    {
      title: 'Libro',
      value: '1',
    },
    {
      title: 'Cine',
      value: '2',
    },
    {
      title: 'Música',
      value: '3',
    },
  ]

  const size = 'xl'

  const Buttonfooter = () => {
    return (
      <div className="text-center">
        <Button variant="secondary" onClick={handleClose} className="mr-3">
          Cancelar
        </Button>
        <Button variant="secondary" onClick={updateSlider}>
          Aceptar
        </Button>
      </div>
    )
  }

  return (
    <ModalCustom
      show={isModal}
      title={<p className="text-center">Modificar Slider</p>}
      size={size}
    >
      <Row>
        <Col sm={6}>
          <Form.Group>
            <Form.Label>Elija el tipo</Form.Label>
            <Form.Control as="select" value={sliderTipo} onChange={handleTipo}>
              <option value="0">Elija una opción</option>
              {tipos.map((item) => (
                <option key={item.title} value={item.value}>
                  {item.title}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group controlId="formFile" className="mb-3">
            <Form.File
              onChange={handleImageChange}
              label="Portada"
              accept="*"
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col className="mt-3">
          <Form.Check
            type="checkbox"
            id="activo"
            label="Activo"
            defaultChecked={sliderActivo}
            onClick={(e) => setSliderActivo(e.target.checked)}
          />
        </Col>
      </Row>
      <Buttonfooter />
    </ModalCustom>
  )
}

export default ModalEditSlider
