import React, { useState, useEffect } from 'react'
import { Row, Col, Container, Button } from 'react-bootstrap'
import Cookie from 'js-cookie'

import './cookies.scss'

const Cookies = () => {
  const [isAccepted, setIsAccepted] = useState(null)
  var cookieAccepted = Cookie.get('cookieAccepted')

  if (!cookieAccepted) {
    Cookie.set('cookieAccepted', 'false')
    cookieAccepted = Cookie.get('cookieAccepted')
  }

  useEffect(() => {
    cookieAccepted = Cookie.get('cookieAccepted')
    setIsAccepted(cookieAccepted)
  }, [isAccepted])

  const acceptCookie = () => {
    Cookie.set('cookieAccepted', 'true')
    cookieAccepted = Cookie.get('cookieAccepted')
    setIsAccepted(cookieAccepted)
  }

  return (
    <Container fluid className="cookies">
      {isAccepted === 'false' ? (
        <Row className="subscription-row cookies-row">
          <Col lg={8}>
            <p>
              Utilizamos cookies propias y de terceros para fines analíticos y
              para mostrarte publicidad personalizada en base a un perfil
              elaborado a partir de tus hábitos de navegación (por ejemplo,
              páginas visitadas). Puedes aceptar todas las cookies pulsando el
              botón “Aceptar” o rechazarlas bloqueando el uso de cookies desde
              la configuración de su navegador.
            </p>
          </Col>
          <Col lg={2}>
            <Button onClick={acceptCookie}>Aceptar</Button>
          </Col>
        </Row>
      ) : null}
    </Container>
  )
}

export default Cookies
