import React, { createContext, useState } from 'react'

//helpers
import { getItemStore } from '../helpers'
export const UserContext = createContext()

const UserContextProvider = (props) => {
  const logged = getItemStore('access') ? true : false
  const typeUser = getItemStore('type_user')
  const [isLogged, setIsLogged] = useState(logged)
  const [user, setUser] = useState(typeUser)
  const [isSignUpModalOpen, showSignUpModal] = useState(false)
  const [isRegister, setIsRegister] = useState(false)

  return (
    <UserContext.Provider
      value={{
        isLogged,
        setIsLogged,
        user,
        setUser,
        isSignUpModalOpen,
        showSignUpModal,
        isRegister,
        setIsRegister
      }}
    >
      {props.children}
    </UserContext.Provider>
  )
}

export default UserContextProvider
