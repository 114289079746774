import React, { useState, useEffect } from 'react'
import Button from 'react-bootstrap/Button'
//api
import { createAuthor } from '../../../api/authors'
//components
import CustomInput from '../../customInput/index'
import ModalCustom from '../../modal/index'

const ModalCreateAuthor = ({
  dataAuthor,
  setIsModal,
  isModal,
  getNameAuthors,
}) => {
  const [nameAuthor, setNameAuthor] = useState('')
  const [validate, setValidate] = useState('')

  const data = {
    nombre_autor: nameAuthor,
  }
  const onSubmit = async () => {
    try {
      const response = await createAuthor(data)
      if (response.status === 201) {
        getNameAuthors()
        setNameAuthor('')
        setIsModal(false)
      }
      if (nameAuthor === '' && response.status === 400) {
        setValidate('Campo obligatorio')
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleValue = (e) => {
    setNameAuthor(e.target.value)
  }

  const handleClose = () => {
    setIsModal(false)
  }
  const size = 'md'

  const Buttonfooter = () => {
    return (
      <div className="d-flex justify-content-end">
        <Button variant="secondary" onClick={handleClose} className="mr-3">
          Cerrar
        </Button>
        <Button variant="secondary" onClick={onSubmit}>
          Enviar
        </Button>
      </div>
    )
  }
  return (
    <div>
      <ModalCustom
        show={isModal}
        title={<p className="text-center">Nuevo autor</p>}
      >
        <div className="margin-bottom-30">
          <CustomInput
            size={size}
            onChange={handleValue}
            inputValue={nameAuthor}
            type={'text'}
            label={'Nombre de autor'}
          />
          {validate && (
            <p className="text-danger ">
              <small>{validate}</small>
            </p>
          )}
        </div>
        <Buttonfooter />
      </ModalCustom>
    </div>
  )
}

export default ModalCreateAuthor
