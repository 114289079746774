import React from 'react'
import Button from 'react-bootstrap/Button'
import PropTypes from 'prop-types'
//api
import { deleteList } from '../../../api/lists-intranet'
//components
import ModalCustom from '../../modal/index'

const ModalDeleteList = ({ url, isOpen, setIsOpen, getDataList }) => {
  const handleClose = () => {
    setIsOpen(false)
  }

  const listDelete = async () => {
    const { status } = await deleteList(url)
    if (status === 204) {
      getDataList()
      setIsOpen(false)
    }
  }

  const Buttonfooter = () => {
    return (
      <div className="d-flex justify-content-end">
        <Button variant="secondary" onClick={handleClose} className="mr-3">
          Cancelar
        </Button>
        <Button variant="secondary" onClick={listDelete}>
          Aceptar
        </Button>
      </div>
    )
  }

  return (
    <>
      <ModalCustom
        show={isOpen}
        title={<h5 className="text-center">¿Está seguro de eliminar?</h5>}
      >
        <Buttonfooter />
      </ModalCustom>
    </>
  )
}

ModalDeleteList.propTypes = {
  url: PropTypes.string,
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  getDataList: PropTypes.func,
}
export default ModalDeleteList
