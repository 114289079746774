import React, { useState, useEffect } from 'react'
import { getPost, getPosts } from '../../api/blog'
import { Container, Spinner } from 'react-bootstrap'
import CardBlog from '../../components/blog/cardBlog'
import './blog.scss'

const Post = ({ match }) => {
  const [post, setPost] = useState(null)
  const [loaded, setLoaded] = useState(false)
  const [posts, setPosts] = useState([])
  const [postsCommons, setPostsCommons] = useState([])

  useEffect(() => {
    const getPostData = async () => {
      const rs = await getPost(match.params)
      if (rs.status === 200) {
        setPost(rs.data)
        setLoaded(true)
        preloadImages(rs.data.featured_img)
      }
    }
    getPostData()
    async function loadBlogs() {
      const { status, data } = await getPosts({ page_size: 2 })
      if (status === 200) {
        setPosts(data.results)
        const postImgs = data.results.map(function (x) {
          return x.featured_img
        })
        postImgs.forEach((element) => preloadImages(element))
      }
    }
    function preloadImages(url) {
      let img = new Image()
      img.src = url
    }
    loadBlogs()
    async function loadCommons() {
      const { status, data } = await getPosts({ page_size: 4 })
      if (status === 200) {
        setPostsCommons(data.results)
      }
    }
    loadCommons()
  }, [match])
  if (loaded) {
    return (
      <div className="container p-rel margin-top-md margin-bottom-md">
        <div className="blogContainer">
          <div className="containerPostList">
            <div
              className="titleColumnPostTitle"
              style={{ textTransform: 'uppercase' }}
            >
              {post.title}
            </div>
            <CardBlog event={post} isContainer={true} isOnlyPost={true} />
          </div>
          <div className="columnPostList">
            <div className="titleColumnPostTitle">Más visitados</div>
            {posts.map((post, i) => {
              return (
                <CardBlog
                  event={post}
                  key={i}
                  isColumn={true}
                  hasLine={posts.length - 1 !== i}
                />
              )
            })}
          </div>
        </div>
        <div className="titleColumnPostTitle margin-top-md">Más del autor</div>
        <div style={{ width: '100%', padding: '0px 25px 0px 25px' }}>
          <hr />
        </div>
        <div className="scrollBlogContainer">
          {postsCommons.map((post, i) => {
            return (
              <CardBlog
                event={post}
                key={i}
                isColumn={true}
                hasLine={false}
                isRow={true}
              />
            )
          })}
        </div>
      </div>
    )
  } else {
    return (
      <Container fluid={true} style={{ height: '50vh' }}>
        <Spinner
          data-testid="spinner-grow"
          animation={'grow'}
          style={{ marginTop: '20vh', marginLeft: '40vw' }}
        />
      </Container>
    )
  }
}

export default Post
