import React, { useState, useEffect } from 'react'
import Button from 'react-bootstrap/Button'
import PropTypes from 'prop-types'
//components
import ModalCustom from '../../modal/index'

const ModalReadList = ({ dataList, isModal, setIsModal }) => {
  const [list, setList] = useState({})

  useEffect(() => {
    setList(dataList)
  }, [dataList])

  const handleClose = () => {
    setIsModal(false)
  }

  const Buttonfooter = () => {
    return (
      <div className="d-flex justify-content-end">
        <Button variant="secondary" onClick={handleClose} className="mr-3">
          Cerrar
        </Button>
      </div>
    )
  }

  return (
    <ModalCustom
      show={isModal}
      title={<p className="text-center">Detalle de Lista</p>}
    >
      <div className="d-flex flex-wrap p-3">
        <div className="flex-50">
          <p className="style-font">Nombre: </p>
          <p>{list?.nombre}</p>
        </div>
        <div className="flex-50">
          <p className="style-font">Url: </p>
          <p>{list?.url}</p>
        </div>
      </div>

      <Buttonfooter />
    </ModalCustom>
  )
}

ModalReadList.propTypes = {
  dataList: PropTypes.object,
  isModal: PropTypes.bool,
  setIsModal: PropTypes.func,
}

export default ModalReadList
