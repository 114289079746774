import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'

//icons
import { FaEdit } from 'react-icons/fa'
import { BsSearch } from 'react-icons/bs'

//api
import { getListProducts } from '../../../api/products'

//custom hook
import { useQuery } from '../../../utils/customHooks/query'

//components
import CustomInput from '../../../components/customInput'
import CustomTable from '../../../components/table'
import ModalEditProduct from '../../../components/productsIntranet/modalEdit/index'

import { COIN } from '../../../constans'

const Productos = () => {
  const navigate = useNavigate()
  const [listsName, setListsName] = useState([])
  const searchParams = useQuery()

  const page = searchParams.get('page') || 1
  const pageSize = 10
  const [inputValue, setInputValue] = useState(searchParams.get('titulo') || '')
  const [noResults, setNoResults] = useState(false)
  const [totalPages, setTotalPages] = useState(1)
  const [pageCurrent, setPageCurrent] = useState(1)
  const [dataProduct, setDataProduct] = useState({})
  const [openModal, setOpenModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [isClear, setIsClear] = useState(false)
  const [initialQuery, setinitialQuery] = useState({
    titulo: inputValue,
    page: page,
    page_size: pageSize,
    type: 'products',
  })

  const colsEditorials = ['EAN', 'Nombre', 'PVP', 'Editar']

  const getProductsList = async () => {
    setLoading(true)
    const { data, status } = await getListProducts(initialQuery)
    const { results, total_pages } = data
    if (status === 200) {
      setListsName(results)
      setTotalPages(total_pages)
    }
    setLoading(false)
  }

  const handleValue = (e) => {
    const title = e.target.value
    setinitialQuery((prev) => ({ ...prev, titulo: title }))
    const search = new URLSearchParams({
      ...initialQuery,
      titulo: title,
    })
    navigate(`?${search.toString()}`)
  }

  const handleChangePage = (page) => {
    const search = new URLSearchParams({
      ...initialQuery,
      page,
    })
    setPageCurrent(page)
    setinitialQuery((prev) => ({ ...prev, page }))
    navigate(`?${search.toString()}`)
  }

  useEffect(() => {
    getProductsList()
  }, [pageCurrent])

  useEffect(() => {
    if (isClear) {
      getProductsList()
      setIsClear(false)
    }
  }, [isClear])

  const handleClear = () => {
    navigate('/intranet/productos', { replace: true })
    setInputValue('')
    setNoResults(false)
    const paramsReset = {
      titulo: '',
      page: 1,
      page_size: pageSize,
      type: 'products',
    }
    setinitialQuery(paramsReset)
    setPageCurrent(1)
    setTotalPages(1)
    if (initialQuery.page === 1) {
      setIsClear(true)
    }
  }

  const openIsModal = (item, id, setOpenModal) => {
    if (item.id === id) {
      setOpenModal(true)
      setDataProduct(item)
    }
  }

  return (
    <div>
      <h1>Productos</h1>
      <div className="d-flex justify-content-end m-3">
        <CustomInput
          handleSubmit={getProductsList}
          onChange={(e) => handleValue(e)}
          inputValue={initialQuery.titulo}
          placeholder="Buscar producto por nombre"
          icon={<BsSearch color="white" />}
          type="search"
        />
        <button onClick={handleClear}>Limpiar</button>
      </div>
      <CustomTable
        cols={colsEditorials}
        noResults={noResults}
        totalPages={totalPages}
        page={parseInt(page)}
        changePage={handleChangePage}
        isLoading={loading}
      >
        {listsName &&
          listsName.map((listName) => {
            return (
              <tr key={listName.id}>
                <td>{listName.ean}</td>
                <td className="w-50">{listName.titulo}</td>
                <td className="text-right">
                  {listName.precio_venta_publico} {COIN}
                </td>
                <td className="text-center">
                  <FaEdit
                    className="cursor-pointer"
                    onClick={() =>
                      openIsModal(listName, listName.id, setOpenModal)
                    }
                  />
                </td>
              </tr>
            )
          })}
      </CustomTable>
      <ModalEditProduct
        dataProduct={dataProduct}
        isModal={openModal}
        setIsModal={setOpenModal}
        getProductsList={getProductsList}
      />
    </div>
  )
}
export default Productos
