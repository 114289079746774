import PropTypes from 'prop-types'
import React from 'react'
import { Pagination } from 'react-bootstrap'

const IntranetPagination = ({ page, totalPages, changePage }) => {
  return (
    <Pagination>
      {page > 1 && (
        <>
          <Pagination.First onClick={() => changePage(1)} />
          <Pagination.Prev
            onClick={() => changePage((prevPage) => prevPage - 1)}
          />
          <Pagination.Item onClick={() => changePage(1)}>{1}</Pagination.Item>
        </>
      )}
      {page > 3 && <Pagination.Ellipsis />}
      {page - 2 > 1 && (
        <Pagination.Item onClick={() => changePage((prevPage) => prevPage - 2)}>
          {page - 2}
        </Pagination.Item>
      )}
      {page - 1 > 1 && (
        <Pagination.Item onClick={() => changePage((prevPage) => prevPage - 1)}>
          {page - 1}
        </Pagination.Item>
      )}

      <Pagination.Item active>{page}</Pagination.Item>

      {page + 1 < totalPages && (
        <Pagination.Item onClick={() => changePage(page + 1)}>
          {page + 1}
        </Pagination.Item>
      )}
      {page + 2 < totalPages && (
        <Pagination.Item onClick={() => changePage(page + 2)}>
          {page + 2}
        </Pagination.Item>
      )}
      {totalPages - 3 > page && <Pagination.Ellipsis />}
      {page < totalPages && (
        <>
          <Pagination.Item onClick={() => changePage(totalPages)}>
            {totalPages}
          </Pagination.Item>
          <Pagination.Next
            onClick={() => changePage((prevPage) => prevPage + 1)}
          />
          <Pagination.Last onClick={() => changePage(totalPages)} />
        </>
      )}
    </Pagination>
  )
}

IntranetPagination.propTypes = {
  page: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  changePage: PropTypes.func.isRequired,
}

export default IntranetPagination
