import React, { useState, useEffect } from 'react'
import { FaEdit } from 'react-icons/fa'
import { AiFillDelete } from 'react-icons/ai'
import { Button, Col, Row } from 'react-bootstrap'
//components
import CustomTable from '../../../components/table'
import CustomInput from '../../../components/customInput'
import { getListConcourse } from '../../../api/concourse'
import { InputCustom, SelectInputCustom } from '../../../components/input'
import ModalCreateConcourse from '../../../components/concoursesIntranet/modalCreate'
import ModalEditConcourse from '../../../components/concoursesIntranet/modalEdit'
import ModalDeleteConcourse from '../../../components/concoursesIntranet/modalDelete'

const colsConcourse = [
  'Nombre',
  'Descuento',
  'Usuario especial',
  'Nº de usuarios',
  'Acciones',
]

const Concursos = () => {
  const [page, setPage] = useState(1)
  const [concourses, setConcourses] = useState([])
  const [dataConcourse, setDataConcourse] = useState({})
  const [openModalEdit, setOpenModalEdit] = useState(false)
  const [openModalCreate, setOpenModalCreate] = useState(false)
  const [openModalDelete, setOpenModalDelete] = useState(false)

  //const [filterOptions, setFilterOptions] = useState({})

  /* useEffect(() => {
    const getFilterOptions = async () => {
      const { data, status } = await getOrdersFiltersApi()
      if (status === 200) {
        setFilterOptions(data)
      }
    }
    getFilterOptions()
  }, [])*/

  const getConcourses = async () => {
    const { data, status } = await getListConcourse({ page: page })
    if (status === 200) {
      setConcourses(data.results)
    } else {
    }
  }

  useEffect(() => {
    getConcourses()
  }, [page])

  const openIsModal = (item, id, setOpenModal) => {
    if (item.id === id) {
      setOpenModal(true)
      setDataConcourse(item)
    }
  }

  return (
    <div>
      <div className="d-flex mt-3 justify-content-between">
        <h1>Concursos</h1>
        <Button
          variant="secondary"
          type="submit"
          className="height-39 mr-4 "
          onClick={() => setOpenModalCreate(true)}
        >
          Crear concurso
        </Button>
      </div>
      <div className="d-flex justify-content-end m-3">
        <CustomInput
          //handleSubmit={(e) => handleSubmit(e)}
          // onChange={handleValue}
          //inputValue={inputName}
          //icon={<BsSearch color="white" />}
          placeholder={'Buscar por nombre'}
          type={'search'}
        />
        <button>Limpiar url</button>
      </div>
      <CustomTable
        cols={colsConcourse}
        noResults={concourses?.length === 0}
        page={page}
        totalPages={concourses?.total_pages}
        changePage={setPage}
      >
        {concourses?.map((concourse) => (
          <tr key={concourse.id}>
            <td>{concourse.nombre}</td>
            <td>{`${concourse.descuento}%`}</td>
            <td>{concourse.usuario_especial.username}</td>
            <td>{concourse.usuarios_biblioteca.length}</td>
            <td className="text-center">
              <FaEdit
                className="mr-3 cursor-pointer"
                onClick={() =>
                  openIsModal(concourse, concourse.id, setOpenModalEdit)
                }
              />
              <AiFillDelete
                className="mr-3 cursor-pointer"
                onClick={() => {
                  openIsModal(concourse, concourse.id, setOpenModalDelete)
                }}
              />
            </td>
          </tr>
        ))}
      </CustomTable>
      <ModalCreateConcourse
        openModal={openModalCreate}
        setOpenModal={setOpenModalCreate}
        getConcourses={getConcourses}
      />
      <ModalEditConcourse
        openModal={openModalEdit}
        setOpenModal={setOpenModalEdit}
        getConcourses={getConcourses}
        dataConcourse={dataConcourse}
      />
      <ModalDeleteConcourse
        id={dataConcourse.id}
        isOpen={openModalDelete}
        setIsOpen={setOpenModalDelete}
        getConcourses={getConcourses}
      />
    </div>
  )
}

export default Concursos
