import React from 'react'
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react'
import PropTypes from 'prop-types'

const mapStyle = {
  minWwidth: '200px',
  minHeight: '200px',
  maxHeight: '300px',
}

const MapMaker = (props) => {
  const { lat, lng } = props
  return (
    <Map
      google={props.google}
      zoom={16}
      style={mapStyle}
      initialCenter={{ lat: lat, lng: lng }}
    >
      <Marker position={{ lat: lat, lng: lng }} />
    </Map>
  )
}

MapMaker.propTypes = {
  lat: PropTypes.string,
  lng: PropTypes.string,
}

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_API_KEY_MAPS,
  v: '3',
})(MapMaker)
