import { methodGet, methodPost, methodDelete, methodPatch } from './methods'
import { API_INFO } from '../constans'

export const getLists = async (query) => {
  const url = API_INFO + `products/listas/?${query}`
  return methodGet(url)
}

export const createList = async (params) => {
  const url = API_INFO + `products/listas/`
  return methodPost(url, params)
}

export const editItemList = async (url, params) => {
  const urlComplete = API_INFO + `products/listas/${url}/`
  return methodPatch(urlComplete, params)
}

export const deleteList = async (url) => {
  const urlComplete = API_INFO + `products/listas/${url}/`
  return methodDelete(urlComplete)
}

export const changeOrderList = async (payload) => {
  const url = API_INFO + `products/listas/change-order/`
  return methodPost(url, payload)
}
