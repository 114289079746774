import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'

// layouts
import DefaultLayout from '../layouts/defaultLayout'

// pages
import HomePage from '../pages/home'
import ListPage from '../pages/list'
import BookDetail from '../pages/detail/index'
import OrderDetail from '../pages/orders/detail'
import CoffeeShopPage from '../pages/cafeteria'
import GalleryPage from '../pages/gallery'
import EventsPage from '../pages/event'
import EventDetailPage from '../pages/event/detail'
import ProfilePage from '../pages/profile'
import Cart from '../pages/cart'
import OkPayment from '../pages/cart/ok_payment'
import ErrorPayment from '../pages/cart/error_payment'
import DynamicFilter from '../pages/dynamicFilter/'
import Nosotros from '../pages/Nosotros/Nosotros'
import Legals from '../pages/legals'
import Refund from '../pages/refund'
import Conditions from '../pages/conditions'
import SearchAdvanced from '../pages/searchAdvanced/'
import FavoritesPage from '../pages/favorites'
import Pedidos from '../pages/orders'
import { PostList } from '../pages/blog'
import Post from '../pages/blog/post'
import RecoverPage from '../pages/recover'
import FeaturedMattersPage from '../pages/matters/featuredMatters'
import Cafeteria from '../pages/cafeteria'
import NotFound from '../pages/404'

//intranet
import Intranet from '../pages/intranet'
import Login from '../pages/intranet/Login'
import IntranetLayout from '../layouts/intranetLayout'
import Clientes from '../pages/intranet/Clientes'
import Concursos from '../pages/intranet/Concursos'
import Productos from '../pages/intranet/Productos'
import Autores from '../pages/intranet/Autores'
import PedidosIntranetPage from '../pages/intranet/Pedidos'
import Suscriptores from '../pages/intranet/Suscriptores'
import GestionarPosts from '../pages/intranet/GestionarPosts'
import SliderClientes from '../pages/intranet/SliderClientes'
import Galeria from '../pages/intranet/Galeria'
import Merchandising from '../pages/intranet/Merchandising'
import Slider from '../pages/intranet/Slider'
import Listas from '../pages/intranet/Listas'
import Editoriales from '../pages/intranet/Editoriales'
import PreparacionPedidos from '../pages/intranet/PreparacionPedidos'
import EditClient from '../pages/intranet/Clientes/actualizar'
import Budget from '../pages/budget'
import BudgetList from '../pages/budgetList'
import CreateClient from '../pages/intranet/Clientes/crear'
import ModifyBudget from '../pages/modifyBudget'

const MainRoute = () => {
  const intranetsubRoutes = [
    {
      path: '',
      Component: Intranet,
    },
    {
      path: 'concursos',
      Component: Concursos,
    },
    {
      path: 'clientes',
      Component: Clientes,
    },
    {
      path: 'clientes/crear',
      Component: CreateClient,
    },
    {
      path: 'clientes/actualizar/:id',
      Component: EditClient,
    },
    {
      path: 'productos',
      Component: Productos,
    },
    {
      path: 'autores',
      Component: Autores,
    },
    {
      path: 'pedidos',
      Component: PedidosIntranetPage,
    },
    {
      path: 'suscriptores',
      Component: Suscriptores,
    },
    {
      path: 'gestionarposts',
      Component: GestionarPosts,
    },
    {
      path: 'sliderClientes',
      Component: SliderClientes,
    },
    {
      path: 'galeria',
      Component: Galeria,
    },
    {
      path: 'merchandising',
      Component: Merchandising,
    },
    {
      path: 'slider',
      Component: Slider,
    },
    {
      path: 'listas',
      Component: Listas,
    },
    {
      path: 'editoriales',
      Component: Editoriales,
    },
    {
      path: 'preparacionPedidos',
      Component: PreparacionPedidos,
    },
  ]

  const zoneUserRoutes = [
    {
      path: '',
      Component: HomePage,
    },
    {
      path: 'lista/:nombre',
      Component: ListPage,
    },
    {
      path: 'materia/:matterName',
      Component: ListPage,
    },
    {
      path: 'autor/:slug',
      Component: ListPage,
    },
    {
      path: 'editorial/:slug',
      Component: ListPage,
    },
    {
      path: 'materias-destacadas',
      Component: FeaturedMattersPage,
    },
    {
      path: 'cafeteria',
      Component: CoffeeShopPage,
    },
    {
      path: 'galeria',
      Component: GalleryPage,
    },
    {
      path: 'eventos',
      Component: EventsPage,
    },
    {
      path: 'eventos/:id',
      Component: EventDetailPage,
    },
    {
      path: 'carrito',
      Component: Cart,
    },
    {
      path: 'pago-ok',
      Component: OkPayment,
    },
    {
      path: 'pago-error',
      Component: ErrorPayment,
    },
    {
      path: 'perfil',
      protected: true,
      Component: ProfilePage,
    },
    {
      path: 'presupuestos',
      protected: true,
      Component: BudgetList,
    },
    {
      path: 'filtro/:type/',
      Component: DynamicFilter,
    },
    {
      path: 'productos/:type/:url/',
      Component: BookDetail,
    },
    {
      path: 'nosotros',
      Component: Nosotros,
    },
    {
      path: 'aviso-legal',
      Component: Legals,
    },
    {
      path: 'devoluciones-y-anulaciones',
      Component: Refund,
    },
    {
      path: 'condiciones',
      Component: Conditions,
    },
    {
      path: 'buscador-avanzado',
      Component: SearchAdvanced,
    },
    {
      path: 'favoritos',
      Component: FavoritesPage,
    },
    {
      path: 'blog',
      Component: PostList,
    },
    {
      path: 'blog/post/:year/:month/:day/:slug',
      Component: Post,
    },
    {
      path: 'recuperar-contrasena/:uid/:token',
      Component: RecoverPage,
    },
    {
      path: 'mis-pedidos',
      protected: true,
      Component: Pedidos,
    },
    {
      path: 'mis-pedidos/:pk/',
      protected: true,
      Component: OrderDetail,
    },
    {
      path: 'cafeteria',
      Component: Cafeteria,
    },
    {
      path: 'presupuesto',
      Component: Budget,
    },
    {
      path: 'editarpresupuesto/:id',
      Component: ModifyBudget,
    },
    {
      path: '*',
      Component: NotFound,
    },
  ]

  return (
    <Router>
      <Routes>
        <Route path="/" element={<DefaultLayout />}>
          {zoneUserRoutes.map(({ path, Component }, index) => (
            <Route
              index={path === ''}
              key={index}
              path={path}
              element={<Component />}
            />
          ))}
        </Route>
        <Route exact path="/intranet/login" element={<Login />} />
        <Route path="/intranet/" element={<IntranetLayout />}>
          {intranetsubRoutes.map(({ path, Component }, index) => (
            <Route
              index={path === ''}
              key={'intranet_' + index}
              path={path}
              element={<Component />}
            />
          ))}
        </Route>
      </Routes>
    </Router>
  )
}

export default MainRoute
