import React, { useState, useEffect } from 'react'
import Button from 'react-bootstrap/Button'
import { FormGroup } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
//api
import { createClient } from '../../../../api/clients'
//components
import CustomMultipleInput from '../../../../components/custommultipleInput/index'

const CreateClient = () => {
  const {
    register,
    reset,
    formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues: {
      codigo: '',
      cif: '',
      nombre_fiscal: '',
      empresa: '',
      email: '',
    },
  })

  const resetData = {
    codigo: '',
    nombre_fiscal: '',
    empresa: '',
    email: '',
    cif: '',
  }
  const onSubmit = async (values) => {
    const data = {
      codigo: values.codigo,
      nombre_fiscal: values.nombre_fiscal,
      empresa: values.empresa,
      email: values.email,
      cif: values.cif,
    }

    try {
      const response = await createClient(data)
      if (response.status === 201) {
        reset(resetData)
      }
    } catch (error) {
      console.log(error)
    }
  }
  return (
    <div>
      <h1 className="mt-3">Nuevo cliente</h1>
      <CustomMultipleInput onSubmit={handleSubmit(onSubmit)}>
        <div className="d-flex flex-wrap ml-5 mt-5">
          <FormGroup className="d-flex flex-column width-40">
            <label>Codigo</label>
            <input
              type="text"
              className="input-round"
              {...register('codigo', {
                minLength: {
                  value: 4,
                  message: 'El codigo debe tener mínimo 4 caracteres',
                },
                maxLength: {
                  value: 15,
                  message: 'El codigo debe tener máximo 15 caracteres',
                },
              })}
            />
            {errors.codigo && (
              <small className="text-danger">{errors.codigo.message}</small>
            )}
          </FormGroup>
          <FormGroup className="d-flex flex-column width-40 margin-left-15">
            <label>Nombre fiscal</label>
            <input
              type="text"
              className="input-round"
              {...register('nombre_fiscal', {
                required: {
                  value: true,
                  message: 'Campo obligatorio',
                },
                minLength: {
                  value: 3,
                  message: 'El nombre fiscal debe tener mínimo 4 caracteres',
                },
                maxLength: {
                  value: 9,
                  message: 'El nombre fiscal debe tener máximo 9 caracteres',
                },
              })}
            />
            {errors.nombre_fiscal && (
              <small className="text-danger">
                {errors.nombre_fiscal.message}
              </small>
            )}
          </FormGroup>
          <FormGroup className="d-flex flex-column width-40">
            <label>Empresa</label>
            <input
              type="text"
              className="input-round"
              {...register('empresa', {})}
            />
          </FormGroup>
          <FormGroup className="d-flex flex-column width-40 margin-left-15">
            <label>Email</label>
            <input
              type="email"
              className="input-round"
              {...register('email', {
                validate: {
                  matchPattern: (v) =>
                    /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
                    'Debe ser un correo electrónico válido',
                },
              })}
            />
            {errors.email && (
              <small className="text-danger">{errors.email.message}</small>
            )}
          </FormGroup>

          <FormGroup className="d-flex flex-column width-40">
            <label>Cif</label>
            <input
              type="text"
              className="input-round"
              {...register('cif', {
                minLength: {
                  value: 3,
                  message: 'El cif debe tener mínimo 4 caracteres',
                },
                maxLength: {
                  value: 9,
                  message: 'El cif debe tener máximo 9 caracteres',
                },
              })}
            />
            {errors.cif && (
              <small className="text-danger">{errors.cif.message}</small>
            )}
          </FormGroup>
        </div>
        <div className="mr-5 d-flex justify-content-end">
          <Button variant="secondary" type="submit">
            Enviar
          </Button>
        </div>
      </CustomMultipleInput>
    </div>
  )
}

export default CreateClient
