import { Form } from 'react-bootstrap'
import React from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'

export const Autocomplete = ({
  asyncFetch,
  label,
  onSelect,
  inputValue,
  placeholder,
  isDisabled,
  id,
  showError = false,
  errorMessage,
  ...props
}) => {
  return (
    <Form.Group>
      {label && <Form.Label>{label}</Form.Label>}
      <Select
        isDisabled={isDisabled}
        value={inputValue}
        options={asyncFetch}
        onChange={(option) => {
          onSelect(option)
        }}
        placeholder={placeholder}
        noOptionsMessage={() => 'Sin Resultados'}
        //defaultValue={asyncFetch ? asyncFetch[0] : null}
        inputId={id}
        {...props}
      />
      {showError && (
        <div className="invalid-feedback" style={{ display: 'block' }}>
          {errorMessage}
        </div>
      )}
    </Form.Group>
  )
}

Autocomplete.propTypes = {
  asyncFetch: PropTypes.array,
  label: PropTypes.string,
  onSelect: PropTypes.func,
  inputValue: PropTypes.object,
  placeholder: PropTypes.string,
  isDisabled: PropTypes.bool,
  id: PropTypes.string,
  showError: PropTypes.bool,
  errorMessage: PropTypes.string,
}
