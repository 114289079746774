import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { AiFillDelete } from 'react-icons/ai'
import { FaEdit } from 'react-icons/fa'
import Lightbox from 'react-awesome-lightbox'
import 'react-awesome-lightbox/build/style.css'

import Button from '../../../components/buttons/ButtonCustom'
import CustomTable from '../../../components/table'
import CreateGalleryModal from './CreateModal'

import { deleteGallery, getGalleryPaginated } from '../../../api/company'
import EditGalleryModal from './editModal'

const Galeria = () => {
  const [dataGallery, setDataGallery] = useState({})
  const [opnePreviewImage, setOpnePreviewImage] = useState(false)
  const [previewImage, setPreviewImage] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [isOpenCreateModal, setIsOpenCreateModal] = useState(false)
  const [isOpenEditModal, setisOpenEditModal] = useState(false)
  const [gallery, setGallery] = useState({})
  const [page, setPage] = useState(1)

  const handleGallery = async () => {
    setIsLoading(true)
    const { data, status } = await getGalleryPaginated({ page, page_size: 5 })
    if (status === 200) {
      setDataGallery(data)
    }
    setIsLoading(false)
  }

  const doDeleteGallery = async (id) => {
    const { status } = await deleteGallery(id)
    if (status === 204) {
      toast.success('Eliminado correctamente!')
      handleGallery()
    } else {
      toast.error('Algo ha ido mal, intentalo nuevamente')
    }
  }

  const doEditGallery = (data, id) => {
    setGallery({
      id: id,
      data,
    })
    setisOpenEditModal(true)
  }

  useEffect(() => {
    handleGallery()
  }, [page])

  return (
    <div>
      <div className="d-flex justify-content-between">
        <h1>Galeria</h1>
        <Button onClick={() => setIsOpenCreateModal(true)}>
          Crear galería
        </Button>
      </div>
      <CustomTable
        isLoading={isLoading}
        cols={['id', 'Categoría', 'Imagen', 'Acciones']}
        page={page}
        changePage={setPage}
        totalPages={dataGallery?.total_pages}
      >
        {dataGallery?.results?.map((item) => (
          <tr key={item.id}>
            <td>{item.id}</td>
            <td>{item.category}</td>
            <td className="text-center">
              <img
                src={item.image}
                alt=""
                height={100}
                onClick={() => {
                  setPreviewImage(item.image)
                  setOpnePreviewImage(true)
                }}
              />
            </td>
            <td className="text-center">
              <FaEdit
                className="cursor-pointer"
                color="gray"
                onClick={() => {
                  doEditGallery(
                    { category: item.category, image: item.image },
                    item.id
                  )
                }}
              />
              <AiFillDelete
                className="cursor-pointer ml-5"
                onClick={() => {
                  doDeleteGallery(item.id)
                }}
              />
            </td>
          </tr>
        ))}
      </CustomTable>
      {opnePreviewImage && (
        <Lightbox
          image={previewImage}
          onClose={() => setOpnePreviewImage(false)}
        />
      )}
      <CreateGalleryModal
        onSuccess={handleGallery}
        isOpen={isOpenCreateModal}
        setIsOpen={setIsOpenCreateModal}
      />
      <EditGalleryModal
        onSuccess={handleGallery}
        isOpen={isOpenEditModal}
        setIsOpen={setisOpenEditModal}
        dataGallery={gallery?.data}
        idGallery={gallery?.id}
      />
    </div>
  )
}

export default Galeria
