import {
  methodPut,
  methodGet,
  methodDelete,
  methodPost,
  methodPostForm,
  methodPatch,
} from './methods'
import { API_INFO } from '../constans'

export const getListClients = async (query) => {
  const url = API_INFO + `user/clients/?${query}`
  return methodGet(url)
}

export const getDetailClient = async (id) => {
  const url = API_INFO + `user/clients/${id}/`
  return methodGet(url)
}

export const createClient = async (params) => {
  const url = API_INFO + `user/clients/`
  return methodPost(url, params)
}
export const editClient = async (id, params) => {
  const completeUrl = API_INFO + `user/clients/${id}/`
  return methodPut(completeUrl, params)
}

export const deleteClient = async (id) => {
  const url = API_INFO + `user/clients/${id}/`
  return methodDelete(url)
}

export const getListUsersClient = async (query) => {
  const url = API_INFO + `user/library-users/?${query}`
  return methodGet(url)
}

export const createUserClient = async (params) => {
  const url = API_INFO + `user/library-users/`
  return methodPost(url, params)
}

export const readUserClient = (id) => {
  const url = API_INFO + `user/library-users/${id}/`
  return methodGet(url)
}

export const deleteUserClient = (id) => {
  const url = API_INFO + `user/library-users/${id}/`
  return methodDelete(url)
}

export const editUserClient = (id, params) => {
  const url = API_INFO + `user/library-users/${id}/`
  return methodPatch(url, params)
}
