import React from 'react'
import { Row, Col, Image } from 'react-bootstrap'
import { FaRegCalendarAlt } from 'react-icons/fa'
import { MdLocationOn } from 'react-icons/md'
import { AiOutlineIdcard } from 'react-icons/ai'
import PropTypes from 'prop-types'
import '../blog/blog.scss'
import { truncate } from '../../helpers'

const ItemEvent = (props) => {
  const { start_date, finish_date, cover, description, title, address, id } =
    props.event
  return (
    <div className="cardBlog">
      <div
        className="imageCardBlog"
        style={{
          backgroundImage: `url(${cover})`,
          height: 150,
          width: 'none',
        }}
      ></div>
      <Row>
        <Col md={12} className="text-left text-bold">
          <h6>{title} </h6>
          <FaRegCalendarAlt />{' '}
          <span className="text-bold text-small">
            {start_date} - {finish_date}{' '}
          </span>
          <br />
          <MdLocationOn />{' '}
          <span className="text-bold text-small">{address} </span>
          <br />
          <span className="text-small">{truncate(description, 130)} </span>
        </Col>
        <Col className="text-secondary text-right">
          <AiOutlineIdcard className="text-small" />
          {'   '}
          <a
            className="text-secondary link-more text-small"
            href={`/eventos/${id}`}
          >
            Ver más
          </a>
        </Col>
      </Row>
    </div>
  )
}

ItemEvent.propTypes = {
  event: PropTypes.object,
}

export default ItemEvent
