import React, { useEffect, useState } from 'react'
import { Row, Col } from 'react-bootstrap'

// own components
import ListsHome from './liststHome'
import Slider from '../../components/slider'
import EventHome from './events'
import Head from '../../components/metatags_helmet/metatags'

import { companySelector, setLogo } from '../../slices/company'
import { useSelector, useDispatch } from 'react-redux'

//api
import { getCompany } from '../../api/company'
import { slidersClient } from '../../api/products'
import { getPosts } from '../../api/blog'
import { getEvents } from '../../api/company'

const Home = () => {
  const [company, setCompany] = useState({})
  const [sliders, setSliders] = useState([])
  const [events, setEvents] = useState([])
  const [blogs, setBlogs] = useState([])
  const dispatch = useDispatch()

  const { has_slider, has_events, has_blog, name } = useSelector(companySelector)

  const description = `Una amplia selección de contenido te está esperando en ${name}. Comprar desde tu casa nunca fué tan fácil.`

  async function loadEvents() {
    const { status, data } = await getEvents({ page_size: 2 })
    if (status === 200) {
      setEvents(data.results)
    }
  }

  async function loadBlogs() {
    const { status, data } = await getPosts({ page_size: 4 })
    if (status === 200) {
      setBlogs(data.results)
    }
  }

  async function getSlidersClient() {
    const rs = await slidersClient()
    if (rs.status === 200) {
      setSliders(rs.data)
    }
  }

  const getCompanyInfo = async () => {
    try {
      const rs = await getCompany()
      if (rs.status === 200) {
        const { data } = rs
        setCompany(data)
        dispatch(setLogo(data))
      }
    } catch (error) {}
  }

  useEffect(() => {
    getCompanyInfo()
  }, [])

  useEffect(() => {
    if (has_slider) {
      getSlidersClient()
    }
  }, [has_slider])

  useEffect(() => {
    if (has_events) {
      loadEvents()
    }
  }, [has_events])

  useEffect(() => {
    if (has_blog) {
      loadBlogs()
    }
  }, [has_blog])

  return (
    <div className="container p-rel">
      <Row>
        <Col lg={9}>
          {has_slider && <Slider elements={sliders} />}
          <ListsHome />
          <Head description={description} title="" home={true} />
        </Col>
        <Col lg={3}>
          <EventHome blogs={blogs} events={events} />
        </Col>
      </Row>
    </div>
  )
}

export default Home
