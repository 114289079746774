import React from 'react'
import { Card } from 'react-bootstrap'
import PropTypes from 'prop-types'

import '../../assets/css/card.scss'

const CardCustom = (props) => {
  return (
    <Card {...props}>
      <Card.Body>
        <Card.Title className="text-center mt-3">{props.title}</Card.Title>
        <div className="card-text">{props.children}</div>
        {props.footer}
      </Card.Body>
    </Card>
  )
}

CardCustom.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node.isRequired,
  footer: PropTypes.node,
  classNameTitle: PropTypes.string,
}

export default CardCustom
