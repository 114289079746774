import React from 'react'
import { Row, Col, Container } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { companySelector } from '../../slices/company.js'

const Refund = () => {
  const { refundText } = useSelector(companySelector)

  return (
    <Container>
      <Row>
        <Col className="title" md={12}>
          <h2>Devoluciones y anulaciones</h2>
          <span className="divider-title"></span>
          <div
            className="legal"
            dangerouslySetInnerHTML={{ __html: refundText }}
          ></div>
        </Col>
      </Row>
    </Container>
  )
}

export default Refund
