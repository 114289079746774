import {
  methodPut,
  methodGet,
  methodDelete,
  methodPost,
  methodPostForm,
} from './methods'
import { API_INFO } from '../constans'

export async function getDetailProduct(id, query) {
  const url = API_INFO + 'products/products/' + id + '/'
  return methodGet(url, query)
}

export async function editProduct(id, query) {
  const url = API_INFO + `products/products/${id}/`
  return methodPut(url, query)
}

export async function getRelatedProduct(id, query) {
  const url = API_INFO + `products/products/${id}/get_releated_products/`
  return methodGet(url, query)
}

export async function getPublishedCountries(query) {
  const url = API_INFO + `products/products/get_published_countries/`
  return methodGet(url, query)
}

export async function getLanguageProducts(query) {
  const url = API_INFO + `products/products/get_lan_products/`
  return methodGet(url, query)
}

export async function searchProduct(query) {
  // new version (Elasticsearch), to replace searchFilterProduct
  const url = API_INFO + `products/products/search_products/`
  return methodGet(url, query)
}

export async function searchFilterProduct(query) {
  const url = API_INFO + `products/products/filter_products/`
  return methodGet(url, query)
}

export async function searchFilterProductByTitle(query) {
  const url = API_INFO + `products/products/filter_products/?titulo=${query}`
  return methodGet(url, query)
}

export async function listAudiencia() {
  const url = API_INFO + 'products/products/get_clasification_products/'
  return methodGet(url, {})
}

export const slidersClient = () => {
  const url = API_INFO + 'products/sliders-client/get-active-sliders/'
  return methodGet(url, {})
}

export const getRangeDatePublished = () => {
  const url = API_INFO + 'products/products/get_range_fecha_public/'
  return methodGet(url, {})
}

export async function removeItemFav(productUrl) {
  const url = API_INFO + `products/products/${productUrl}/remove_saved_user/`
  return methodDelete(url, {})
}

export async function getDetailAuthor(slug) {
  const url = API_INFO + `products/authors/${slug}`
  return methodGet(url, {})
}

export async function getProductsByAuthor(slug, page) {
  const url = API_INFO + `products/authors/${slug}/get-products?page=${page}`
  return methodGet(url, {})
}

export async function getDetailEditorial(slug) {
  const url = API_INFO + `products/editorials/${slug}/`
  return methodGet(url, {})
}

export async function getProductsByEditorial(slug, page) {
  const url = API_INFO + `products/editorials/${slug}/get-products?page=${page}`
  return methodGet(url, {})
}

export async function getStockProviders(id) {
  const url = API_INFO + `products/products/${id}/get_stock_cegal`
  return methodGet(url, {})
}

export const getListEditorial = async (query) => {
  const url = API_INFO + `products/editorials/?${query}`
  return methodGet(url)
}

export const editNameEditorial = async (url, params) => {
  const completeUrl = API_INFO + `products/editorials/${url}/`
  return methodPut(completeUrl, params)
}

export const deleteEditorial = async (url) => {
  const urlComplete = API_INFO + `products/editorials/${url}/`
}

export const getListProducts = async (payload) => {
  const urlComplete = API_INFO + `products/products/`
  return methodGet(urlComplete, payload)
}

export const searchProducts = async (slug) => {
  const urlComplete = API_INFO + `products/products/search_products/?${slug}`
  return methodGet(urlComplete)
}

export const getSlider = async () => {
  const urlComplete = API_INFO + `products/elementos_slider/`
  return methodGet(urlComplete)
}

export const editSlider = async (id, params) => {
  const completeUrl = API_INFO + `products/elementos_slider/${id}/`
  return methodPut(completeUrl, params)
}

export const createSlider = async (params) => {
  const completeUrl = API_INFO + `products/elementos_slider/`
  return methodPostForm(completeUrl, params)
}

export const getLibraries = async (query) => {
  const urlComplete = API_INFO + `intranet/libraries/?${query}`
  return methodGet(urlComplete)
}

export const importDilve = async (params) => {
  const url = API_INFO + `products/products/import-book-delvi/`
  return methodPost(url, params)
}
