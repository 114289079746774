import React, { useState } from 'react'
import PropTypes from 'prop-types'

const Image = (props) => {
  const [src, setSrc] = useState(props.src)
  const [errored, setErrored] = useState(false)

  const onError = () => {
    if (!errored) {
      setSrc(props.fallbackSrc)
      setErrored(true)
    }
  }

  return (
    <img
      alt="imag"
      src={src}
      onError={onError}
      width={props.width}
      height={props.height}
    />
  )
}

Image.propTypes = {
  src: PropTypes.string,
  fallbackSrc: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
}

export default Image
