import React, { useEffect, useState } from 'react'
import { AiFillEye, AiFillDelete } from 'react-icons/ai'
import Button from 'react-bootstrap/Button'
import { FaEdit } from 'react-icons/fa'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
//api
import { getListUsersClient } from '../../api/clients'
//components
import CustomTable from '../table'
import IntranetPagination from '../../pages/intranet/Pagination/index'
import ModalCreateUserClient from '../modalCreateUserClient/index'
//custom hook
import { useQuery } from '../../utils/customHooks/query'
import CustomMultipleInput from '../custommultipleInput'
import { InputCustom } from '../input'
import ModalReadUserClient from './modalRead'
import ModalDeleteUserClient from './modalDelete'
import ModalEditUserClient from './modalEdit/index'

const ListUsers = ({ client }) => {
  const navigate = useNavigate()
  const searchParams = useQuery()
  const page = searchParams.get('page') || 1
  const pageSize = 10
  const [usersClient, setUsersClient] = useState([])
  const [totalPages, setTotalPages] = useState(null)
  const [noResults, setNoResults] = useState(false)
  const [filterUser, setFilterUser] = useState({
    username: '',
    email: '',
  })
  const [isActiveSearch, setIsActiveSearch] = useState(false)
  const [idUser, setIdUser] = useState()
  const [user, setUser] = useState({})
  const [openModal, setOpenModal] = useState(false)
  const [openModalDelete, setOpenModalDelete] = useState(false)
  const [openModalCreate, setOpenModalCreate] = useState(false)
  const [openModalEdit, setOpenModalEdit] = useState(false)

  const query = {
    client,
    username: filterUser.username,
    email: filterUser.email,
    page: page,
    page_size: 10,
  }

  const searchQuery = new URLSearchParams(query)
  const queryString = searchQuery.toString()

  const listUserClient = async () => {
    try {
      const { data, status } = await getListUsersClient(queryString)
      const { results } = data
      if (status === 200 && results.length > 0) {
        setTotalPages(Math.ceil(data.count / pageSize))
        setUsersClient(results)

        setNoResults(false)
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    const { username, email } = filterUser
    if (username !== '' || email !== '') {
      handleSubmit()
    } else {
      listUserClient()
    }
  }, [page])

  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      const { data } = await getListUsersClient(queryString)

      if (data.results.length > 0) {
        setUsersClient(data?.results)
        setNoResults(false)
        setTotalPages(Math.ceil(data.count / pageSize))
      } else {
        setUsersClient([])
        setNoResults(true)
      }
    } catch (error) {
      console.log(error)
    }

    navigate(`?${queryString}`)
    setIsActiveSearch(true)
  }

  const handleClear = (e) => {
    e.preventDefault()
    setFilterUser({})
    navigate(`/intranet/clientes/actualizar/${client}`)
  }

  const openIsModal = (id, setOpenModal, user) => {
    if (user.id === id) {
      setOpenModal(true)
      setIdUser(id)
      setUser(user)
    }
  }
  const colsUsers = ['Usuario', 'Biblioteca', 'Acciones']

  return (
    <div>
      <div className="d-flex w-100 m-3">
        <div className="flex-50">
          <h3 className="text-center">Usuarios</h3>
        </div>
        <div className="flex-1 mr-5">
          <Button variant="secondary" onClick={() => setOpenModalCreate(true)}>
            Crear usuario
          </Button>
        </div>
      </div>

      <CustomMultipleInput
        className="d-flex justify-content-end mr-3"
        onSubmit={handleSubmit}
      >
        <InputCustom
          placeholder="Buscar por nombre de usuario"
          type="text"
          required="true"
          value={filterUser.username || ''}
          onChange={(e) =>
            setFilterUser({
              ...filterUser,
              username: e.target.value,
            })
          }
          className="height-45 mt-3 form-control input-search"
        />
        <InputCustom
          placeholder="Buscar por email"
          type="email"
          value={filterUser.email || ''}
          onChange={(e) =>
            setFilterUser({
              ...filterUser,
              email: e.target.value,
            })
          }
          className="height-45 margin-left-15 mt-3 form-control input-search"
        />
        <div className="d-flex justify-content-center align-items-center mt-2">
          <Button variant="secondary" type="submit" className="height-39 ml-4">
            Buscar
          </Button>
          <button
            onClick={handleClear}
            className=" cursor-pointer height-39 margin-left-15"
          >
            Limpiar url
          </button>
        </div>
      </CustomMultipleInput>

      <CustomTable cols={colsUsers} noResults={noResults}>
        {usersClient &&
          usersClient?.map((userClient) => {
            return (
              <tr key={userClient.id}>
                <td className="text-center">{userClient.user}</td>
                <td className="text-center">{userClient.biblioteca}</td>
                <td className="text-center">
                  <AiFillEye
                    className="mr-5 cursor-pointer"
                    onClick={() =>
                      openIsModal(userClient.id, setOpenModal, userClient)
                    }
                  />

                  <FaEdit
                    className="cursor-pointer"
                    color="gray"
                    onClick={() =>
                      openIsModal(userClient.id, setOpenModalEdit, userClient)
                    }
                  />

                  <AiFillDelete
                    className="cursor-pointer ml-5"
                    onClick={() =>
                      openIsModal(userClient.id, setOpenModalDelete, userClient)
                    }
                  />
                </td>
              </tr>
            )
          })}
      </CustomTable>
      <ModalCreateUserClient
        listUserClient={listUserClient}
        openModal={openModalCreate}
        setOpenModal={setOpenModalCreate}
      />
      <ModalReadUserClient
        openModal={openModal}
        setOpenModal={setOpenModal}
        idUser={idUser}
      />
      <ModalDeleteUserClient
        openModalDelete={openModalDelete}
        setOpenModalDelete={setOpenModalDelete}
        idUser={idUser}
        listUserClient={listUserClient}
      />
      <ModalEditUserClient
        openModalEdit={openModalEdit}
        setOpenModalEdit={setOpenModalEdit}
        user={user}
        listUserClient={listUserClient}
      />
      <IntranetPagination
        page={parseInt(page)}
        pages={totalPages}
        isActiveSearch={isActiveSearch}
        queryParams={queryString}
      />
    </div>
  )
}

ListUsers.propTypes = {
  client: PropTypes.number,
}

export default ListUsers
