import React, { useState, useEffect } from 'react'
import { Row, Col, Card, Container, Table } from 'react-bootstrap'
//router
import { useParams } from 'react-router-dom'
//api
import { getDetailOrder } from '../../api/orders'

//styles
//import './detailItem.scss'

import { COMPANY } from '../../constans'

function OrderDetail() {
  const [orderDetail, setOrderDetail] = useState(null)
  let { pk } = useParams()

  useEffect(() => {
    window.document.title = 'Detalle del Pedido | ' + COMPANY
  }, [orderDetail])

  const handleDetailOrder = async () => {
    const rs = await getDetailOrder(pk)
    if (rs.status === 200) {
      const { data } = rs
      setOrderDetail(data)
    }
  }

  useEffect(() => {
    handleDetailOrder()
  }, [pk])

  return (
    <div className="container">
      <Container fluid>
        {orderDetail ? (
          <Card>
            <Card.Body>
              <Card.Title>Detalle del Pedido</Card.Title>
              <Card.Subtitle className="mb-2 text-muted">
                {orderDetail.cantidad_total} items
              </Card.Subtitle>
              <Row className="m-2">
                <Col md={2} lg={2}>
                  <p>Fecha: {orderDetail.fecha}</p>
                </Col>
                <Col md={2} lg={2}>
                  <p>Estado: {orderDetail.status}</p>
                </Col>
                <Col md={4} lg={4}>
                  <p>Datos de Facturación:</p>
                  <span>
                    A nombre de: {orderDetail.billing_address.name}{' '}
                  </span>{' '}
                  <br />
                  <span style={{ color: 'gray' }}>
                    {orderDetail.billing_address.address}
                  </span>{' '}
                  <br />
                  <span style={{ color: 'gray' }}>
                    {orderDetail.billing_address.postal_code +
                      ' ' +
                      orderDetail.billing_address.locality}
                    ,{' '}
                    {orderDetail.billing_address.province +
                      ' - ' +
                      orderDetail.billing_address.country}
                  </span>
                </Col>
                <Col md={4} lg={4}>
                  <p>Datos de Envío:</p>
                  {orderDetail.store_pickup ? (
                    <>
                      <span>Para recoger en tienda</span> <br />
                    </>
                  ) : (
                    <>
                      <span>
                        A nombre de: {orderDetail.shipping_address.name}{' '}
                      </span>{' '}
                      <br />
                      <span style={{ color: 'gray' }}>
                        {orderDetail.shipping_address.address}
                      </span>{' '}
                      <br />
                      <span style={{ color: 'gray' }}>
                        {orderDetail.shipping_address.postal_code +
                          ' ' +
                          orderDetail.shipping_address.locality}
                        ,{' '}
                        {orderDetail.shipping_address.province +
                          ' - ' +
                          orderDetail.shipping_address.country}
                      </span>
                    </>
                  )}
                </Col>
              </Row>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>EAN</th>
                    <th>Nombre</th>
                    <th>Cantidad</th>
                    <th>Precio</th>
                    <th>IVA</th>
                    <th>Total</th>
                  </tr>
                </thead>
                <tbody>
                  {orderDetail.items.map((item, i) => (
                    <tr>
                      <td>{item.ean}</td>
                      <td>{item.nombre}</td>
                      <td>{item.cantidad}</td>
                      <td>{item.precio_venta} €</td>
                      <td>{item.iva_value.toFixed(2)} €</td>
                      <td>{item.total.toFixed(2)} €</td>
                    </tr>
                  ))}
                  {!orderDetail.store_pickup && (
                    <tr>
                      <td>Costo de envío:</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>{orderDetail.shipping_cost} €</td>
                    </tr>
                  )}
                  <tr>
                    <td>Total:</td>
                    <td></td>
                    <td>{orderDetail.cantidad_total}</td>
                    <td></td>
                    <td>{orderDetail.iva.toFixed(2)} €</td>
                    <td>{orderDetail.total.toFixed(2)} €</td>
                  </tr>
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        ) : (
          <Row>
            <Col>
              <p>Cargando información</p>
            </Col>
          </Row>
        )}
      </Container>
    </div>
  )
}

export default OrderDetail
