import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import Button from 'react-bootstrap/Button'

//icons
import { FaEdit } from 'react-icons/fa'
import { AiFillDelete } from 'react-icons/ai'
import { BsSearch } from 'react-icons/bs'
import { SiMicrosoftexcel } from 'react-icons/si'

//api
import { getListEditorial } from '../../../api/products'
import { getReportExcel } from '../../../api/editorials'

//custom hook
//components
import CustomInput from '../../../components/customInput'
import CustomTable from '../../../components/table'
import IntranetPagination from '../Pagination'
import ModalEditEditorial from '../../../components/editorialsIntranet/modalEdit/index'
import ModalDeleteEditorial from '../../../components/editorialsIntranet/modalDelete/index'
import ModalMergeEditorial from '../../../components/editorialsIntranet/modalMergeEditorial'

const colsEditorials = ['CIF', 'Nombre Fiscal', 'Distribuidora', 'Acciones']

const Editoriales = () => {
  const navigate = useNavigate()
  //const page = searchParams.get('page') || 1
  const [listsName, setListsName] = useState([])
  const [inputValue, setInputValue] = useState('')
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(null)
  const [editorialList, setEditorialList] = useState({})
  const [isActiveSearch, setIsActiveSearch] = useState(false)
  const [dataEditorial, setDataEditorial] = useState()
  const [openModal, setOpenModal] = useState(false)
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [isModalMergeEditorial, setIsModalMergeEditorial] = useState(false)

  const query = {
    search: inputValue,
    page: page,
    page_size: 10,
  }

  const searchQuery = new URLSearchParams(query)
  const queryString = searchQuery.toString()

  const getEditorials = async () => {
    const { data, status } = await getListEditorial(queryString)
    const { results } = data
    if (status === 200) {
      setListsName(results)
      setEditorialList(results)
    }
  }

  const handleValue = (e) => {
    setInputValue(e.target.value)
  }

  const handleSubmit = async (e) => {
    try {
      const { data } = await getListEditorial(queryString)
      if (data.results.length > 0) {
        setListsName(data?.results)
      } else {
        setListsName([])
      }
    } catch (error) {}
    navigate(`?${queryString}`)
    setIsActiveSearch(true)
  }

  useEffect(() => {
    getEditorials()
  }, [])

  useEffect(() => {
    if (inputValue !== '') {
      handleSubmit()
    } else {
      getEditorials()
    }
  }, [page])

  const handleClear = () => {
    setInputValue('')
    navigate('/intranet/editoriales')
  }

  const openIsModal = (item, id, setOpenModal) => {
    if (item.id === id) {
      setOpenModal(true)
      setDataEditorial(item)
    }
  }

  const handleExportExcel = async (url) => {
    const { status, data } = await getReportExcel(url)
    console.log(data, 'data')
    if (status === 200) {
      const url = window.URL.createObjectURL(new Blob([data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'Presupuesto.xlsx')
      document.body.appendChild(link)
      link.click()
      link.remove()
    } else {
      console.log('error budget excel export')
    }
  }

  return (
    <div>
      <div className="d-flex mt-3 justify-content-between">
        <h1>Editoriales</h1>

        <Button
          variant="secondary"
          type="submit"
          className="height-39 mr-4 "
          onClick={() => setIsModalMergeEditorial(true)}
        >
          Fusionar editorial
        </Button>
      </div>
      <div className="d-flex justify-content-end m-3">
        <CustomInput
          handleSubmit={handleSubmit}
          onChange={(e) => handleValue(e)}
          inputValue={inputValue}
          placeholder="Buscar editorial"
          icon={<BsSearch color="white" />}
          type="search"
        />
        <button onClick={handleClear}>Limpiar url</button>
      </div>
      <CustomTable
        cols={colsEditorials}
        noResults={listsName?.length === 0}
        page={page}
        totalPages={listsName?.total_pages}
        changePage={setPage}
      >
        {editorialList &&
          listsName.map((listName) => {
            return (
              <tr key={listName.id}>
                <td className="text-center">{listName.cif}</td>
                <td className="text-center">{listName.nombre_fiscal}</td>
                <td className="text-center">{listName.url}</td>
                <td className="text-center">
                  <FaEdit
                    className="mr-2 cursor-pointer"
                    onClick={() =>
                      openIsModal(listName, listName.id, setOpenModal)
                    }
                  />
                  <AiFillDelete
                    className="mr-2 cursor-pointer"
                    onClick={() =>
                      openIsModal(listName, listName.id, setIsOpenModal)
                    }
                  />
                  <SiMicrosoftexcel
                    className="cursor-pointer"
                    onClick={() => handleExportExcel(listName.url)}
                  />
                </td>
              </tr>
            )
          })}
      </CustomTable>
      <ModalMergeEditorial
        getEditorials={getEditorials}
        isModalMergeEditorial={isModalMergeEditorial}
        setIsModalMergeEditorial={setIsModalMergeEditorial}
        listsName={listsName}
      />
      <ModalEditEditorial
        dataEditorial={dataEditorial}
        isModal={openModal}
        setIsModal={setOpenModal}
        getEditorials={getEditorials}
      />
      <ModalDeleteEditorial
        isOpen={isOpenModal}
        setIsOpen={setIsOpenModal}
        getEditorials={getEditorials}
        url={dataEditorial?.url}
      />
      <IntranetPagination
        page={parseInt(page)}
        pages={totalPages}
        isActiveSearch={isActiveSearch}
        queryParams={queryString}
      />
    </div>
  )
}
export default Editoriales
