import React, { useEffect, useState, memo } from 'react'
import { FormGroup } from 'react-bootstrap'
import { useForm, Controller } from 'react-hook-form'

import Button from 'react-bootstrap/Button'
//api
import { mergeEditorial } from '../../../api/editorials'
//components
import ModalCustom from '../../modal/index'
import CustomMultipleInput from '../../custommultipleInput/index'
import ButtonsFooter from '../../modal/buttonsFooter'
import { Autocomplete } from '../../autocomplete/index'

const ModalMergeEditorial = memo(
  ({
    getEditorials,
    isModalMergeEditorial,
    setIsModalMergeEditorial,
    listsName,
  }) => {
    const [listEditorial, setListEditorial] = useState([])
    const {
      register,
      reset,
      control,
      formState: { errors },
      handleSubmit,
      watch,
      setValue,
    } = useForm({
      defaultValues: {
        main_editorial: null,
        secondary_editorial: '',
      },
    })

    const optionsEditorials = listsName.map((listName) => ({
      label: listName.nombre_comercial,
      value: listName.id,
    }))

    const resetData = {
      main_editorial: null,
      secondary_editorial: '',
    }

    const onSubmit = async (values) => {
      const data = {
        main_editorial: values.main_editorial,
        secondary_editorial: values.secondary_editorial,
      }

      // e.preventDefault()
      try {
        const { status } = await mergeEditorial(data)
        if (status === 200) {
          getEditorials()
          setIsModalMergeEditorial(false)
          reset(resetData)
        }
      } catch (error) {
        console.log(error)
      }
    }

    const handleClose = () => {
      setIsModalMergeEditorial(false)
      reset(resetData)
    }

    return (
      <ModalCustom
        show={isModalMergeEditorial}
        title="Editar usuario"
        footer={
          <ButtonsFooter
            handleSuccess={handleSubmit(onSubmit)}
            handleClose={handleClose}
          />
        }
      >
        <CustomMultipleInput
          onSubmit={handleSubmit(onSubmit)}
          className="d-flex h-100 flex-wrap justify-content-center p-3"
        >
          <FormGroup className=" m-2 width-40 " controlId="main_editorial">
            <Controller
              control={control}
              name="main_editorial"
              rules={{ required: 'Campo obligatorio' }}
              render={({ field: { onChange, value, name } }) => {
                return (
                  <div>
                    <Autocomplete
                      id="main_editorial"
                      isDisabled={false}
                      label="Editorial"
                      required
                      asyncFetch={optionsEditorials}
                      onSelect={(value) => {
                        setValue('main_editorial', value.value)
                        onChange(value.value)
                      }}
                      placeholder={'Seleccionar'}
                    />
                  </div>
                )
              }}
            />
            {errors.main_editorial && (
              <small className="text-danger">
                {errors.main_editorial.message}
              </small>
            )}
          </FormGroup>
          <FormGroup className=" m-2 width-40" controlId="secondary_editorial">
            <Controller
              control={control}
              name="secondary_editorial"
              rules={{ required: 'Campo obligatorio' }}
              render={({ field: { onChange, value, name } }) => {
                return (
                  <div>
                    <Autocomplete
                      id="secondary_editorial"
                      isDisabled={false}
                      label="A fusionar"
                      required
                      asyncFetch={optionsEditorials}
                      onSelect={(value) => {
                        setValue('secondary_editorial', value.value)
                        onChange(value.value)
                      }}
                      placeholder={'Seleccionar'}
                    />
                  </div>
                )
              }}
            />
            {errors.secondary_editorial && (
              <small className="text-danger">
                {errors.secondary_editorial.message}
              </small>
            )}
          </FormGroup>
        </CustomMultipleInput>
      </ModalCustom>
    )
  }
)

export default ModalMergeEditorial
