import React, { useEffect, useState, useMemo } from 'react'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import PropTypes from 'prop-types'
//api
import { editUserClient, getListUsersClient } from '../../../api/clients'

//components
import ModalCustom from '../../modal'
import { Autocomplete } from '../../autocomplete/index'
import { useQuery } from '../../../utils/customHooks/query'

const ModalEditUserClient = ({
  openModalEdit,
  setOpenModalEdit,
  listUserClient,
  user,
}) => {
  const searchParams = useQuery()
  const [formUser, setFormUser] = useState({})
  const [clients, setClients] = useState([])
  const [validateClient, setValidateClient] = useState(false)
  const [itemClient, setItemClient] = useState({})
  const page = searchParams.get('page') || 1
  const pageSize = 10

  const query = {
    page: page,
    page_size: pageSize,
  }
  const searchQuery = new URLSearchParams(query)
  const queryString = searchQuery.toString()

  const getClients = async () => {
    try {
      const { data } = await getListUsersClient(queryString)
      setClients(data.results)
    } catch (e) {}
  }

  const changeLibrary = (value) => {
    setFormUser({
      ...formUser,
      biblioteca: value.id,
    })
  }

  const changeClient = (value) => {
    setFormUser({
      ...formUser,
      cliente: value.id,
    })
  }

  useEffect(() => {
    getClients()
  }, [])

  useEffect(() => {
    setFormUser({
      username: user.username,
      email: user.email,
      empresa: user.empresa,
      cliente: user.id,
      biblioteca: user.id,
    })
  }, [user])

  const optionsLibrary = clients.map((client) => ({
    label: client.biblioteca,
    value: client.id,
  }))

  const optionsClients = clients.map((client) => ({
    label: client.cliente,
    value: client.id,
  }))

  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      const { status } = await editUserClient(user.id, formUser)
      if (status === 200) {
        listUserClient()
        setOpenModalEdit(false)
      }
      if (user.cliente === '' && status === 400) {
        setValidateClient(true)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleClose = () => {
    setOpenModalEdit(false)
  }

  const filterBy = () => true

  const ButtonFooter = ({ updateUser }) => {
    return (
      <div className="text-center mt-2">
        <Button variant="secondary" onClick={handleClose} className="mr-3">
          Cancelar
        </Button>
        <Button variant="secondary" onClick={updateUser}>
          Aceptar
        </Button>
      </div>
    )
  }

  return (
    <ModalCustom show={openModalEdit} title="Editar usuario">
      <Form onSubmit={handleSubmit}>
        <div className="d-flex mb-2 flex-wrap ml-5">
          <Form.Group className=" m-2 width-40" controlId="username">
            <Form.Label>Nombre de usuario</Form.Label>
            <Form.Control
              type="text"
              value={formUser.username}
              onChange={(e) =>
                setFormUser({
                  ...formUser,
                  username: e.target.value,
                })
              }
            />
          </Form.Group>
          <Form.Group className=" m-2 width-40 " controlId="email">
            <Form.Label>Correo electrónico</Form.Label>
            <Form.Control
              type="email"
              value={formUser.email}
              onChange={(e) =>
                setFormUser({
                  ...formUser,
                  email: e.target.value,
                })
              }
            />
          </Form.Group>
          <Form.Group
            className=" m-2 width-40 margin-left-15"
            controlId="empresa"
          >
            <Form.Label>Empresa</Form.Label>
            <Form.Control
              type="text"
              value={formUser.empresa}
              onChange={(e) =>
                setFormUser({
                  ...formUser,
                  empresa: e.target.value,
                })
              }
            />
          </Form.Group>
          <Form.Group className=" m-2 width-40 " controlId="biblioteca">
            <Autocomplete
              id="biblioteca"
              isDisabled={false}
              label="Biblioteca"
              required
              asyncFetch={optionsLibrary}
              onSelect={(value) => {
                changeLibrary(value)
              }}
              placeholder={'Biblioteca'}
              inputValue={optionsLibrary.filter(
                (option) => option.value === formUser.biblioteca
              )}
            />
          </Form.Group>
          <Form.Group className=" m-2 width-40 " controlId="cliente">
            <Autocomplete
              id="cliente"
              isDisabled={false}
              label="Cliente"
              required
              asyncFetch={optionsClients}
              onSelect={(value) => {
                changeClient(value)
              }}
              placeholder={'Cliente'}
              inputValue={optionsClients.filter(
                (option) => option.value === formUser.cliente
              )}
            />
            {validateClient && (
              <p className="text-danger">
                <small>Campo obligatorio</small>
              </p>
            )}
          </Form.Group>
        </div>
      </Form>
      <ButtonFooter updateUser={handleSubmit} />
    </ModalCustom>
  )
}

ModalEditUserClient.propTypes = {
  openModalEdit: PropTypes.bool,
  setOpenModalEdit: PropTypes.func,
  listUserClient: PropTypes.func,
  user: PropTypes.object,
  idUser: PropTypes.number,
}

export default ModalEditUserClient
