import React, { useState, useEffect } from 'react'
import { FaEdit, FaBook } from 'react-icons/fa'
import { AiFillDelete } from 'react-icons/ai'

import CustomTable from '../../../components/table'
import { getOrdersApi, getOrdersFiltersApi } from '../../../api/orders'
import { Col, Row } from 'react-bootstrap'
import { InputCustom, SelectInputCustom } from '../../../components/input'

const tableColumns = [
  'Cliente',
  'Nº Id',
  'Libreria',
  'Items',
  'Estado',
  'Fecha',
  'Total',
  'Envío',
  'Forma pago',
  'Acciones',
]

const PedidosIntranetPage = () => {
  const [page, setPage] = useState(1)
  const [orders, setOrders] = useState([])
  const [filterOptions, setFilterOptions] = useState({})

  useEffect(() => {
    const getFilterOptions = async () => {
      const { data, status } = await getOrdersFiltersApi()
      if (status === 200) {
        setFilterOptions(data)
      }
    }
    getFilterOptions()
  }, [])

  useEffect(() => {
    const getOrders = async () => {
      const { data, status } = await getOrdersApi({ page: page })
      console.log(status, data)
      if (status === 200) {
        setOrders(data)
      } else {
      }
    }
    getOrders()
  }, [page])

  return (
    <div>
      <h1>Pedidos</h1>
      <Row>
        <Col md={4}>
          <SelectInputCustom placeholder="Medio de pedido" choices={[]} />
        </Col>
        <Col md={4}>
          <SelectInputCustom placeholder="Todos los concursos" choices={[]} />
        </Col>
        <Col md={4}>
          <SelectInputCustom placeholder="Todos los comerciales" choices={[]} />
        </Col>
        <Col md={4}>
          <SelectInputCustom placeholder="Todos los estados" choices={[]} />
        </Col>
        <Col md={4}>
          <InputCustom placeholder="Número de referencia" type={'text'} />
        </Col>
      </Row>
      <CustomTable
        cols={tableColumns}
        noResults={orders?.length === 0}
        page={page}
        totalPages={orders?.total_pages}
        changePage={setPage}
      >
        {orders?.results?.map((order) => (
          <tr key={order.id}>
            <td>{order.clien}</td>
            <td>{order.id}</td>
            <td>{order.library}</td>
            <td>{order.num_elements}</td>
            <td>{order.status?.text}</td>
            <td>{order.fecha}</td>
            <td>{order.total} €</td>
            <td>{order.store_pickup ? 'En tienda' : 'No'}</td>
            <td>{order.payment_method}</td>
            <td className="text-center">
              <FaBook className="mr-3 cursor-pointer" />
              <FaEdit className="mr-3 cursor-pointer" />
              <AiFillDelete className="mr-3 cursor-pointer" />
            </td>
          </tr>
        ))}
      </CustomTable>
    </div>
  )
}

export default PedidosIntranetPage
