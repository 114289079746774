import React, { useEffect, useState } from 'react'

import Form from 'react-bootstrap/Form'

import Button from 'react-bootstrap/Button'
//api
import { getDetailClient, editClient } from '../../../../api/clients'
import ListUsers from '../../../../components/listUsers'

const EditClient = ({ match }) => {
  const {
    params: { id },
  } = match
  const [client, setClient] = useState({})
  const [formClient, setFormClient] = useState({})

  const detailClient = async () => {
    try {
      const { data, status } = await getDetailClient(id)
      if (status === 200) {
        setClient(data)
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    detailClient()
  }, [])

  useEffect(() => {
    setFormClient({
      cif: client.cif,
      codigo: client.codigo,
      email: client.email,
      empresa: client.empresa,
      nombre_fiscal: client.nombre_fiscal,
    })
  }, [client])

  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      const { status } = await editClient(id, formClient)
      if (status === 200) {
        detailClient()
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <div>
      <h2 className="mt-3">Editar Cliente</h2>
      <div>
        <h3 className="text-center">Información</h3>
        <Form onSubmit={handleSubmit}>
          <div className="d-flex p-3 flex-wrap ml-5">
            <Form.Group className=" mt-2 width-40" controlId="cif">
              <Form.Label>Cif</Form.Label>
              <Form.Control
                type="text"
                value={formClient.cif}
                onChange={(e) =>
                  setFormClient({
                    ...formClient,
                    cif: e.target.value,
                  })
                }
              />
            </Form.Group>
            <Form.Group
              className=" mt-2 width-40 margin-left-15"
              controlId="codigo"
            >
              <Form.Label>Código</Form.Label>
              <Form.Control
                type="text"
                value={formClient.codigo}
                onChange={(e) =>
                  setFormClient({
                    ...formClient,
                    codigo: e.target.value,
                  })
                }
              />
            </Form.Group>
            <Form.Group className=" mt-2 width-40 " controlId="email">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                value={formClient.email}
                onChange={(e) =>
                  setFormClient({
                    ...formClient,
                    email: e.target.value,
                  })
                }
              />
            </Form.Group>
            <Form.Group
              className=" mt-2 width-40 margin-left-15"
              controlId="empresa"
            >
              <Form.Label>Empresa</Form.Label>
              <Form.Control
                type="text"
                value={formClient.empresa}
                onChange={(e) =>
                  setFormClient({
                    ...formClient,
                    empresa: e.target.value,
                  })
                }
              />
            </Form.Group>
            <Form.Group className=" mt-2 width-40 " controlId="nombreFiscal">
              <Form.Label>Nombre fiscal</Form.Label>
              <Form.Control
                type="text"
                value={formClient.nombre_fiscal}
                onChange={(e) =>
                  setFormClient({
                    ...formClient,
                    nombre_fiscal: e.target.value,
                  })
                }
              />
            </Form.Group>
          </div>

          <div className="d-flex justify-content-end mr-5">
            <Button variant="secondary" type="submit">
              Guardar
            </Button>
          </div>
        </Form>
      </div>
      <hr />
      <ListUsers client={id} />
    </div>
  )
}

export default EditClient
