import React from 'react'
import Button from 'react-bootstrap/Button'
import PropTypes from 'prop-types'
//api
import { deleteUserClient } from '../../../api/clients'
//components
import ModalCustom from '../../modal/index'

const ModalDeleteUserClient = ({
  openModalDelete,
  setOpenModalDelete,
  listUserClient,
  idUser,
}) => {
  const handleClose = () => {
    setOpenModalDelete(false)
  }

  const userDelete = async () => {
    const { status } = await deleteUserClient(idUser)
    try {
      if (status === 204) {
        listUserClient()
        setOpenModalDelete(false)
      }
    } catch (e) {
      console.log(e)
    }
  }

  const ButtonFooter = () => {
    return (
      <div className="d-flex justify-content-end">
        <Button variant="secondary" onClick={handleClose} className="mr-3">
          Cancelar
        </Button>
        <Button variant="secondary" onClick={userDelete}>
          Aceptar
        </Button>
      </div>
    )
  }
  return (
    <ModalCustom
      show={openModalDelete}
      title={<h5 className="text-center m-3">¿Está seguro de eliminar?</h5>}
    >
      <ButtonFooter />
    </ModalCustom>
  )
}

ModalDeleteUserClient.propTypes = {
  openModalDelete: PropTypes.bool,
  setOpenModalDelete: PropTypes.func,
  listUserClient: PropTypes.func,
  idUser: PropTypes.number,
}

export default ModalDeleteUserClient
