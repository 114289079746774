import React, { useEffect, useState } from 'react'
import { Col, Container, Row, Spinner } from 'react-bootstrap'
import { BsSearch } from 'react-icons/bs'

//icons
import { AiFillEdit, AiFillDelete, AiOutlineExport } from 'react-icons/ai'

//api
import {
  deleteBudget,
  getBudget,
  getBudgetExcel,
  getBudgetsByUser,
} from '../../api/ecommerce'

//utils
import { useQuery } from '../../utils/customHooks/query'

//componentes
import CustomTable from '../../components/table'
import IntranetPagination from '../intranet/Pagination'
import CustomInput from '../../components/customInput'
import { Link, useNavigate } from 'react-router-dom'

const BudgetList = () => {
  const navigate = useNavigate()
  const searchParams = useQuery()
  const page = searchParams.get('page') || 1
  const pageSize = 10
  const [isLoading, setIsLoading] = useState(false)
  const [noResults, setNoResults] = useState(false)
  const [budgetList, setBudgetList] = useState([])
  const [inputValue, setInputValue] = useState('')
  const [totalPages, setTotalPages] = useState(null)
  const [isActiveSearch, setIsActiveSearch] = useState(false)
  const [itemEdit, setItemEdit] = useState({})

  const query = {
    nombre: inputValue,
    page: page,
    page_size: pageSize,
  }

  const searchQuery = new URLSearchParams(query)
  const queryString = searchQuery.toString()

  const getBudgetListFunc = async () => {
    setIsLoading(true)
    const { status, data } = await getBudgetsByUser(queryString)
    const { results } = data
    if (status === 200) {
      setIsLoading(false)
      setBudgetList(results)
      setTotalPages(Math.ceil(data.count / pageSize))
    } else {
      console.log('error')
    }
  }

  const getBudgetToEdit = async (id) => {
    const { status, data } = await getBudget(id)
    const { result } = data
    if (status === 200) {
      setItemEdit(result)
    } else {
      console.log('error')
    }
  }

  const deleteBudgetFunc = async (id) => {
    const { status } = await deleteBudget(id)
    if (status === 204) {
      getBudgetListFunc()
    } else {
      console.log('error al borrar presupuesto')
    }
  }

  const exportExcel = async (id) => {
    const { status, data } = await getBudgetExcel(id)
    if (status === 200) {
      const url = window.URL.createObjectURL(new Blob([data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'Presupuesto.xlsx')
      document.body.appendChild(link)
      link.click()
      link.remove()
    } else {
      console.log('error budget excel export')
    }
  }

  const handleSubmit = async () => {
    try {
      const { data } = await getBudgetsByUser(queryString)
      if (data.results.length > 0) {
        setBudgetList(data?.results)
        setNoResults(false)
        setTotalPages(Math.ceil(data.count / pageSize))
      } else {
        setBudgetList([])
        setNoResults(true)
      }
    } catch (error) {}
    navigate(`?${queryString}`)
    setIsActiveSearch(true)
  }

  const handleValue = (e) => {
    setInputValue(e.target.value)
  }

  useEffect(() => {
    getBudgetListFunc()
  }, [])

  useEffect(() => {
    if (inputValue !== '') {
      handleSubmit()
    } else {
      getBudgetListFunc()
    }
  }, [page])

  const colsTable = ['ID', 'Nombre', 'Biblioteca', 'Fecha', 'Total', 'Acciones']

  return (
    <Container>
      <h1>Listado de presupuestos</h1>
      <Row>
        <Col sm={5} className="my-3">
          <CustomInput
            handleSubmit={(e) => handleSubmit(e)}
            onChange={handleValue}
            inputValue={inputValue}
            placeholder="Buscar por nombre de autor"
            icon={<BsSearch color="white" />}
            type={'search'}
          />
        </Col>
      </Row>
      {isLoading ? (
        <div className="text-center">
          <Spinner animation="border" role="status" size="lg" />
        </div>
      ) : (
        <Row>
          <CustomTable cols={colsTable} noResults={noResults}>
            {budgetList.map((items) => {
              return (
                <tr key={items.id}>
                  <td className="text-center">{items.id}</td>
                  <td className="text-center">{items.nombre}</td>
                  <td className="text-center">{items.biblioteca}</td>
                  <td className="text-center">
                    {new Date(items.fecha).toLocaleDateString('es-ES')}
                  </td>
                  <td className="text-center">$ {items.total.toFixed(2)}</td>
                  <td className="text-center">
                    <Link
                      to={{
                        pathname: `/editarpresupuesto/${items.id}`,
                      }}
                      style={{ color: 'black' }}
                    >
                      <AiFillEdit onClick={() => getBudgetToEdit(items.id)} />
                    </Link>
                    <AiFillDelete
                      className="ml-3"
                      onClick={() => deleteBudgetFunc(items.id)}
                    />
                    <AiOutlineExport
                      className="ml-3"
                      onClick={() => exportExcel(items.id)}
                    />
                  </td>
                </tr>
              )
            })}
          </CustomTable>
          <IntranetPagination
            page={parseInt(page)}
            pages={totalPages}
            isActiveSearch={isActiveSearch}
            queryParams={queryString}
          />
        </Row>
      )}
    </Container>
  )
}

export default BudgetList
